import * as React from 'react';
import axios from 'axios';
import GlobalVar from '../../GlobalVar';
import { useTheme, useMediaQuery } from '@material-ui/core';
import ReactAudioPlayer from 'react-audio-player';
import Typography from '@mui/material/Typography';
import ImageList from '@mui/material/ImageList';
import Fab from '@mui/material/Fab';
import Snackbar from '@material-ui/core/Snackbar';
import Modal from '@mui/material/Modal';
import Backdrop from '@mui/material/Backdrop';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import { CardActionArea } from '@mui/material';
import ImageListItem from '@mui/material/ImageListItem';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Fade from '@mui/material/Fade';
import Grow from '@mui/material/Grow';
import { bankData } from '../../Bank.js'
import { AnimationOnScroll } from 'react-animation-on-scroll';
import PlaceIcon from '@mui/icons-material/Place';
import CardGiftcardIcon from '@mui/icons-material/CardGiftcard';
import CloseIcon from '@mui/icons-material/Close';
import InsertCommentIcon from '@mui/icons-material/InsertComment';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import PauseCircleIcon from '@mui/icons-material/PauseCircle';

import SwiperCore, { EffectCoverflow, Pagination, Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";
import 'swiper/css/autoplay';

import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

import Lightbox from "yet-another-react-lightbox";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen";
import Slideshow from "yet-another-react-lightbox/plugins/slideshow";
import "yet-another-react-lightbox/styles.css";

import SectionRSVPPremiumUlos from './Section-rsvp';

const server = GlobalVar.server;

const Item = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(0.25),
  color: theme.palette.text.secondary,
}));

SwiperCore.use([EffectCoverflow, Pagination, Autoplay]);

const SectionAllUlos = (props) => {
    const [custname, setCustname] = React.useState('');

    const [stateRSVP, setStateRSVP] = React.useState({ right: false, });
    const toggleDrawerRSVP = (anchor, open) => (event) => {
        if (
          event &&
          event.type === 'keydown' &&
          (event.key === 'Tab' || event.key === 'Shift')
        ) {
          return;
        }
    
        setStateRSVP({ ...stateRSVP, [anchor]: open });
      };
        const listRSVP = (anchor) => (
            <Box
                role="presentation"
                onClick={toggleDrawerRSVP(anchor, true)}
                onKeyDown={toggleDrawerRSVP(anchor, true)}
            >
                <SectionRSVPPremiumUlos  />
            </Box>
        );
    

    const theme = useTheme();
    const mobile = useMediaQuery(theme.breakpoints.down('xs'));
    const tablet = useMediaQuery(theme.breakpoints.down('sm')); 

    const [tglAkadS, setTglAkadS] = React.useState('');
    const [tglAkadE, setTglAkadE] = React.useState('');
    const [tglResepsiE, setTglResepsiE] = React.useState('');
    const [lokResepsi, setlokResepsi] = React.useState('');
    const [resepsiGmaps, setResepsiGmaps] = React.useState('');
    const [mempelaiPria, setMempelaiPria] = React.useState('');
    const [mempelaiWanita, setMempelaiWanita] = React.useState('');
    const [priaFull, setPriaFull] = React.useState('');
    const [wanitaFull, setWanitaFull] = React.useState('');
    const [ayahPria, setAyahPria] = React.useState('');
    const [ibuPria, setIbuPria] = React.useState('');
    const [ayahWanita, setAyahWanita] = React.useState('');
    const [ibuWanita, setIbuWanita] = React.useState('');
    const [fotoPria, setFotoPria] = React.useState('');
    const [fotoWanita, setFotoWanita] = React.useState('');
    const [fotoCover1, setFotoCover1] = React.useState('');
    const [fotoCover2, setFotoCover2] = React.useState('');
    const [foto1, setFoto1] = React.useState('');
    const [foto2, setFoto2] = React.useState('');
    const [foto3, setFoto3] = React.useState('');
    const [foto4, setFoto4] = React.useState('');
    const [foto5, setFoto5] = React.useState('');
    const [foto6, setFoto6] = React.useState('');
    const [foto7, setFoto7] = React.useState('');
    const [foto8, setFoto8] = React.useState('');
    const [foto9, setFoto9] = React.useState('');
    const [foto10, setFoto10] = React.useState('');
    const [foto11, setFoto11] = React.useState('');
    const [foto12, setFoto12] = React.useState('');
    const [bank1Name, setBank1Name] = React.useState('');
    const [bank1Rek, setBank1Rek] = React.useState('');
    const [bank1An, setBank1An] = React.useState('');
    const [bank2Name, setBank2Name] = React.useState('');
    const [bank2Rek, setBank2Rek] = React.useState('');
    const [bank2An, setBank2An] = React.useState('');
    const [alamatHadiah, setAlamatHadiah] = React.useState('');
    const [hari, setHari] = React.useState('');
    const [tgl, setTgl] = React.useState('');
    const [bulan, setBulan] = React.useState('');
    const [tahun, setTahun] = React.useState('');
    const [jamSA, setJamSA] = React.useState('');
    const [menitSA, setMenitSA] = React.useState('');
    const [jamEA, setJamEA] = React.useState('');
    const [menitEA, setMenitEA] = React.useState('');
    const [jamSR, setJamSR] = React.useState('');
    const [menitSR, setMenitSR] = React.useState('');
    const [jamER, setJamER] = React.useState('');
    const [menitER, setMenitER] = React.useState('');
    const [unduhOnly, setUnduhOnly] = React.useState(0);
    const [paketID, setPaketID] = React.useState(1);
    const [islam, setIslam] = React.useState(1);
    const [audioMempelai, setAudioMempelai] = React.useState('');
    
    const [openModalGift, setopenModalGift] = React.useState(false);
    const handleopenModalGift = () => setopenModalGift(true);
    const handleCloseModalGift = () => setopenModalGift(false);

    const [openSnackbar, setOpenSnackbar] = React.useState(false);

    const handleClickSnackbar = () => {
        setOpenSnackbar(true);
    };

    const handleCloseSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
        return;
        }

        setOpenSnackbar(false);
    };
    
    const openGMaps = url => {
      window.open(url, '_blank', 'noopener,noreferrer');
    };

    const [indexImg, SetIndexImg] = React.useState(0);
    const [openLB, SetOpenLB] = React.useState(false);
    const itemData = [ 
        {
            index: 0,
            img: foto1,
            title: 'Foto1',
        },{
            index: 1,
            img: foto2,
            title: 'Foto2',
        },{
            index: 2,
            img: foto3,
            title: 'Foto3',
        },{
            index: 3,
            img: foto4,
            title: 'Foto4',
        },{
            index: 4,
            img: foto5,
            title: 'Foto5',
        },{
            index: 5,
            img: foto6,
            title: 'Foto6',
        },    
    ];

    const itemDataLB = [ 
        {
            src: `data:image/png;base64, ${foto1}`,
        },{
            src: `data:image/png;base64, ${foto2}`,
        },{
            src: `data:image/png;base64, ${foto3}`,
        },{
            src: `data:image/png;base64, ${foto4}`,
        },{
            src: `data:image/png;base64, ${foto5}`,
        },{
            src: `data:image/png;base64, ${foto6}`,
        }
    ];
    const pathParts = window.location.pathname.split('/');  

    React.useEffect(() => {
        const path = window.location.search;
        const urlParams = new URLSearchParams(path);
        
        setCustname(pathParts[1]);
        
        axios.get(server+'GetData.php?n='+pathParts[1]).then(function(response){
            let res = JSON.stringify(response.data);
            let resP = JSON.parse(res);

            props.fotoShared(resP[0].cf_foto_shared);
            setMempelaiPria(resP[0].cf_mempelai_pria);
            setMempelaiWanita(resP[0].cf_mempelai_wanita);
            setPriaFull(resP[0].cf_mempelai_pria_full);
            setWanitaFull(resP[0].cf_mempelai_wanita_full);
            setUnduhOnly(resP[0].cf_unduh_only);
            setPaketID(resP[0].cs_paketid);
            setlokResepsi(resP[0].cf_tempat_resepsi);
            setResepsiGmaps(resP[0].cf_gmaps_resepsi);
            setAlamatHadiah(resP[0].cf_alamat_hadiah);
            setAudioMempelai(resP[0].cf_audio_mempelai);

            const days = ['Minggu', 'Senin', 'Selasa', 'Rabu', 'Kamis', 'Jumat', 'Sabtu'];
            const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
              "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
            ];
            let newTgl = new Date(resP[0].cf_unduh_only == 1 ? resP[0].cf_waktu_unduh_start : resP[0].cf_waktu_akad_start == null ?
                resP[0].cf_waktu_resepsi_start : resP[0].cf_waktu_akad_start);
            setHari(days[newTgl.getDay()]);
            setTgl(newTgl.getDate());
            setBulan(monthNames[newTgl.getMonth()]); //Month from 0 to 11
            setTahun(newTgl.getFullYear());

            function addZero(i) {
              if (i < 10) {i = "0" + i}
              return i;
            }

            setTglAkadS(resP[0].cf_waktu_akad_start);
            let newTglAkadS = new Date(resP[0].cf_waktu_akad_start);
            setJamSA(addZero(newTglAkadS.getHours()));
            setMenitSA(addZero(newTglAkadS.getMinutes()));
            setTglAkadE(resP[0].cf_waktu_akad_end);
            let newTglAkadE = new Date(resP[0].cf_waktu_akad_end);
            setJamEA(addZero(newTglAkadE.getHours()));
            setMenitEA(addZero(newTglAkadE.getMinutes()));

            let newTglResepsiS = new Date(resP[0].cf_waktu_resepsi_start);
            setJamSR(addZero(newTglResepsiS.getHours()));
            setMenitSR(addZero(newTglResepsiS.getMinutes()));
            setTglResepsiE(resP[0].cf_waktu_resepsi_end);
            let newTglResepsiE = new Date(resP[0].cf_waktu_resepsi_end);
            setJamER(addZero(newTglResepsiE.getHours()));
            setMenitER(addZero(newTglResepsiE.getMinutes()));

            setAyahPria(resP[0].cf_ayah_mempelai_pria);
            setIbuPria(resP[0].cf_ibu_mempelai_pria);
            setAyahWanita(resP[0].cf_ayah_mempelai_wanita);
            setIbuWanita(resP[0].cf_ibu_mempelai_wanita);

            setIslam(resP[0].cs_islam);       
            
            bankData
            .filter((bank) => bank.code == resP[0].cf_bank1_kode)
            .map((filteredBank) => {
              setBank1Name(filteredBank.logo)
              setBank1Rek(resP[0].cf_bank1_rekening)
              setBank1An(resP[0].cf_bank1_an)
            });

            bankData
            .filter((bank) => bank.code == resP[0].cf_bank2_kode)
            .map((filteredBank) => {
                setBank2Name(filteredBank.logo)
                setBank2Rek(resP[0].cf_bank2_rekening)
                setBank2An(resP[0].cf_bank2_an)
            });
           
            
        if(resP[0].cs_paketid == 1) {
            axios.get(server+'GetImages6.php?n='+pathParts[1]).then(function(response){
                let res = JSON.stringify(response.data);
                let resP = JSON.parse(res);
                setFotoPria(resP.pria);
                setFotoWanita(resP.wanita);
                setFoto1(resP.foto1);
                setFoto2(resP.foto2);
                setFoto3(resP.foto3);
                setFoto4(resP.foto4);
                setFoto5(resP.foto5);
                setFoto6(resP.foto6);
                setFoto7('');
                setFoto8('');
                setFoto9('');
                setFoto10('');
                setFoto11('');
                setFoto12('');
  
                setTimeout(() => {
                  setFotoCover1(resP.fotocover1);
                  setFotoCover2(resP.fotocover2);
                }, 300);
  
            });
          } else {
              axios.get(server+'GetImages12.php?n='+pathParts[1]).then(function(response){
                let res = JSON.stringify(response.data);
                let resP = JSON.parse(res);
                setFotoPria(resP.pria);
                setFotoWanita(resP.wanita);
                setFoto1(resP.foto1);
                setFoto2(resP.foto2);
                setFoto3(resP.foto3);
                setFoto4(resP.foto4);
                setFoto5(resP.foto5);
                setFoto6(resP.foto6);
                setFoto7(resP.foto7);
                setFoto8(resP.foto8);
                setFoto9(resP.foto9);
                setFoto10(resP.foto10);
                setFoto11(resP.foto11);
                setFoto12(resP.foto12);
  
                setTimeout(() => {
                    
                    itemData.push(...[
                        {
                            index: 6,
                            img: foto7,
                            title: 'Foto7',
                        },{
                            index: 7,
                            img: foto8,
                            title: 'Foto8',
                        },{
                            index: 8,
                            img: foto9,
                            title: 'Foto9',
                        },{
                            index: 9,
                            img: foto10,
                            title: 'Foto10',
                        },{
                            index: 10,
                            img: foto11,
                            title: 'Foto11',
                        },{
                            index: 11,
                            img: foto12,
                            title: 'Foto12',
                        }, 
                    ]);

                    itemDataLB.push(...[
                        {
                            src: `data:image/png;base64, ${foto7}`,
                        },{
                            src: `data:image/png;base64, ${foto8}`,
                        },{
                            src: `data:image/png;base64, ${foto9}`,
                        },{
                            src: `data:image/png;base64, ${foto10}`,
                        },{
                            src: `data:image/png;base64, ${foto11}`,
                        },{
                            src: `data:image/png;base64, ${foto12}`,
                        }
                    ]);
                  setFotoCover1(resP.fotocover1);
                  setFotoCover2(resP.fotocover2);
                }, 300);
  
            });
            
          }
            
        });
        

    }, []);


    const imgClick = (i) => {
        SetIndexImg(i);
        setTimeout(() => {
            SetOpenLB(true);
        }, 200);

    }

  return (
    <div className='divCenter'>
    <Fade in={props.visible}
        {...(props.visible ? { timeout: 2000 } : {})} > 
        <div className='App premium ulos'>

            <div className='divBG static'></div>

<div className='ulosBatak'></div>
            <div className='section-overlay'>

                {/* <Fab color="extended" aria-label="GMaps" className='navGBtn'>
                  <img src={require('../../images/googlemapsIcon.png')} width={mobile ? 36 : 48} />
                </Fab> */}

                <div className='section-overlay-content'>
                      <center>
                  <img src={require('../../images/bg_adat/batak/banner.png')} className="App-section-ulos-banner"/>
                        <Grow in={props.visible}
                            {...(props.visible ? { timeout: 2000 } : {})} > 
                            <Typography variant={mobile ? "h2": "h2"} mb={2} mt={-5} color='rgba(0,0,0,1)' className='judul font-minang'>{unduhOnly == 1 ? 'UNDUH MANTU' : 'UNDANGAN'}</Typography>
                        </Grow>                   
                        <Grow in={props.visible}
                            {...(props.visible ? { timeout: 2000 } : {})} > 
                        <Typography variant={mobile ? "body2": "body1"} style={{marginTop: -15}} color='rgba(0,0,0,0.75)' component='div' className='font-XanhMono'><b>Putri dari : </b></Typography>
                            </Grow>                   
                        <Grow in={props.visible}
                            {...(props.visible ? { timeout: 2000 } : {})} > 
                        <Typography variant={mobile ? "body2": "body1"} component='div' color='rgba(0,0,0,0.75)' className='font-XanhMono'><b> Bpk. {ayahWanita} &</b></Typography>
                            </Grow>                   
                        <Grow in={props.visible}
                            {...(props.visible ? { timeout: 2000 } : {})} > 
                        <Typography variant={mobile ? "body2": "body1"} component='div' color='rgba(0,0,0,0.75)' className='font-XanhMono'><b>Ibu {ibuWanita}</b></Typography>
                            </Grow>                   
                        
                        <Grow in={props.visible}
                            {...(props.visible ? { timeout: 2000 } : {})} > 
                            <Typography variant={mobile ? "h4": "h2"} color='rgba(0,0,0,1)' className='font-XanhMono'><b>
                                <Typography variant='caption' component='div' style={{marginBottom: 0}} className='font-XanhMono' color='rgba(0,0,0,0.75)'><b>[{wanitaFull}]</b> </Typography>{mempelaiWanita} &</b></Typography>
                        </Grow> 

                        <Grow in={props.visible}
                            {...(props.visible ? { timeout: 2000 } : {})} > 
                            <Typography variant={mobile ? "h4" : "h2"} color='rgba(0,0,0,1)' style={{marginTop: -5}} className='font-XanhMono'><b>{mempelaiPria}</b>
                                <Typography variant='caption' component='div' style={{marginTop: 0}} className='font-XanhMono' color='rgba(0,0,0,0.75)'> <b>[{priaFull}]</b></Typography></Typography>
                        </Grow>
                        <Grow in={props.visible}
                            {...(props.visible ? { timeout: 2000 } : {})} > 
                            <Typography variant={mobile ? "body2": "body1"} color='rgba(0,0,0,0.75)' component='div' className='font-XanhMono'><b>Putra dari : </b></Typography>
                        </Grow>
                        <Grow in={props.visible}
                            {...(props.visible ? { timeout: 2000 } : {})} > 
                            <Typography variant={mobile ? "body2": "body1"} color='rgba(0,0,0,0.75)' component='div' className='font-XanhMono'><b> Bpk. {ayahPria} &</b></Typography>
                        </Grow>
                        <Grow in={props.visible}
                            {...(props.visible ? { timeout: 2000 } : {})} > 
                            <Typography variant={mobile ? "body2": "body1"} color='rgba(0,0,0,0.75)' component='div' className='font-XanhMono'><b>Ibu {ibuPria}</b></Typography>
                        </Grow>
                        <Grow in={props.visible}
                            {...(props.visible ? { timeout: 2000 } : {})} > 
                            <Typography variant={mobile ? "h6": "h5"} mt={2} color='rgba(220, 38, 38, 1)' className='font-XanhMono'><b>{hari}, {tgl} {bulan} {tahun}</b></Typography>
                        </Grow>
                            {/* <hr style={{width: 20, border: '1px solid #EEC373'}} /> */}
                        <Grow in={props.visible}
                            {...(props.visible ? { timeout: 2000 } : {})} > 
                            <Typography variant={mobile ? "body1": "h6"} color='rgba(0,0,0,0.75)' className='font-XanhMono' my={1}><b>{lokResepsi}</b></Typography>
                        </Grow>
                        {
                            tglAkadS != null ?
                                <div>
                                    <Grow in={props.visible}
                                        {...(props.visible ? { timeout: 2000 } : {})} > 
                                        <Typography variant={mobile ? "body1": "h6"} color='rgba(0,0,0,0.75)' className='font-XanhMono'><b>{islam == 1 ? 'Akad' : 'Pemberkatan'} :</b>
                                        <span className='font-XanhMono'><b> {jamSA}:{menitSA} - {tglAkadE == null  ? 'Selesai' : jamEA+':'}{tglAkadE != null  && menitEA} WIB </b></span></Typography>                        
                                    </Grow>
                                </div>
                            : ''
                        }
                        <Grow in={props.visible}
                            {...(props.visible ? { timeout: 2000 } : {})} > 
                            <Typography variant={mobile ? "body1": "h6"} color='rgba(0,0,0,0.75)' mb={4} className='font-XanhMono'><b>Resepsi :
                             <span className='font-XanhMono'>{jamSR}:{menitSR} - {tglResepsiE == null  ? 'Selesai' : jamER+':'}{tglResepsiE != null  && menitER} WIB </span></b></Typography>
                        </Grow>

                        <Fab color="extended" aria-label="GMaps" className='navGBtn navIcon' onClick={() => openGMaps(resepsiGmaps)}>
                            <PlaceIcon />
                                        {/* <img src={require('../../images/googlemapsIcon.png')} width={24} />   */}
                        </Fab>
                        <Fab color="extended" aria-label="Gift" className='navGBtn giftIcon' onClick={handleopenModalGift} sx={{ml: 2, mr: .75}}>
                            <CardGiftcardIcon />
                        </Fab>
                      {['right'].map((anchor) => (
                            <React.Fragment key={anchor}>
                            <Fab color="extended" aria-label="Gift" className='navGBtn rsvpIcon' onClick={toggleDrawerRSVP(anchor, true)} sx={{mr: 1}} >
                                <InsertCommentIcon />
                            </Fab>
                                <SwipeableDrawer
                                    anchor={anchor}
                                    open={stateRSVP[anchor]}
                                    onClose={toggleDrawerRSVP(anchor, false)}
                                    onOpen={toggleDrawerRSVP(anchor, true)}
                                >
                                    <IconButton aria-label="copy" size={mobile ? 'medium' : 'large'} style={{position: 'fixed', top: '10px', right: '20px', zIndex: 9, 
                                    width: mobile ? '32px' : '36px', background:'rgba(0,0,0,0.525)', padding: '4px'}} 
                                    onClick={toggleDrawerRSVP(anchor, false)}>
                                        <CloseIcon fontSize="inherit" style={{color: '#fff'}} />
                                    </IconButton>
                                    {listRSVP(anchor)}
                                </SwipeableDrawer>
                            </React.Fragment>
                        ))}

                            {/* <ReactAudioPlayer
                                src={GlobalVar.audio+'_'+audioMempelai+'.mp3'}
                                className='audioPlayer'
                                autoPlay
                                muted={play}
                                loop={true}
                            /> */}

                      </center>
                        
                </div>

                <Box className='premium_gallery'>
                    <div>
                        <Swiper
                            effect={"coverflow"}
                            grabCursor={true}
                            centeredSlides={true}
                            slidesPerView={"auto"}
                            coverflowEffect={{
                            rotate: 50,
                            stretch: 0,
                            depth: 100,
                            modifier: 1,
                            slideShadows: false,
                            }}
                            pagination={false}
                            className="mySwiper"
                            autoplay
                        >
                            {itemData.map((item) => {
                            return (
                                <SwiperSlide key={item.index} style={{ 'backgroundImage': `url(data:image/*;base64,${item.img})`, cursor: 'pointer', }} onClick={() => imgClick(item.index)}>
                                </SwiperSlide>
                            );
                            })}
                        </Swiper>
                    </div>
                    
                    <div className='premium_gallery_layer2'>
<Modal
  aria-labelledby="transition-modal-title"
  aria-describedby="transition-modal-description"
  open={openModalGift}
  onClose={handleCloseModalGift}
  closeAfterTransition
  BackdropComponent={Backdrop}
  BackdropProps={{
    timeout: 100,
  }}
  className='modalGiftPremium'
>
  <Fade in={openModalGift}>
    <Box className='boxModal' 
            style={{paddingBottom: 20}}>
            <IconButton aria-label="copy" size={mobile ? 'medium' : 'large'}  mb={4} style={{position: 'absolute', right: 2, top: 2}} onClick={handleCloseModalGift}>
                <CloseIcon fontSize="inherit" style={{color: '#000'}} />
            </IconButton>
            <container>
                <center>
                    <Grid container 
                        direction="row"
                        justifyContent="center"
                        alignItems="center" 
                        spacing={2} mt={4} px={mobile ? 2 : 2} mb={4}
                    >
                        <Grid item xs={9}>
                            <Typography variant={mobile ? "body2" : "body1"} className="font-XanhMono">
                            <b>Alamat Pengiriman :</b> <span className='font-XanhMono'>{alamatHadiah}</span>
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Item elevation={0} className='bgTransparent'>
                                <Card elevation={4} className='cardBank'>
                                    <CardActionArea className='cardBank'>
                                    <CardContent>
                                        <img src={`${bank1Name}`} width={85} className="bank-img"/>  
                                        <Typography gutterBottom variant={mobile ? "body2" : "body1"} className='font-XanhMono font-thin bank-rek' style={{letterSpacing: 2}} component="div" mt={2}>
                                            <IconButton aria-label="copy" size="small" style={{marginRight: 5}} onClick={() =>  {navigator.clipboard.writeText(bank1Rek); handleClickSnackbar(); }}>
                                                <FileCopyIcon fontSize="inherit" />
                                            </IconButton>
                                            {bank1Rek} 
                                        </Typography> 
                                        <Typography variant={mobile ? "caption" : "body2"} className="font-XanhMono font-thin bank-an" >{bank1An}</Typography>
                                    </CardContent>
                                    </CardActionArea>
                                </Card>
                            </Item>
                        </Grid>
                    { bank2Rek &&
                        <Grid item xs={12}>
                            <Item elevation={0} className='bgTransparent'>
                                <Card elevation={1} className='cardBank'>
                                    <CardActionArea className='cardBank'>
                                    <CardContent>
                                        <img src={`${bank2Name}`} width={85} className="bank-img"/>  
                                        <Typography gutterBottom variant={mobile ? "body2" : "body1"} className='font-XanhMono font-thin bank-rek' style={{letterSpacing: 2}} component="div" mt={2}>
                                            <IconButton aria-label="copy" size="small" style={{marginRight: 5}} onClick={() => {navigator.clipboard.writeText(bank2Rek); handleClickSnackbar(); }}>
                                                <FileCopyIcon fontSize="inherit" />
                                            </IconButton>
                                            {bank2Rek} 
                                        </Typography> 
                                        <Typography variant={mobile ? "caption" : "body2"} className="font-XanhMono font-thin bank-an" >{bank2An}</Typography>
                                    </CardContent>
                                    </CardActionArea>
                                </Card>
                            </Item>
                        </Grid>
                    }
                        
                    </Grid>
                </center>
            </container>            

    </Box>
  </Fade>
</Modal>     
<Snackbar
    anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
    }}
    open={openSnackbar}
    autoHideDuration={2000}
    onClose={handleCloseSnackbar}
    message="Copied to clipboard"
    action={
        <React.Fragment>
            <IconButton size="small" aria-label="close" color="inherit" onClick={handleCloseSnackbar}>
                <CloseIcon fontSize="small" />
            </IconButton>
        </React.Fragment>
    }
/>

                    </div>
                    
                </Box>
                {
                    openLB &&

                    <Lightbox
                        open={openLB}
                        index={indexImg}
                        close={() => { SetOpenLB(false); SetIndexImg(0); }}
                        slides={itemDataLB}
                        plugins={[Slideshow,Zoom]}
                        styles={{ root: { "--yarl__color_backdrop": "rgba(0, 0, 0, .8)" } }}
                    />
                        // <Lightbox images={itemDataLB} startIndex={indexImg} onClose={() => { SetOpenLB(false); SetIndexImg(0); }} />
                }
            </div>
        </div>
        
    </Fade>
    </div>
  );
}

export default SectionAllUlos;


