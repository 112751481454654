import * as React from 'react';
import { useTheme, useMediaQuery } from '@material-ui/core';
import { Paper, Grid, Box, Typography, Container, Button, IconButton } from '@mui/material';
import { styled } from '@mui/material/styles';
import { AnimationOnScroll } from 'react-animation-on-scroll';
import { Link } from "react-scroll";

import Design from './Design';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import InstagramIcon from '@mui/icons-material/Instagram';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';

const Item = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(1),
}));

const Home = (props) =>  {
    const theme = useTheme();
    const mobile = useMediaQuery(theme.breakpoints.down('xs'));

    // const path = window.location.search;
    // const urlParams = new URLSearchParams(path);
    
    const WAMsg = () => {
      const msg = 'Hi, saya tertarik ingin membuat website undangan dengan momentkami.com, bisakah saya berdiskusi lebih lanjut ?';
      window.open('https://api.whatsapp.com/send?phone=+6281912428620&text='+msg+'&app_absent=0', '_blank', 'noopener,noreferrer'); 
  }

    React.useEffect(() => {
        // window.location.hash.replace('#','')
    }, []);

  return ( 
    <div className="HomeContent-header">
      <Box className='HomeContent'>

          <div className='divBG'></div>
          <div className='divBG'></div>
          <div className='divBG'></div>
          <div className='divBG'></div>
          <div className='divBG'></div>

        <div className='overlay-content'>


        {/* <Button 
          variant="contained" 
          // onClick={WAMsg}
          size={mobile ? 'small' : 'large'}    
          sx={{padding: mobile ? '.25rem 0.75rem' : '.5rem 1.25rem', position: 'fixed', right: mobile ? 20: 40, bottom: mobile ? 20: 40, zIndex: 9 }}    
          className='btnWA'
          startIcon={<WhatsAppIcon />}
          >
            Hubungi Kami
        </Button> */}
        <IconButton aria-label="whatsapp" sx={{position: 'fixed', bottom: mobile ? 25: 40, right: mobile ? 25: 40,zIndex: 9}} onClick={WAMsg}>
            <WhatsAppIcon sx={{ fontSize: mobile ? 42 : 48, color: '#fff', background: '#128c7e', borderRadius: 50, padding: .25 }}/>
        </IconButton>
          
          <Container>
            <div className='overlay-content-header' style={{padding: mobile ? '4em 2em' : '8em 2em', width: mobile ? '80%':'45%'}}>
              <AnimationOnScroll animateIn="animate__fadeInDown" initiallyVisible={true}>
                <Typography variant={mobile ? "h5" : "h3"} className="font-jost whiteText">Undangan Digital.</Typography>
                <Typography variant={mobile ? "h2" : "h1"} className="font-curly" sx={{color: '#f5f5f5'}}><b>Momentkami</b></Typography> 
                <Typography variant={mobile ? "body1" : "h5"} mb={6} className="font-jost font-thin whiteText">
                  Bagikan moment bahagia mu bersama kami dalam bentuk website yang menarik dan responsive serta dapat dibagikan kapanpun dan dimanapun.                
                </Typography>
              </AnimationOnScroll>
              <AnimationOnScroll animateIn="animate__fadeInUp" initiallyVisible={true}>
                <Button 
                  variant="outlined" 
                  size={mobile ? 'small' : 'large'}    
                  sx={{padding: mobile ? '.5rem 1rem' : '.5rem 1.25rem' }} 
                  className='btnLight'            
                  startIcon={<AutoFixHighIcon />}>
                    <Link 
                      as={Link} 
                      activeClass="active"
                      to={'fitur'}
                      spy={true}
                      smooth={true}  
                      offset={0}
                    >Fitur Menarik</Link>
                </Button>
              </AnimationOnScroll>
            </div>
          </Container>
            {/* <svg style={{position: 'absolute', bottom: mobile ? 0 : -20, width: '100%', left: 0}} xmlns="http://www.w3.org/2000/svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
              <path fill="#ffffff" fill-opacity="1" d="M0,288L40,272C80,256,160,224,240,229.3C320,235,400,277,480,277.3C560,277,640,235,720,202.7C800,171,880,149,960,160C1040,171,1120,213,1200,229.3C1280,245,1360,235,1400,229.3L1440,224L1440,320L1400,320C1360,320,1280,320,1200,320C1120,320,1040,320,960,320C880,320,800,320,720,320C640,320,560,320,480,320C400,320,320,320,240,320C160,320,80,320,40,320L0,320Z"></path>
            </svg> */}
            
            {/* <svg style={{position: 'absolute', bottom: mobile ? 0 : -20, width: '100%', left: 0}} id="svg" viewBox="0 0 1440 590" xmlns="http://www.w3.org/2000/svg" class="transition duration-300 ease-in-out delay-150">             
              <defs><linearGradient id="gradient" x1="0%" y1="51%" x2="100%" y2="49%"><stop offset="5%" stop-color="#abb8c3"></stop><stop offset="95%" stop-color="#6750a4"></stop></linearGradient></defs><path d="M 0,600 C 0,600 0,150 0,150 C 140.21428571428572,134.71428571428572 280.42857142857144,119.42857142857143 378,115 C 475.57142857142856,110.57142857142857 530.5,117.00000000000001 660,115 C 789.5,112.99999999999999 993.5714285714287,102.57142857142857 1136,107 C 1278.4285714285713,111.42857142857143 1359.2142857142858,130.71428571428572 1440,150 C 1440,150 1440,600 1440,600 Z" stroke="none" stroke-width="0" fill="url(#gradient)" fill-opacity="0.4" class="transition-all duration-300 ease-in-out delay-150 path-0"></path>
              <defs><linearGradient id="gradient" x1="0%" y1="51%" x2="100%" y2="49%"><stop offset="5%" stop-color="#abb8c3"></stop><stop offset="95%" stop-color="#6750a4"></stop></linearGradient></defs><path d="M 0,600 C 0,600 0,300 0,300 C 120.39285714285714,323.1428571428571 240.78571428571428,346.2857142857143 368,325 C 495.2142857142857,303.7142857142857 629.2500000000001,237.99999999999994 749,243 C 868.7499999999999,248.00000000000006 974.2142857142856,323.7142857142858 1087,345 C 1199.7857142857144,366.2857142857142 1319.8928571428573,333.1428571428571 1440,300 C 1440,300 1440,600 1440,600 Z" stroke="none" stroke-width="0" fill="url(#gradient)" fill-opacity="0.53" class="transition-all duration-300 ease-in-out delay-150 path-1"></path>
              <defs><linearGradient id="gradient" x1="0%" y1="51%" x2="100%" y2="49%"><stop offset="5%" stop-color="#abb8c3"></stop><stop offset="95%" stop-color="#6750a4"></stop></linearGradient></defs><path d="M 0,600 C 0,600 0,450 0,450 C 114.57142857142858,416.25 229.14285714285717,382.5 339,395 C 448.85714285714283,407.5 554,466.24999999999994 670,470 C 786,473.75000000000006 912.8571428571429,422.50000000000006 1043,410 C 1173.142857142857,397.49999999999994 1306.5714285714284,423.75 1440,450 C 1440,450 1440,600 1440,600 Z" stroke="none" stroke-width="0" fill="url(#gradient)" fill-opacity="1" class="transition-all duration-300 ease-in-out delay-150 path-2"></path>
            </svg> */}
            {/* <svg style={{position: 'absolute', bottom: mobile ? 0 : -20, width: '100%', left: 0}} id="svg" viewBox="0 0 1440 590" xmlns="http://www.w3.org/2000/svg" class="transition duration-300 ease-in-out delay-150">
              <defs><linearGradient id="gradient" x1="0%" y1="51%" x2="100%" y2="49%"><stop offset="5%" stop-color="#abb8c3"></stop><stop offset="95%" stop-color="#6750a4"></stop></linearGradient></defs><path d="M 0,600 C 0,600 0,200 0,200 C 53.550669872895895,206.4500171762281 107.10133974579179,212.9000343524562 183,210 C 258.8986602542082,207.0999656475438 357.14531088972865,194.8498797664033 423,201 C 488.85468911027135,207.1501202335967 522.3174166952937,231.7004465819306 589,221 C 655.6825833047063,210.2995534180694 755.5850223290967,164.34833390587428 841,165 C 926.4149776709033,165.65166609412572 997.34249398832,212.9062177945723 1056,226 C 1114.65750601168,239.0937822054277 1161.0450017176229,218.0267949158365 1223,208 C 1284.9549982823771,197.9732050841635 1362.4774991411887,198.98660254208176 1440,200 C 1440,200 1440,600 1440,600 Z" stroke="none" stroke-width="0" fill="url(#gradient)" fill-opacity="0.53" class="transition-all duration-300 ease-in-out delay-150 path-0"></path>          
              <defs><linearGradient id="gradient" x1="0%" y1="51%" x2="100%" y2="49%"><stop offset="5%" stop-color="#abb8c3"></stop><stop offset="95%" stop-color="#6750a4"></stop></linearGradient></defs><path d="M 0,600 C 0,600 0,400 0,400 C 69.07042253521129,410.2974922706974 138.14084507042259,420.5949845413947 209,407 C 279.8591549295774,393.4050154586053 352.50704225352104,355.9175541051185 420,351 C 487.49295774647896,346.0824458948815 549.8309859154931,373.7347990381312 623,401 C 696.1690140845069,428.2652009618688 780.1690140845069,455.1432497423566 848,430 C 915.8309859154931,404.8567502576434 967.4929577464789,327.69220199244245 1031,329 C 1094.5070422535211,330.30779800755755 1169.8591549295775,410.08794228787355 1240,435 C 1310.1408450704225,459.91205771212645 1375.0704225352113,429.9560288560632 1440,400 C 1440,400 1440,600 1440,600 Z" stroke="none" stroke-width="0" fill="url(#gradient)" fill-opacity="1" class="transition-all duration-300 ease-in-out delay-150 path-1"></path>
            </svg> */}
        </div>
      </Box>
    </div>

  );
}

export default Home;