import * as React from 'react';
import { useTheme, useMediaQuery } from '@material-ui/core';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Grow from '@mui/material/Grow';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import { CardActionArea } from '@mui/material';
import { AnimationOnScroll } from 'react-animation-on-scroll';

import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';

const Item = styled(Paper)(({ theme }) => ({
    padding: theme.spacing(0.25),
    color: theme.palette.text.secondary,
  }));
  
const SectionMempelaiMinang = (props) => {
    const theme = useTheme();
    const mobile = useMediaQuery(theme.breakpoints.down('xs'));
    const tablet = useMediaQuery(theme.breakpoints.down('sm'));

  
  return (
    <div className='App-header-section-minang-mempelai'>
    { props.islam &&
        <Grow in={props.visible}
                      {...(props.visible ? { timeout: 3000 } : {})} > 
            <center>  
                { props.islam == 1 &&
                        <Typography variant="h5" mt={4} style={{letterSpacing: 0}}>السَّلاَمُ عَلَيْكُمْ وَرَحْمَةُ اللهِ وَبَرَكَاتُهُ</Typography>
                }
                <Typography variant="h6" className="font-minang" mx={4} mb={2}>
                    {props.islam == 1 ? 'Maha Suci Allah yang telah menciptakan makhluk-Nya berpasang-pasangan' : 'Tuhan membuat segala sesuatu indah pada waktu-Nya. Indah ketika mempertemukan kami, indah saat menumbuhkan kasih di antara kami, dan indah saat Ia mempersatukan kami dalam pernikahan yang kudus.'}
                </Typography>
                
            </center>
        </Grow>
    }

        <Grid container 
            direction="row"
            justifyContent="center"
            alignItems="center" 
            spacing={2} mt={4} px={2} mb={4}
        >
            <Grid item xs={12}>
                <Item elevation={0} className='bgTransparentFrameMinang'>
                    <Card elevation={0} className='bgTransparentFrameMinang'>
                        <AnimationOnScroll animateIn="animate__fadeIn">
                            <center>
                                <CardMedia
                                    component="img"
                                    sx={{ width: 250, border: 'none' }}
                                    image={`data:image/png;base64, ${props.fotoWanita}`}
                                    className="img-mempelai-round"
                                    alt="mempelai wanita"
                                />
                            </center>
                        </AnimationOnScroll>
                        <CardActionArea className='cardNoAction'>
                            <CardContent>
                                <center>
                                <AnimationOnScroll animateIn="animate__fadeInDown">
                                    <Typography variant="h3" className='font-satisfy' component="div">
                                        {props.wanita}
                                    </Typography>
                                </AnimationOnScroll>
                                <AnimationOnScroll animateIn="animate__fadeInUp">
                                    <Typography gutterBottom variant="h4" className='font-minang' component="div">
                                        {props.wanitaFull}
                                    </Typography>
                                    <Typography variant="h5" className="font-minang" mt={2} mb={1}> Putri dari </Typography>
                                    <Typography variant="h5" className="font-minang" >Bapak {props.ayahWanita} &</Typography>
                                    <Typography variant="h5" className="font-minang" >Ibu {props.ibuWanita}</Typography>
                                </AnimationOnScroll>
                                </center>
                            </CardContent>
                        </CardActionArea>
                    </Card>
                </Item>
            </Grid>
            <Grid item xs={12}>
                <Item elevation={0} className='bgTransparentFrameMinang'>
                    <Card elevation={0} className='bgTransparentFrameMinang'>
                        <AnimationOnScroll animateIn="animate__fadeIn">
                            <center>
                                <CardMedia
                                    component="img"
                                    sx={{ width: 250, border: 'none' }}
                                    image={`data:image/png;base64, ${props.fotoPria}`}
                                    className="img-mempelai-round"
                                    alt="mempelai pria" 
                                />
                            </center>
                        </AnimationOnScroll>
                        <CardActionArea className='cardNoAction'>
                            <CardContent>
                                <center>
                                <AnimationOnScroll animateIn="animate__fadeInDown">
                                    <Typography variant="h3" className='font-satisfy' component="div">
                                        {props.pria}
                                    </Typography>
                                </AnimationOnScroll>

                                <AnimationOnScroll animateIn="animate__fadeInUp">
                                    <Typography gutterBottom variant="h4" className='font-minang' component="div">
                                        {props.priaFull}
                                    </Typography>
                                    <Typography variant="h5" className="font-minang" mt={2} mb={1}> Putra dari </Typography>
                                    <Typography variant="h5" className="font-minang" >Bapak {props.ayahPria} &</Typography>
                                    <Typography variant="h5" className="font-minang" >Ibu {props.ibuPria}</Typography>
                                </AnimationOnScroll>
                                </center>
                            </CardContent>
                        </CardActionArea>
                    </Card>
                </Item>
            </Grid>
            
            
        </Grid>
        
        { props.unduhOnly == 0 && 
            <div className='App-header-section-minang-ayat'>
                <Box>
                { props.islam &&
                    <center>
                    <AnimationOnScroll animateIn="animate__fadeIn">
                        <Typography variant="body1" pt={2} mx={2} className='font-satisfy' style={{width: '85%'}}>
                        {props.islam == 1 ? '" Dan di antara tanda-tanda (kebesaran)-Nya ialah Dia menciptakan pasangan-pasangan untukmu dari jenismu sendiri, agar kamu cenderung dan merasa tenteram kepadanya, dan Dia menjadikan di antaramu rasa kasih dan sayang. Sungguh, pada demikian itu benar-benar terdapat tanda-tanda (kebesaran Allah) bagi kaum yang berpikir. "'
                        : '"Sebab pada awal dunia, Allah menjadikan mereka laki-laki dan perempuan, sebab itu laki-laki akan meninggalkan ayahnya dan ibunya dan bersatu dengan isterinya, sehingga keduanya itu menjadi satu daging. Demikianlah mereka bukan lagi dua, melainkan satu. Karena itu, apa yang telah dipersatukan Allah, tidak boleh diceraikan manusia."'}
                        </Typography>            
                    </AnimationOnScroll>
                    <AnimationOnScroll animateIn="animate__fadeIn">
                        <Typography variant="h5" pb={2} mt={2} mx={2} className="font-minang">
                        {props.islam == 1 ? '( QS. Ar-Rum ayat 21 )' : '( Markus 10:6-9 )'}</Typography>
                    </AnimationOnScroll>
                    </center> 
                }
                </Box>
            </div>
        }
        
    </div>
  );
}

export default SectionMempelaiMinang;
