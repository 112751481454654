import * as React from 'react';
import axios from 'axios';
import { useTheme, useMediaQuery } from '@material-ui/core';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

import GlobalVar from '../../GlobalVar';

const Mempelai = (props) =>  {

    const theme = useTheme();
    const mobile = useMediaQuery(theme.breakpoints.down('xs'));

    const path = window.location.search;
    const urlParams = new URLSearchParams(path);

    const [openLoading, setOpenLoading] = React.useState(false);
    const handleCloseLoading = () => {
      setOpenLoading(false);
    };
    const handleToggleLoading = () => {
      setOpenLoading(!openLoading);
    };

    const [nickPria, setNickPria] = React.useState('');
    const [fullPria, setFullPria] = React.useState('');
    const [ayahPria, setAyahPria] = React.useState('');
    const [ibuPria, setIbuPria] = React.useState('');
    // const [fotoPria, setFotoPria] = React.useState(null);

    const [nickWanita, setNickWanita] = React.useState('');
    const [fullWanita, setFullWanita] = React.useState('');
    const [ayahWanita, setAyahWanita] = React.useState('');
    const [ibuWanita, setIbuWanita] = React.useState(''); 
    // const [fotoWanita, setFotoWanita] = React.useState(null);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if(event.target.id == 'nickPria') {
            setNickPria(event.target.value);
            props.nickPria(event.target.value);
        } else if(event.target.id == 'fullPria') {
            setFullPria(event.target.value);
            props.fullPria(event.target.value);
        } else if(event.target.id == 'ayahPria') {
            setAyahPria(event.target.value);
            props.ayahPria(event.target.value);
        } else if(event.target.id == 'ibuPria') {
            setIbuPria(event.target.value);
            props.ibuPria(event.target.value);
        } else if(event.target.id == 'nickWanita') {
            setNickWanita(event.target.value);
            props.nickWanita(event.target.value);
        } else if(event.target.id == 'fullWanita') {
            setFullWanita(event.target.value);
            props.fullWanita(event.target.value);
        } else if(event.target.id == 'ayahWanita') {
            setAyahWanita(event.target.value);
            props.ayahWanita(event.target.value);
        } else if(event.target.id == 'ibuWanita') {
            setIbuWanita(event.target.value);
            props.ibuWanita(event.target.value);
        }
    };

    // const handleUploadClick = event => {
    //     var file = event.target.files[0];
    //     const reader = new FileReader();
    //     var url = reader.readAsDataURL(file);

    //     reader.onloadend = function(e) {
    //         if(event.target.id == 'fotoPria') {
    //             setFotoPria([reader.result]);
    //             props.fotoPria([reader.result]);
    //         } else if(event.target.id == 'fotoWanita') {
    //             setFotoWanita([reader.result]);
    //             props.fotoWanita([reader.result]);
    //         } 
    //     }.bind(this);

    //     if(event.target.id == 'fotoPria') {
    //         setFotoPria(URL.createObjectURL(event.target.files[0]));
    //         props.fotoPria(URL.createObjectURL(event.target.files[0]));
    //     } else if(event.target.id == 'fotoWanita') {
    //         setFotoWanita(URL.createObjectURL(event.target.files[0]));
    //         props.fotoWanita(URL.createObjectURL(event.target.files[0]));
    //     } 
    // };
    const pathParts = window.location.pathname.split('/');  

    React.useEffect(() => {
        handleToggleLoading(); 

        axios.get(GlobalVar.server+'GetData.php?n='+pathParts[1]).then(function(response){
            let res = JSON.stringify(response.data);
            let resP = JSON.parse(res);
            handleCloseLoading(); 

            setNickPria(resP[0].cf_mempelai_pria);
            setFullPria(resP[0].cf_mempelai_pria_full);
            setAyahPria(resP[0].cf_ayah_mempelai_pria);
            setIbuPria(resP[0].cf_ibu_mempelai_pria);
            // setFotoPria(`data:image/*;base64,${resP[0].cf_foto_mempelai_pria}`);
            props.nickPria(resP[0].cf_mempelai_pria);
            props.fullPria(resP[0].cf_mempelai_pria_full);
            props.ayahPria(resP[0].cf_ayah_mempelai_pria);
            props.ibuPria(resP[0].cf_ibu_mempelai_pria);
            // props.fotoPria(`data:image/*;base64,${resP[0].cf_foto_mempelai_pria}`);

            setNickWanita(resP[0].cf_mempelai_wanita);
            setFullWanita(resP[0].cf_mempelai_wanita_full);
            setAyahWanita(resP[0].cf_ayah_mempelai_wanita);
            setIbuWanita(resP[0].cf_ibu_mempelai_wanita);
            // setFotoWanita(`data:image/*;base64,${resP[0].cf_foto_mempelai_wanita}`);
            props.nickWanita(resP[0].cf_mempelai_wanita);
            props.fullWanita(resP[0].cf_mempelai_wanita_full);
            props.ayahWanita(resP[0].cf_ayah_mempelai_wanita);
            props.ibuWanita(resP[0].cf_ibu_mempelai_wanita);
            // props.fotoWanita(`data:image/*;base64,${resP[0].cf_foto_mempelai_wanita}`);
 
                         
        });

    }, []);

  return ( 
    <section>
        <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={openLoading}
            // onClick={handleCloseLoading}
            >
            <CircularProgress color="inherit" />
        </Backdrop>

      <center>

        <Grid container spacing={2} sx={{mt: 2}}>
{/* =============== PRIA =============== */}
            <Grid item xs={12} style={{textAlign: 'left'}}>
                <Typography variant={mobile ? "h5" : "h4"} className="font-jost">
                Mempelai Pria
                </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
                <TextField
                    id="nickPria"
                    label="Nama Pria (Panggilan)"
                    color='primary'
                    className='input'
                    value={nickPria} 
                    onChange={handleChange}
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <TextField
                    id="fullPria"
                    label="Nama Pria (Full)"
                    color='primary'
                    className='input'
                    value={fullPria} 
                    onChange={handleChange}
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <TextField
                    id="ayahPria"
                    label="Ayah Pria"
                    color='primary'
                    className='input'
                    value={ayahPria} 
                    onChange={handleChange}
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <TextField
                    id="ibuPria"
                    label="Ibu Pria"
                    color='primary'
                    className='input'
                    value={ibuPria} 
                    onChange={handleChange}
                />
            </Grid>
            {/* <Grid item xs={12} style={{textAlign: 'left'}}>
                <Typography variant={mobile ? "h6" : "h5"} mt={2} >Foto</Typography>
                <input
                    accept="image/*"
                    id="fotoPria"
                    multiple
                    type="file"
                    onChange={handleUploadClick}
                    style={{margin: '20px 0px'}}
                />
                <img src={fotoPria} width={240} />
            </Grid> */}
        </Grid>

        <Grid container spacing={2} sx={{mt: 4}}>
        {/* =============== WANITA =============== */}
            <Grid item xs={12} style={{textAlign: 'left'}}>
                <Typography variant={mobile ? "h5" : "h4"} className="font-jost">
                Mempelai Wanita
                </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
                <TextField
                    id="nickWanita"
                    label="Nama Wanita (Panggilan)"
                    color='primary'
                    className='input'
                    value={nickWanita} 
                    onChange={handleChange}
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <TextField
                    id="fullWanita"
                    label="Nama Wanita (Full)"
                    color='primary'
                    className='input'
                    value={fullWanita} 
                    onChange={handleChange}
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <TextField
                    id="ayahWanita"
                    label="Ayah Wanita"
                    color='primary'
                    className='input'
                    value={ayahWanita} 
                    onChange={handleChange}
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <TextField
                    id="ibuWanita"
                    label="Ibu Wanita"
                    color='primary'
                    className='input'
                    value={ibuWanita} 
                    onChange={handleChange}
                />
            </Grid>
            {/* <Grid item xs={12} style={{textAlign: 'left'}}>
                <Typography variant={mobile ? "h6" : "h5"} mt={2} >Foto</Typography>
                <input
                    accept="image/*"
                    id="fotoWanita"
                    multiple
                    type="file"
                    onChange={handleUploadClick}
                    style={{margin: '20px 0px'}}
                />
                <img src={fotoWanita} width={240} />
            </Grid> */}

        </Grid>
        
      </center>
    </section>

        
  );
}

export default Mempelai;