/**
 * @author Kevin.Bagasdwika
 * 
 */

 class GlobalVar {
//\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\
        
    static server = 'https://momentkami.com/api/';
    // static server = 'http://192.19.27.112/weddproject/api/';
    static audio = 'https://momentkami.com/audio';
    // static audio = 'http://192.19.27.112/weddproject/audio';
    
//\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\
}

export default GlobalVar;


