import * as React from 'react';
import { useTheme, useMediaQuery } from '@material-ui/core';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import { AnimationOnScroll } from 'react-animation-on-scroll';

import SwiperCore, { EffectCoverflow, Pagination, Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";
import 'swiper/css/autoplay';

import Lightbox from "yet-another-react-lightbox";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen";
import Slideshow from "yet-another-react-lightbox/plugins/slideshow";
import "yet-another-react-lightbox/styles.css";

SwiperCore.use([EffectCoverflow, Pagination, Autoplay]);

const SectionGallery = (props) =>  {
    const [indexImg, SetIndexImg] = React.useState(0);
    const [openLB, SetOpenLB] = React.useState(false);
    const pathParts = window.location.pathname.split('/');  
    const itemData = [ 
        {
            index: 0,
            img: props.foto1,
            title: 'Foto1',
        },{
            index: 1,
            img: props.foto2,
            title: 'Foto2',
        },{
            index: 2,
            img: props.foto3,
            title: 'Foto3',
        },{
            index: 3,
            img: props.foto4,
            title: 'Foto4',
        },{
            index: 4,
            img: props.foto5,
            title: 'Foto5',
        },{
            index: 5,
            img: props.foto6,
            title: 'Foto6',
        },    
    ];

    if(props.paketID == 2) {
        itemData.push(...[
            {
                index: 6,
                img: props.foto7,
                title: 'Foto7',
            },{
                index: 7,
                img: props.foto8,
                title: 'Foto8',
            },{
                index: 8,
                img: props.foto9,
                title: 'Foto9',
            },{
                index: 9,
                img: props.foto10,
                title: 'Foto10',
            },{
                index: 10,
                img: props.foto11,
                title: 'Foto11',
            },{
                index: 11,
                img: props.foto12,
                title: 'Foto12',
            }, 
        ]);
    }

    const itemDataLB = [ 
        {
            src: `data:image/png;base64, ${props.foto1}`,
        },
    ];
    if(props.foto2 != '') { itemDataLB.push(...[{src: `data:image/png;base64, ${props.foto2}`,} ]); }
    if(props.foto3 != '') { itemDataLB.push(...[{src: `data:image/png;base64, ${props.foto3}`,} ]); }
    if(props.foto4 != '') { itemDataLB.push(...[{src: `data:image/png;base64, ${props.foto4}`,} ]); }
    if(props.foto5 != '') { itemDataLB.push(...[{src: `data:image/png;base64, ${props.foto5}`,} ]); }
    if(props.foto6 != '') { itemDataLB.push(...[{src: `data:image/png;base64, ${props.foto6}`,} ]); }

    if(props.paketID == 2) {
        if(props.foto7 != '') { itemDataLB.push(...[{src: `data:image/png;base64, ${props.foto7}`,} ]); }
        if(props.foto8 != '') { itemDataLB.push(...[{src: `data:image/png;base64, ${props.foto8}`,} ]); }
        if(props.foto9 != '') { itemDataLB.push(...[{src: `data:image/png;base64, ${props.foto9}`,} ]); }
        if(props.foto10 != '') { itemDataLB.push(...[{src: `data:image/png;base64, ${props.foto10}`,} ]); }
        if(props.foto11 != '') { itemDataLB.push(...[{src: `data:image/png;base64, ${props.foto11}`,} ]); }
        if(props.foto12 != '') { itemDataLB.push(...[{src: `data:image/png;base64, ${props.foto12}`,} ]); }
    }

    const theme = useTheme();
    const mobile = useMediaQuery(theme.breakpoints.down('xs'));
    const tablet = useMediaQuery(theme.breakpoints.down('sm')); 

    const imgClick = (i) => {
        SetIndexImg(i);
        setTimeout(() => {
            SetOpenLB(true);
        }, 200);

    }


  return ( 
    <div style={{background: '#fff'}} className='sec-pastel-gallery'>

        {
            pathParts[1] == 'ghinabagas' ?
                <Swiper
                    effect={"coverflow"}
                    grabCursor={true}
                    centeredSlides={true}
                    slidesPerView={"auto"}
                    coverflowEffect={{
                    rotate: 50,
                    stretch: 0,
                    depth: 100,
                    modifier: 1,
                    slideShadows: false,
                    }}
                    pagination={false}
                    className="mySwiper"
                    autoplay
                >
                    {itemData.map((item) => {
                    return (
                        <SwiperSlide key={item.index} style={{ 'backgroundImage': `url(data:image/*;base64,${item.img})`, cursor: 'pointer', }} onClick={() => imgClick(item.index)}>
                        </SwiperSlide>
                    );
                    })}
                </Swiper>
            :
                <Box style={{marginTop: -16}}>
                    <ImageList variant="woven" cols={2} gap={3}>
                        {itemData.map((item) => (
                            
                                item.img != '' &&                
                                    <AnimationOnScroll animateIn="animate__fadeIn">
                                        <ImageListItem key={item} onClick={() => imgClick(item.index)} style={{cursor: 'pointer'}}>
                                            <img
                                                src={`data:image/png;base64, ${item.img}`}
                                                // alt={item.title}
                                                loading="lazy"
                                            />
                                        </ImageListItem>
                                    </AnimationOnScroll>
                        ))}
                    </ImageList>
                </Box>
        }        

        {
            openLB &&

            <Lightbox
                open={openLB}
                index={indexImg}
                close={() => { SetOpenLB(false); SetIndexImg(0); }}
                slides={itemDataLB}
                plugins={[Fullscreen,Slideshow,Zoom]}
                styles={{ root: { "--yarl__color_backdrop": "rgba(0, 0, 0, .8)" } }}
            />
                // <Lightbox images={itemDataLB} startIndex={indexImg} onClose={() => { SetOpenLB(false); SetIndexImg(0); }} />
        }
    </div>

        
  );
}

export default SectionGallery;