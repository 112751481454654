import * as React from 'react';
// import {Helmet} from "react-helmet";
import axios from 'axios';
import GlobalVar from '../GlobalVar';
import { useTheme, useMediaQuery, Divider } from '@material-ui/core';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import GestureIcon from '@mui/icons-material/Gesture';
import DraftsOutlinedIcon from '@mui/icons-material/DraftsOutlined';
import Grow from '@mui/material/Grow';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import PauseCircleIcon from '@mui/icons-material/PauseCircle';

import SectionNoPage from './Section-nopage';
import GenerateInvitation from './GenerateInvitation';
import MPanel from './MPanel';
import Momentkami from './Momentkami';
import { isEmpty } from 'lodash';

const server = GlobalVar.server;
let openingBg = [];

const OpeningInvitation = (props) =>  {

    const [hidden, setHidden] = React.useState(false);
    const [visibleContent, setVisibleContent] = React.useState(false);
    const [mempelaiPria, setMempelaiPria] = React.useState('');
    const [mempelaiWanita, setMempelaiWanita] = React.useState('');
    const [unduhOnly, setUnduhOnly] = React.useState('');
    const [themes, setThemes] = React.useState('');
    const [to, setTo] = React.useState('');
    const [toPageErr, setToPageErr] = React.useState(false);
    const [toPageGenerate, setToPageGenerate] = React.useState(false);
    const [toPageAdmin, setToPageAdmin] = React.useState(false);
    const [toMomentkami, setMomentkami] = React.useState(false);
    const [adminLock, setAdminLock] = React.useState(0);
    const [fotoShared, setFotoShared] = React.useState('');    
    const [cs_color, setCs_color] = React.useState('');      
    const [cs_islam, setCs_islam] = React.useState('');    
    const [audioMempelai, setAudioMempelai] = React.useState('');    
    
    
    const [hari, setHari] = React.useState('');
    const [tgl, setTgl] = React.useState('');
    const [bulan, setBulan] = React.useState('');
    const [tahun, setTahun] = React.useState('');
    
    const theme = useTheme();
    const mobile = useMediaQuery(theme.breakpoints.down('sm'));
    const toggleFullSceen = (): void => {
        if (!document.fullscreenElement) {
          document.documentElement.requestFullscreen();
        }
        else if (document.documentElement.requestFullscreen) {
            document.documentElement.requestFullscreen();    
          }
        else if (document.documentElement.mozRequestFullScreen) {
            document.documentElement.mozRequestFullScreen();     // Firefox
          }
          else if (document.documentElement.webkitRequestFullscreen) {
            document.documentElement.webkitRequestFullscreen();  // Safari
          }
          else if(document.documentElement.msRequestFullscreen) {
            document.documentElement.msRequestFullscreen();      // IE/Edge
          }
           else {
          if (document.exitFullscreen) {
            document.exitFullscreen();
          }
        }
      };
    
    const handleChange = () => {
        setHidden((prev) => !prev);
        setTimeout(() => {
            props.visible(true);
            playOrPause();
        }, 100);
    };
    
    const handleChangeFS = () => {
        setHidden((prev) => !prev);
        setTimeout(() => {
            props.visible(true);
            playOrPause();
            toggleFullSceen();
        }, 100);
    };
    
    const path = window.location.search;
    const urlParams = new URLSearchParams(path);
    const pathParts = window.location.pathname.split('/');  
    React.useEffect(() => {
        setHidden(true);
        if(isEmpty(pathParts[1])) {
            setMomentkami(true);
        } else {
            setTo(urlParams.get('to'));  
            if(urlParams.get('page') == 'GenerateInvitation') {
                if(isEmpty(pathParts[1])){                
                    setToPageErr(true);
                } else {
                    axios.get(server+'GetData.php?n='+pathParts[1]).then(function(response){
                        let res = JSON.stringify(response.data);
                        let resP = JSON.parse(res);

                        if(!isEmpty(resP)) {
                            setToPageGenerate(true);
                        } else {
                            setToPageErr(true);
                        }
                        
                    });
                    
                }
            } else if(urlParams.get('page') == 'MPanel') {
                if(!pathParts[1]){                
                    setToPageErr(true);
                } else {
                    axios.get(server+'GetData.php?n='+pathParts[1]).then(function(response){
                        let res = JSON.stringify(response.data);
                        let resP = JSON.parse(res);
                        if(!isEmpty(resP)) {
                            setAdminLock(resP[0].cs_lock);
                            setToPageAdmin(true);
                        } else {
                            setToPageErr(true);
                        }
                        
                    });
                    
                }
            } else {
                axios.get(server+'GetData.php?n='+pathParts[1]).then(function(response){
                    let res = JSON.stringify(response.data);
                    let resP = JSON.parse(res);
                    if(!isEmpty(resP)) {
                        const days = ['Minggu', 'Senin', 'Selasa', 'Rabu', 'Kamis', 'Jumat', 'Sabtu'];
                        const monthNames = ["Januari", "Februari", "Maret", "April", "May", "Juni",
                        "Juli", "Agustus", "September", "Oktober", "November", "Desember"
                        ];
                        let newTgl = new Date(resP[0].cf_unduh_only == 1 ? resP[0].cf_waktu_unduh_start : resP[0].cf_waktu_resepsi_start);
                        setHari(days[newTgl.getDay()]);
                        let bln = newTgl.getMonth()+1;
                        if(newTgl.getDate() < 10) {
                        setTgl('0'+newTgl.getDate());
                        } else {
                        setTgl(newTgl.getDate());
                        }
                        if(bln < 10) {
                          setBulan('0'+bln);
                        } else {
                          setBulan(bln);
                        } 
                        // setBulan(monthNames[newTgl.getMonth()]); //Month from 0 to 11
                        setTahun(newTgl.getFullYear());
                        
                        setMempelaiPria(resP[0].cf_mempelai_pria);
                        setMempelaiWanita(resP[0].cf_mempelai_wanita);
                        setFotoShared(resP[0].cf_foto_shared);
                        setUnduhOnly(resP[0].cf_unduh_only);
                        props.theme(resP[0].cs_theme);
                        setThemes(resP[0].cs_theme);
                        setCs_color(resP[0].cs_color);
                        setCs_islam(resP[0].cs_islam)
                        setAudioMempelai(resP[0].cf_audio_mempelai);

                    } else {
                        setToPageErr(true);
                    }
                    
                });
                
                if(!toPageErr){
                    axios.get(server+'GetImages6.php?n='+pathParts[1]).then(function(response){
                        let res = JSON.stringify(response.data);
                        let resP = JSON.parse(res);
                        setTimeout(() => {
                            openingBg = {
                                backgroundImage: `url("data:image/*;base64,${resP.fotoopening}")`,
                            };
                            setVisibleContent(true);
                        
                        }, 300);
    
                    });
                }
    
            }
        }

    }, []);

    const [play, setPlay] = React.useState(true);
    const playOrPause = () => {
        let audio = document.querySelector(".audio");
        play ? setPlay(false) : setPlay(true);
        play ? audio.play() : audio.pause();
    }

    return (
        <div>
        {/* <Helmet>
            <title>Momentkami - {mempelaiPria} & {mempelaiWanita} Invitation !</title>
            {
            urlParams.get('n') == 'arifalin' ?
                <meta itemprop="image" content="https://momentkami.com/assets/arifalin.png" />
            :
                <meta itemprop="image" content="https://momentkami.com/assets/rings.jpg" />
            }
            
            <meta property="og:image:height" content="512" />
            <meta property="og:image:width" content="512" />
            <meta property="og:image:type" content="image/*" />
        </Helmet> */}
            
        {
            toPageErr ? 
                <SectionNoPage />
            :

            toPageGenerate ?
                <GenerateInvitation />
            :

            toPageAdmin ?
                <MPanel adminLock={adminLock}/>
            :

            toMomentkami ?
                <Momentkami />
            
            :
            visibleContent &&
                // <Slide  direction="up"
                <div> 
                       <div style={{position: 'absolute', visibility: !hidden ? 'visible' : 'hidden' }}>        
                            <audio id='audio' className='audio' src={GlobalVar.audio+'_'+audioMempelai+'.mp3'} loop>
                                <source src={GlobalVar.audio+'_'+audioMempelai+'.mp3'} type="audio/mpeg" ></source>
                                <source src={GlobalVar.audio+'_'+audioMempelai+'.mp3'} type="audio/ogg" ></source>
                            </audio>
                            <IconButton
                                aria-label="audio"
                                color="inherit"
                                size="medium"
                                onClick={playOrPause}  
                                id="audioBtn" 
                                                    
                            >
                                { play ? 
                                    <PlayCircleIcon fontSize="inherit" 
                                        className={
                                            themes == 'Elegant' || themes == 'Elegant2' ? 'audioPlayer' 
                                            : themes == 'Ulos' || themes == 'Minang' || themes == 'Premium_Ulos' ? 'audioPlayer audioUlos' 
                                            : themes == 'Jawa' || themes == 'Premium_Jawa' ? 'audioPlayer audioJawa' 
                                            : themes == 'Classic_Blue' ? 'audioPlayerClassic Blue' 
                                            : themes == 'Classic_Orange' ? 'audioPlayerClassic Orange' 
                                            : themes == 'Classic_Green' ? 'audioPlayerClassic Green' 
                                            : themes == 'Classic_Red' ? 'audioPlayerClassic Red' 
                                            : themes == 'Blue_Pastel' ? 'audioPlayerPastel BluePastel' 
                                            : themes == 'Yellow_Pastel' ? 'audioPlayerPastel YellowPastel' 
                                            : themes == 'Pink_Pastel' ? 'audioPlayerPastel PinkPastel' 
                                            : themes == 'Green_Pastel' ? 'audioPlayerPastel GreenPastel' 
                                            : themes == 'GreenEl_Pastel' ? 'audioPlayerPastel GreenElPastel' 
                                            : 'audioPlayer'} sx={{ fontSize: 40 }} /> : 
                                    <PauseCircleIcon fontSize="inherit" 
                                        className={
                                            themes == 'Elegant' || themes == 'Elegant2' ? 'audioPlayer' 
                                            : themes == 'Ulos' || themes == 'Minang' || themes == 'Premium_Ulos' ? 'audioPlayer audioUlos' 
                                            : themes == 'Jawa' || themes == 'Premium_Jawa' ? 'audioPlayer audioJawa' 
                                            : themes == 'Classic_Blue' ? 'audioPlayerClassic Blue' 
                                            : themes == 'Classic_Orange' ? 'audioPlayerClassic Orange' 
                                            : themes == 'Classic_Green' ? 'audioPlayerClassic Green' 
                                            : themes == 'Classic_Red' ? 'audioPlayerClassic Red' 
                                            : themes == 'Blue_Pastel' ? 'audioPlayerPastel BluePastel' 
                                            : themes == 'Yellow_Pastel' ? 'audioPlayerPastel YellowPastel' 
                                            : themes == 'Pink_Pastel' ? 'audioPlayerPastel PinkPastel' 
                                            : themes == 'Green_Pastel' ? 'audioPlayerPastel GreenPastel' 
                                            : themes == 'GreenEl_Pastel' ? 'audioPlayerPastel GreenElPastel' 
                                            : 'audioPlayer'} sx={{ fontSize: 40 }} /> }            
                            </IconButton>
                        </div>
                <Grow
                    in={hidden}
                    style={{ transformOrigin: '0 0 0' }}
                    {...(hidden ? { timeout: 1200 } : {})} unmountOnExit
                >   
                    {
                        themes == 'Elegant' ?
                        // <OpeningElegant openingBg={openingBg} mempelaiPria={mempelaiPria} mempelaiWanita={mempelaiWanita} to={to} unduhOnly={unduhOnly} />
                        <header className={!hidden ? "App-header hidden" : "App-header"}>
                            <div className="App-bgimage" style={openingBg}></div>

                            <Stack className="App-inside">
                                {/* { unduhOnly == 0 ?
                                    <img src={require('../images/logoinvitation.png')} className="App-header-img"/>
                                :                                
                                    <Typography variant={mobile ? "h2" : "h1"} gutterBottom className="font-curly" style={{color: '#EEC373'}}>- Unduh Mantu -</Typography>                                       
                                } */}
                                <Typography variant={mobile ? "h3" : "h2"} gutterBottom className="font-italiana">{mempelaiWanita} & {mempelaiPria}</Typography>
                                <Typography variant={mobile ? "h3" : "h2"} className="font-curly" gutterBottom><i>Dear</i></Typography> 
                                {/* Kepada Bpk / Ibu / Saudara / i */}
                                <Typography variant={mobile ? "h6" : "h5"} className="font-italiana" mb={4}><b>{to}</b></Typography>
                                <Typography variant={mobile ? "body1" : "h5"} className="font-kulim">Tanpa Mengurangi Rasa Hormat,</Typography>
                                <Typography variant={mobile ? "body1" : "h5"} className="font-kulim" style={{width: mobile ? '75%' : '100%'}} gutterBottom mb={4}>Kami 
                                Mengundang Anda untuk Hadir di Acara {unduhOnly == 0 ? "Pernikahan" : ""} Kami.</Typography>

                                <Button disableElevation startIcon={<DraftsOutlinedIcon />} size={ mobile ? "medium" : "large" } 
                                className={cs_color == 'Green' ? "btnPrimaryGreen font-kulim" : "btnPrimary font-kulim"} onClick={handleChange}>Open Invitation</Button>
                            
                            </Stack> 
                        
                        </header>

                        : themes == 'Elegant2' ?
                        // <OpeningElegant openingBg={openingBg} mempelaiPria={mempelaiPria} mempelaiWanita={mempelaiWanita} to={to} unduhOnly={unduhOnly} />
                        <header className={!hidden ? "App-header hidden" : "App-header"}>
                            <div className="App-bgimage" style={openingBg}></div>

                            <Stack className="App-inside">
                                {/* { unduhOnly == 0 ?
                                    <img src={require('../images/logoinvitation.png')} className="App-header-img"/>
                                :                                
                                    <Typography variant={mobile ? "h2" : "h1"} gutterBottom className="font-curly" style={{color: '#EEC373'}}>- Unduh Mantu -</Typography>                                       
                                } */}
                                
                                {cs_islam == 1 &&
                                    <Typography variant={mobile ? "h4" : "h3"} mb={10}>بِسْمِ اللّهِ الرَّحْمَنِ الرَّحِيْمِ</Typography>
                                }

                                {pathParts[1] == 'pujabudi' &&
                                    <Typography variant={mobile ? "body1" : "h5"} className="font-kulim" gutterBottom><i>You are invited to the wedding of</i></Typography> 
                                }
                                
                                <Typography variant={mobile ? "h4" : "h2"} gutterBottom className="font-HelveticaB">{mempelaiWanita} & {mempelaiPria}</Typography>
                                <Typography variant={mobile ? "h3" : "h2"} className="font-curly" gutterBottom><i>Dear</i></Typography> 
                                {/* Kepada Bpk / Ibu / Saudara / i */}
                                <Typography variant={mobile ? "h6" : "h5"} className="font-italiana" mb={4}><b>{to}</b></Typography>
                                <Typography variant={mobile ? "body1" : "h5"} className="font-kulim">Tanpa Mengurangi Rasa Hormat,</Typography>
                                <Typography variant={mobile ? "body1" : "h5"} className="font-kulim" style={{width: mobile ? '75%' : '100%'}} gutterBottom mb={4}>Kami 
                                Mengundang Anda untuk Hadir di Acara {unduhOnly == 0 ? "Pernikahan" : ""} Kami.</Typography>

                                <Button disableElevation startIcon={<DraftsOutlinedIcon />} size={ mobile ? "medium" : "large" } 
                                className={cs_color == 'Green' ? "btnPrimaryGreen font-kulim" : "btnPrimary font-kulim"} onClick={handleChange}>Open Invitation</Button>
                            
                            </Stack> 
                        
                        </header>

                        : themes == 'Premium_Elegant' ?
                        // <OpeningElegant openingBg={openingBg} mempelaiPria={mempelaiPria} mempelaiWanita={mempelaiWanita} to={to} unduhOnly={unduhOnly} />
                        <header className={!hidden ? "App-header hidden" : "App-header"}>
                            <div className="App-bgimage" style={openingBg}></div>

                            <Stack className="App-inside">
                                {/* { unduhOnly == 0 ?
                                    <img src={require('../images/logoinvitation.png')} className="App-header-img"/>
                                :                                
                                    <Typography variant={mobile ? "h2" : "h1"} gutterBottom className="font-curly" style={{color: '#EEC373'}}>- Unduh Mantu -</Typography>                                       
                                } */}
                                <Typography variant={mobile ? "h3" : "h2"} gutterBottom className="font-italiana">{mempelaiWanita} & {mempelaiPria}</Typography>
                                <Typography variant={mobile ? "h3" : "h2"} className="font-curly" gutterBottom><i>Dear</i></Typography> 
                                {/* Kepada Bpk / Ibu / Saudara / i */}
                                <Typography variant={mobile ? "h6" : "h5"} className="font-italiana" mb={4}><b>{to}</b></Typography>
                                <Typography variant={mobile ? "body1" : "h5"} className="font-kulim">Tanpa Mengurangi Rasa Hormat,</Typography>
                                <Typography variant={mobile ? "body1" : "h5"} className="font-kulim" style={{width: mobile ? '75%' : '100%'}} gutterBottom mb={4}>Kami 
                                Mengundang Anda untuk Hadir di Acara {unduhOnly == 0 ? "Pernikahan" : ""} Kami.</Typography>

                                <Button disableElevation startIcon={<DraftsOutlinedIcon />} size={ mobile ? "medium" : "large" } 
                                className="btnPrimary font-kulim" onClick={handleChangeFS}>Open Invitation</Button>
                            
                            </Stack> 
                            
                        </header>

                        : themes == 'Ulos' || themes == 'Premium_Ulos' ?

                        <header className='App-header-Ulos'>
                            <div className="App-header-Ulos-Overlay"></div>
                            <div className='App-header-Ulos-Inside'>
                                <center>
                                { pathParts[1] == 'marhaidinnovia' ?
                                        <>
                                            <Typography variant={mobile ? "h3" : "h2"} className="font-XanhMono">{mempelaiPria} &</Typography>
                                            <Typography variant={mobile ? "h3" : "h2"} className="font-XanhMono" mb={2}>{mempelaiWanita}</Typography>
                                        </>
                                    :
                                        <>
                                            <Typography variant={mobile ? "h3" : "h2"} className="font-XanhMono">{mempelaiWanita} &</Typography>
                                            <Typography variant={mobile ? "h3" : "h2"} className="font-XanhMono" mb={2}>{mempelaiPria}</Typography>
                                        </>
                                }
                                    <div className='ulosDiv'></div>
                                    <Typography variant={mobile ? "body1" : "h5"} className="font-XanhMono" my={2}>Kepada Bpk / Ibu / Saudara / i</Typography> 
                                    {/* Kepada Bpk / Ibu / Saudara / i */}
                                    <Typography variant={mobile ? "h6" : "h5"} className="font-XanhMono" my={2}><b>{to}</b></Typography>
                                    <Typography variant={mobile ? "body1" : "h5"} className="font-XanhMono">Tanpa Mengurangi Rasa Hormat,</Typography>
                                    <Typography variant={mobile ? "body1" : "h5"} className="font-XanhMono" style={{width: mobile ? '75%' : '100%'}} gutterBottom mb={4}>Kami 
                                    Mengundang Anda untuk Hadir di Acara {unduhOnly == 0 ? "Pernikahan" : ""} Kami.</Typography>

                                    <Button disableElevation startIcon={<DraftsOutlinedIcon />} size={ mobile ? "medium" : "large" } 
                                    className="btnPrimaryUlos font-XanhMono" onClick={themes == 'Premium_Ulos' ? handleChangeFS : handleChange}>Open Invitation</Button>
                                </center>
                            </div>
                        </header>

                        : themes == 'Jawa' || themes == 'Premium_Jawa' ?

                        <header className='App-header-Jawa'>
                            <div className="App-header-Jawa-Overlay"></div>
                            <div className='App-header-Jawa-Inside'>
                                <center>
                                    <Typography variant={mobile ? "h4" : "h3"} gutterBottom className="font-jawa">{mempelaiWanita} &</Typography>
                                    <Typography variant={mobile ? "h4" : "h3"} className="font-jawa" mb={2}>{mempelaiPria}</Typography>
                                    <Typography variant={mobile ? "body1" : "h5"} className="font-XanhMono" my={2}>Kepada Bpk / Ibu / Saudara / i</Typography> 
                                    {/* Kepada Bpk / Ibu / Saudara / i */}
                                    <Typography variant={mobile ? "h6" : "h5"} className="font-XanhMono" my={2}><b>{to}</b></Typography>
                                    <Typography variant={mobile ? "body1" : "h5"} className="font-XanhMono">Tanpa Mengurangi Rasa Hormat,</Typography>
                                    <Typography variant={mobile ? "body1" : "h5"} className="font-XanhMono" style={{width: mobile ? '75%' : '100%'}} gutterBottom mb={4}>Kami 
                                    Mengundang Anda untuk Hadir di Acara {unduhOnly == 0 ? "Pernikahan" : ""} Kami.</Typography>

                                    <Button disableElevation startIcon={<DraftsOutlinedIcon />} size={ mobile ? "medium" : "large" } 
                                    className="btnPrimaryJawa font-XanhMono" onClick={themes == 'Premium_Jawa' ? handleChangeFS : handleChange}>Open Invitation</Button>
                                </center>
                            </div>
                        </header>

                        : themes == 'Blue_Pastel' || themes == 'Yellow_Pastel' || themes == 'Green_Pastel' || themes == 'Pink_Pastel' || themes == 'GreenEl_Pastel' ?
                        <header className={!hidden ? "App-header-colorTheme hidden" : "App-header-colorTheme"}>
                            <div className={"App-bgimage-colorTheme"} style={pathParts[1] !== 'amirahdidi' ? openingBg : {}}></div>

                            <Stack className={themes == 'Blue_Pastel' ? "App-inside-colorTheme BluePastel" : 
                            themes == 'Yellow_Pastel' ? "App-inside-colorTheme YellowPastel" : themes == 'Green_Pastel' ? "App-inside-colorTheme GreenPastel" 
                            : themes == 'Pink_Pastel' ? "App-inside-colorTheme PinkPastel" : themes == 'GreenEl_Pastel' ? pathParts[1] == 'amirahdidi' ? "App-inside-colorTheme GreenElPastel dark heartbg" : "App-inside-colorTheme GreenElPastel" : "App-inside-colorTheme"}> 

                                {/* {pathParts[1] == 'amirahdidi' && <img src={require('../images/bg_pastel/ornament-heart.png')} className="img-ornament-heart" width={48} />} */}
                                
                                {pathParts[1] == 'amirahdidi' && <Typography variant="h4" className='font-bonheur txtwedding' my={.5} >Wedding dengan diam</Typography>}
                                {pathParts[1] == 'amirahdidi' && <img src={require('../images/muslim_senyap.png')} style={{marginBottom: 20}} width={140} />}
                                <Typography variant={mobile ? "body1" : "h5"} className="font-jost" >Kepada :</Typography>  
                                {/* Kepada Bpk / Ibu / Saudara / i */}
                                <Typography variant={mobile ? "h6" : "h5"} className="font-jost" mb={4}><b>{to}</b></Typography>
                                {pathParts[1] == 'amirahdidi' && <img src={require('../images/bg_pastel/ornament1.png')} className="img-ornament1-top" width={240} style={{marginTop: -80, marginBottom: -40}} />}
                                {
                                    pathParts[1] == 'nagasuryana' || pathParts[1] == 'amirahdidi' ? 
                                        <Typography variant="h2" className="font-bonheur">{mempelaiPria} & <br /> {mempelaiWanita}</Typography>
                                    :
                                    pathParts[1] == 'silfiary' ? 
                                        <Typography variant="h2" className="font-bonheur">{mempelaiPria} <br /> & <br /> {mempelaiWanita}</Typography>
                                    :
                                        <Typography variant="h2" className="font-bonheur">{mempelaiWanita} & <br /> {mempelaiPria}</Typography>
                                    
                                }
                                {/* <Typography variant={mobile ? "body1" : "h5"} className="font-jost">Tanpa Mengurangi Rasa Hormat,</Typography>
                                <Typography variant={mobile ? "body1" : "h5"} className="font-jost" style={{width: mobile ? '75%' : '100%'}} gutterBottom mb={4}>Kami 
                                Mengundang Anda untuk Hadir di Acara {unduhOnly == 0 ? "Pernikahan" : ""} Kami.</Typography> */}          
                                {pathParts[1] == 'tyasarif' ?
                                    <>
                                        <Typography variant="subtitle1" className='hrOpeningPastel font-jost' style={{fontWeight: 'bold', borderBottom: 'none'}} mt={2} mb={0}>{31}. {12}. {2023}</Typography>
                                        <Typography variant="subtitle1" className='hrOpeningPastel font-jost' style={{fontWeight: 'bold', borderTop: 'none'}} mt={-1} mb={4}>{tgl}. {bulan}. {tahun}</Typography>
                                    </>
                                :
                                    <Typography variant="subtitle1" className='hrOpeningPastel font-jost' style={{fontWeight: 'bold'}} mt={2} mb={4}>{pathParts[1] == 'nagasuryana' && '21-'}{tgl}. {bulan}. {tahun}</Typography>
                                }
                                {pathParts[1] == 'amirahdidi' && <img src={require('../images/bg_pastel/ornament1.png')} className="img-ornament1-bottom" width={240} style={{marginTop: -60}} />}
                                <Button disableElevation startIcon={<DraftsOutlinedIcon />} size={ mobile ? "medium" : "large" } 
                                className="btnPrimary font-jost" onClick={handleChange}>Buka Undangan</Button>
                            
                            </Stack> 
                        
                        </header>

                        : themes == 'Minang' ?

                        <header className='App-header-Minang'>
                            <div className="App-header-Minang-Overlay"></div>
                            <div className='App-header-Minang-Inside'>
                                <center>
                                    <Typography variant={mobile ? "h3" : "h2"} className="font-satisfy">{mempelaiWanita} &</Typography>
                                    <Typography variant={mobile ? "h3" : "h2"} className="font-satisfy" mb={2}>{mempelaiPria}</Typography>
                                    <div className='MinangDiv'></div>
                                    <Typography variant={mobile ? "body1" : "h5"} className="font-jost font-thin" my={2}>Kepada Bpk / Ibu / Saudara / i</Typography> 
                                    {/* Kepada Bpk / Ibu / Saudara / i */}
                                    <Typography variant={mobile ? "h6" : "h5"} className="font-satisfy" my={2}><b>{to}</b></Typography>
                                    <Typography variant={mobile ? "body1" : "h5"} className="font-jost font-thin">Tanpa Mengurangi Rasa Hormat,</Typography>
                                    <Typography variant={mobile ? "body1" : "h5"} className="font-jost font-thin" style={{width: mobile ? '75%' : '100%'}} gutterBottom mb={4}>Kami 
                                    Mengundang Anda untuk Hadir di Acara {unduhOnly == 0 ? "Pernikahan" : ""} Kami.</Typography>

                                    <Button disableElevation startIcon={<DraftsOutlinedIcon />} size={ mobile ? "medium" : "large" } 
                                    className="btnPrimaryMinang font-jost" onClick={handleChange}>Open Invitation</Button>
                                </center>
                            </div>
                        </header>

                        : themes == 'Classic_Blue' || themes == 'Classic_Green' || themes == 'Classic_Orange' || themes == 'Classic_Red' ?
                        <header className={!hidden ? "Classic-header-colorTheme hidden" : "Classic-header-colorTheme"}>
                            <div className={"Classic-bgimage-colorTheme"} style={openingBg}></div>

                            <Stack className={themes == 'Classic_Blue' ? "Classic-inside-colorTheme ClassicBlue" : 
                            themes == 'Classic_Green' ? "Classic-inside-colorTheme ClassicGreen" 
                            : themes == 'Classic_Orange' ? "Classic-inside-colorTheme ClassicOrange" :  themes == 'Classic_Red' ? "Classic-inside-colorTheme ClassicRed" 
                            : "Classic-inside-colorTheme"}> 

                            { pathParts[1] == 'syarifahherman' ?
                                <Typography variant="h3" mb={2} mt={8} className="font-satisfy">{mempelaiWanita} & <br /> {mempelaiPria}</Typography>
                            :
                                <Typography variant="h2" mb={2} className="font-satisfy">{mempelaiWanita} & <br /> {mempelaiPria}</Typography>
                            }
                                <Typography variant={mobile ? "body1" : "h5"} className="font-jost" >Kepada Yth :</Typography>  
                                {/* Kepada Bpk / Ibu / Saudara / i */}
                                <Typography variant={mobile ? "h6" : "h5"} className="font-jost" mb={4}><b>{to}</b></Typography>                                                    
                                <Button disableElevation startIcon={<DraftsOutlinedIcon />} size={ mobile ? "medium" : "large" } 
                                className="btnPrimary font-jost" onClick={handleChange}>Buka Undangan</Button>
                            
                            </Stack> 

                        </header>

                        : ""
                        
                    }
                
                </Grow>
                </div>
        }
        </div>
    );
}

export default OpeningInvitation;