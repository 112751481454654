import * as React from 'react';
import { useTheme, useMediaQuery } from '@material-ui/core';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

const Galeri = (props) =>  {

    const theme = useTheme();
    const mobile = useMediaQuery(theme.breakpoints.down('xs'));


  return ( 
    <section class="">
      <center>

        <Typography variant={mobile ? "h3" : "h3"} className="font-kulim">
        Galeri
        </Typography>
        
      </center>
    </section>

        
  );
}

export default Galeri;