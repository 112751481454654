import * as React from 'react';
import { slice } from 'lodash'
import axios from 'axios';
import GlobalVar from '../../GlobalVar';
import { useTheme, useMediaQuery } from '@material-ui/core';
import Typography from '@mui/material/Typography';
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';
import Button from '@mui/material/Button';
import CloseIcon from '@mui/icons-material/Close';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import { AnimationOnScroll } from 'react-animation-on-scroll';

import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Divider from '@mui/material/Divider';

  
import SendIcon from '@mui/icons-material/Send';
import CheckIcon from '@mui/icons-material/Check';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import QuestionMarkSharpIcon from '@mui/icons-material/QuestionMarkSharp';
import CheckSharpIcon from '@mui/icons-material/CheckSharp';
import CloseSharpIcon from '@mui/icons-material/CloseSharp';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import RefreshIcon from '@mui/icons-material/Refresh';
import AccessTimeIcon from '@mui/icons-material/AccessTime';

const server = GlobalVar.server;

const SectionRSVPMinang = (props) =>  {
    const [openAlert, setOpenAlert] = React.useState(false);
    const [alertMsg, setAlertMsg] = React.useState('');
    const [alertInit, setAlertInit] = React.useState('');
    const [listItem, setListItem] = React.useState([]);
    // const [index, setIndex] = React.useState(5);
    // const initialPosts = slice(listItem, 0, index);
    const [isMore, setIsMore] = React.useState(true)
    
    const[tbu_pesan, SetTbu_pesan] = React.useState('');
    const[tbu_nama, SetTbu_nama] = React.useState('');
    const[tbu_konfirmasi_hadir, SetTbu_konfirmasi_hadir] = React.useState('hadir');
    const[usrname, SetUsrname] = React.useState('');
    const kehadiran = [
        {
          value: 'hadir',
          label: 'Ya, Saya Akan Hadir',
        },
        {
            value: 'tidak',
            label: 'Maaf, Saya Tidak Bisa Hadir',
        },
        {
            value: 'ragu',
            label: 'Saya Masih Ragu',
        },
    ]

    const theme = useTheme();
    const mobile = useMediaQuery(theme.breakpoints.down('xs'));

    const handleChangeNama = (event: React.ChangeEvent<HTMLInputElement>) => {
        SetTbu_nama(event.target.value);
    };
    const handleChangePesan = (event: React.ChangeEvent<HTMLInputElement>) => {
        SetTbu_pesan(event.target.value);
    };
    const handleChangeKehadiran = (event: React.ChangeEvent<HTMLInputElement>) => {
        SetTbu_konfirmasi_hadir(event.target.value);
    };
    // const loadMoreLess = () => {
    //     if (index >= listItem.length) {
    //         setIndex(5);
    //     } else {
    //         setIndex(index + 5);
    //     }        
    // }
    const pathParts = window.location.pathname.split('/');  

    React.useEffect(() => {
        
        const path = window.location.search;
        const urlParams = new URLSearchParams(path);
        SetUsrname(pathParts[1]);

        axios.get(server+'GetRSVP.php?n='+pathParts[1]).then(function(response){
            setListItem(response.data)
        });
        

    }, []);

    const refreshData = () => {
        axios.get(server+'GetRSVP.php?n='+usrname).then(function(response){
            setListItem(response.data)
        });
    }

    const submitForm = () => {

        if(tbu_nama!="" && tbu_pesan !="" && tbu_konfirmasi_hadir != ""){

            const dataInsert = {
                usrname,
                tbu_nama,
                tbu_pesan,
                tbu_konfirmasi_hadir
            }

            axios.post(server+'PostRSVP.php', dataInsert).then(function(response){
                let res = JSON.stringify(response.data);
                let resP = JSON.parse(res);

                if(resP.statusCode==200){
                    axios.get(server+'GetRSVP.php?&n='+usrname).then(function(response){
                        setListItem(response.data)
                        setAlertMsg('Terima kasih Ucapan & Doa nya :)');
                        SetTbu_pesan('');
                        SetTbu_nama('');
                        SetTbu_konfirmasi_hadir('hadir');
                        setAlertInit('OK');
                    });
                }
                else if(resP.statusCode==500){
                    setAlertMsg("Maaf, ucapan tidak berhasil dikirim, tolong dicoba kembali..");
                    setAlertInit('Err');
                }

                setOpenAlert(true);
            });
                
            
        }
        else{
            setAlertMsg('Maaf, Semua data harus diisi..');
            setAlertInit('Err');
            setOpenAlert(true);
        }
    }

  return ( 
    <div className='App-header-section-minang-rsvp'>
        
        <Box
            component="form"
            sx={{
                '& .MuiTextField-root': { my: 1 },
            }}
            noValidate
            autoComplete="off"
        >
            <Container>
            <center>
                <AnimationOnScroll animateIn="animate__fadeIn">
                    <Typography variant="h4" pt={4} mx={2} className="font-satisfy">Ucapan untuk Kami</Typography>
                    <Typography variant="h5" mb={4} mx={2} className='font-minang'> 
                        Berikan ucapan & doa restu anda untuk kami
                    </Typography>
                </AnimationOnScroll>
                                        <Collapse in={openAlert}>
                                            <Alert 
                                                icon={alertInit == 'OK' ? <CheckIcon fontSize="inherit" /> : <ErrorOutlineIcon fontSize="inherit" /> } 
                                                severity={alertInit == 'OK' ? "success" : "error"}
                                                action={
                                                <IconButton
                                                    aria-label="close"
                                                    color="inherit"
                                                    size="small"
                                                    onClick={() => {
                                                        setOpenAlert(false);
                                                    }}
                                                >
                                                    <CloseIcon fontSize="inherit" />
                                                </IconButton>
                                            }
                                            sx={{ mb: 2, fontSize: '13px'}}
                                            >
                                            {alertMsg}
                                            </Alert>
                                        </Collapse>
            <AnimationOnScroll animateIn="animate__fadeIn">
                <div>
                    <TextField 
                    id="tbu_nama" 
                    label="Nama" 
                    placeholder="Tulis nama anda / keluarga disini..."
                    variant="outlined" 
                    color='primary' 
                    className='input' 
                    value={tbu_nama} 
                    onChange={handleChangeNama} 
                    // sx={{ '.css-1sumxir-MuiFormLabel-root-MuiInputLabel-root.Mui-focused': { color: 'rgb(0,0,0,.67)', }, 
                    // '.css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {border: '1px solid rgb(0,0,0,.67)'}}} 
                    focused/>
                </div>
                    
                <div>
                    <TextField
                        id="tbu_konfirmasi_hadir"
                        select
                        label="Konfirmasi kehadiran"
                        value={tbu_konfirmasi_hadir}
                        onChange={handleChangeKehadiran}
                        helperText=""
                        className='input'
                        sx={{ textAlign: 'left' }}
                        // '.css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {border: '1px solid rgb(0,0,0,.67)'}}} 
                    >
                    {kehadiran.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                            {option.label}
                        </MenuItem>
                    ))}
                    </TextField>
                </div>
 
                <div>
                    <TextField
                        id="tbu_pesan"
                        label="Pesan"
                        placeholder="Tulis Ucapan & Doa anda disini..."
                        multiline
                        rows={6}
                        defaultValue=""
                        color='secondary'
                        className='input'
                        value={tbu_pesan} 
                        onChange={handleChangePesan}
                        focused
                        // sx={{ '.css-1sumxir-MuiFormLabel-root-MuiInputLabel-root.Mui-focused': { color: 'rgb(0,0,0,.67)', }, 
                        // '.css-dpjnhs-MuiInputBase-root-MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {border: '1px solid rgb(0,0,0,.67)'}}} 
                    />
                </div>
                
                <Button disableElevation startIcon={<SendIcon />} size="small" onClick={submitForm}
                className="btnPrimaryMinang font-jost " style={{marginTop: 20, marginBottom: 20}} >Send Message</Button>

            </AnimationOnScroll>    
        </center>

                {
                    listItem.length > 0 ?
                <AnimationOnScroll animateIn="animate__fadeIn">
                    <div style={{maxHeight: 350, overflowY: 'scroll', marginBottom: 40, border: '1px solid #dbb36d', borderRadius: 6}}>

                    {/* <center>
                        <IconButton
                            aria-label="refresh"
                            color="inherit"
                            size={mobile ? "medium" : "large"}
                            onClick={refreshData}       
                            sx={{marginBottom: 1}}                        
                        >
                            <RefreshIcon fontSize="inherit" />
                        </IconButton>
                    </center> */}
                            {
                                listItem.map((item) => (
                                <Card sx={{ overflowWrap: 'break-word', borderLeft: '1px solid #dbb36d',borderRight: '1px solid #dbb36d',borderTop: '1px solid #dbb36d',  
                                borderBottom: '3px solid #dbb36d', padding: mobile ? '0px 5px' : '0px 15px', background: 'rgba(219, 179, 109, .125)', color: '#dbb36d' }} 
                                elevation={0}>
                                    <CardContent>
                                        <Typography variant="caption" component="div" color="text.secondary" className="font-jost ">
                                        
                                        </Typography>
                                        <Typography variant="body1" component="div" className="font-jost ">
                                            {item.tbu_nama} { item.tbu_konfirmasi_hadir == 'hadir' ? <CheckSharpIcon sx={{ fontSize: 14,color: '#4D7C0F', marginRight: 1 }}/> 
                                        : item.tbu_konfirmasi_hadir == 'tidak' ? <CloseSharpIcon sx={{ fontSize: 14,color: '#F87171', marginRight: 1 }} />
                                        : <QuestionMarkSharpIcon sx={{ fontSize: 14,color: '#FACC15', marginRight: 1 }} />}  
                                        </Typography>
                                        <Typography variant="body1" className="font-jost">
                                            {item.tbu_pesan}
                                        </Typography>
                                        <Typography variant="caption" component="span" color="#dbb36d" className="font-jost " >
                                            <i><AccessTimeIcon sx={{ fontSize: 12, marginTop: 1 }}  /> {item.timeAgo}</i>
                                        </Typography>
                                    </CardContent>
                                </Card>
                                ))
                            
                            }
                            {/* <br></br>
                            <center>
                            {   
                                index >= listItem.length ? 
                                    listItem.length > 5 &&
                                    (
                                        <Button disableElevation startIcon={<ExpandLessIcon />} size="medium" style={{marginTop: 10, marginBottom: 40}} 
                                        onClick={loadMoreLess} className="btnPrimaryMinang font-jost"> Show Less</Button>
                                    ) 
                                    
                                : 
                                    listItem.length > 5 &&
                                (
                                    <Button disableElevation startIcon={<ExpandMoreIcon />} size="medium" style={{marginTop: 10, marginBottom: 40}} 
                                    onClick={loadMoreLess} className="btnPrimaryMinang font-jost" > Show More</Button>                                  
                                )
                            }
                            </center> */}
                    </div>
                </AnimationOnScroll>
                : ''
                }
                    
            </Container>
            
        </Box>        
      
    </div>
  );
}

export default SectionRSVPMinang;