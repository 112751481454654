import * as React from 'react';
import axios from 'axios';
import { useTheme, useMediaQuery } from '@material-ui/core';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import moment from 'moment';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

import GlobalVar from '../../GlobalVar';

const Acara = (props) =>  {

    const theme = useTheme();
    const mobile = useMediaQuery(theme.breakpoints.down('xs'));

    const path = window.location.search;
    const urlParams = new URLSearchParams(path);
    const [checkedCBResepsi, setCheckedCBResepsi] = React.useState(false);
  
    const [openLoading, setOpenLoading] = React.useState(false);
    const handleCloseLoading = () => {
      setOpenLoading(false);
    };
    const handleToggleLoading = () => {
      setOpenLoading(!openLoading);
    };

    const handleChangeCBResepsi = (event) => {
      setCheckedCBResepsi(event.target.checked);
        if(event.target.checked === false) {
            setCf_tempat_resepsi(cf_tempat_resepsi_temp);
            props.cf_tempat_resepsi(cf_tempat_resepsi_temp);
            setCf_gmaps_resepsi(cf_gmaps_resepsi_temp);
            props.cf_gmaps_resepsi(cf_gmaps_resepsi_temp);
        } else {
            setCf_tempat_resepsi(cf_tempat_akad);
            props.cf_tempat_resepsi(cf_tempat_akad);
            setCf_gmaps_resepsi(cf_gmaps_akad);
            props.cf_gmaps_resepsi(cf_gmaps_akad);
        }
    };

    const [cf_tempat_akad, setCf_tempat_akad] = React.useState('');
    const [cf_gmaps_akad, setCf_gmaps_akad] = React.useState('');
    const [cf_waktu_akad_start, setCf_waktu_akad_start] = React.useState('');
    const [cf_waktu_akad_end, setCf_waktu_akad_end] = React.useState('');
    const [cf_tempat_resepsi, setCf_tempat_resepsi] = React.useState('');
    const [cf_gmaps_resepsi, setCf_gmaps_resepsi] = React.useState('');
    const [cf_waktu_resepsi_start, setCf_waktu_resepsi_start] = React.useState('');
    const [cf_waktu_resepsi_end, setCf_waktu_resepsi_end] = React.useState('');
    const [cf_tempat_resepsi_temp, setCf_tempat_resepsi_temp] = React.useState('');
    const [cf_gmaps_resepsi_temp, setCf_gmaps_resepsi_temp] = React.useState('');

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if(event.target.id == 'cf_tempat_akad') {
            setCf_tempat_akad(event.target.value);
            props.cf_tempat_akad(event.target.value);
        } else if(event.target.id == 'cf_gmaps_akad') {
            setCf_gmaps_akad(event.target.value);
            props.cf_gmaps_akad(event.target.value);
        } else if(event.target.id == 'cf_tempat_resepsi') {
            setCf_tempat_resepsi(event.target.value);
            props.cf_tempat_resepsi(event.target.value);
        } else if(event.target.id == 'cf_gmaps_resepsi') {
            setCf_gmaps_resepsi(event.target.value);
            props.cf_gmaps_resepsi(event.target.value);
        }
    };
    const pathParts = window.location.pathname.split('/');  

    React.useEffect(() => {
        handleToggleLoading(); 

        axios.get(GlobalVar.server+'GetData.php?n='+pathParts[1]).then(function(response){
            let res = JSON.stringify(response.data);
            let resP = JSON.parse(res);
            handleCloseLoading(); 

            setCf_tempat_akad(resP[0].cf_tempat_akad);
            props.cf_tempat_akad(resP[0].cf_tempat_akad);
            setCf_gmaps_akad(resP[0].cf_gmaps_akad);
            props.cf_gmaps_akad(resP[0].cf_gmaps_akad);
            setCf_waktu_akad_start(resP[0].cf_waktu_akad_start);
            props.cf_waktu_akad_start(resP[0].cf_waktu_akad_start);
            setCf_waktu_akad_end(resP[0].cf_waktu_akad_end);
            props.cf_waktu_akad_end(resP[0].cf_waktu_akad_end);

            setCf_tempat_resepsi(resP[0].cf_tempat_resepsi);
            props.cf_tempat_resepsi(resP[0].cf_tempat_resepsi);
            setCf_gmaps_resepsi(resP[0].cf_gmaps_resepsi);
            props.cf_gmaps_resepsi(resP[0].cf_gmaps_resepsi);
            setCf_waktu_resepsi_start(resP[0].cf_waktu_resepsi_start);
            props.cf_waktu_resepsi_start(resP[0].cf_waktu_resepsi_start);
            setCf_waktu_resepsi_end(resP[0].cf_waktu_resepsi_end);
            props.cf_waktu_resepsi_end(resP[0].cf_waktu_resepsi_end);
            setCf_tempat_resepsi_temp(resP[0].cf_tempat_resepsi);
            setCf_gmaps_resepsi_temp(resP[0].cf_gmaps_resepsi);
            
        });

    }, []);

  return ( 
    <section>
        <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={openLoading}
            // onClick={handleCloseLoading}
            >
            <CircularProgress color="inherit" />
        </Backdrop>

        <Grid container
            direction="row"
            justifyContent="flex-start" spacing={2} sx={{mt: 2}}>
{/* =============== AKAD =============== */}
            <Grid item xs={12} style={{textAlign: 'left'}}>
                <Typography variant={mobile ? "h5" : "h4"} className="font-jost">
                Akad / Pemberkatan
                </Typography>
            </Grid>
            <Grid item xs={6} md={4}>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                <DateTimePicker
                    renderInput={(props) => <TextField {...props} />}
                    label="Mulai"
                    value={cf_waktu_akad_start}
                    onChange={(newValue) => {
                        setCf_waktu_akad_start(newValue);
                        props.cf_waktu_akad_start(newValue.format("YYYY-MM-D HH:mm:SS"));
                    }}
                />
                </LocalizationProvider>
            </Grid>
            <Grid item xs={6} md={4}>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                <DateTimePicker
                    renderInput={(props) => <TextField {...props} />}
                    label="Selesai (Opsional)"
                    value={cf_waktu_akad_end}
                    onChange={(newValue) => {
                        setCf_waktu_akad_end(newValue);
                        props.cf_waktu_akad_end(newValue.format("YYYY-MM-D HH:mm:SS"));
                    }}
                />
                </LocalizationProvider>
            </Grid>
            <Grid item xs={12}>
                <Typography variant={mobile ? "caption" : "body2"} className="font-jost" sx={{color: '#DC2626'}} mt={1} mb={2}>
                 *) Kosongkan kolom "Selesai (Opsional)" jika tidak ada batas waktu.
                </Typography>
            </Grid>
            
            <Grid item xs={12} md={6} >
                <TextField
                    id="cf_tempat_akad"
                    label="Lokasi"
                    color='primary'
                    className='input'
                    value={cf_tempat_akad} 
                    onChange={handleChange}
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <TextField
                    id="cf_gmaps_akad"
                    label="Link Google Maps"
                    color='primary'
                    className='input'
                    value={cf_gmaps_akad} 
                    onChange={handleChange}
                />
            </Grid>
{/* =============== RESEPSI =============== */}
            <Grid item xs={12} style={{textAlign: 'left'}}>
                <Typography variant={mobile ? "h5" : "h4"} className="font-jost">
                Resepsi
                </Typography>
            </Grid>
            <Grid item xs={6} md={4}>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                <DateTimePicker
                    renderInput={(props) => <TextField {...props} />}
                    label="Mulai"
                    value={cf_waktu_resepsi_start}
                    onChange={(newValue) => {
                        setCf_waktu_resepsi_start(newValue);
                        props.cf_waktu_resepsi_start(newValue.format("YYYY-MM-D HH:mm:SS"));
                        
                    }}
                />
                </LocalizationProvider>
            </Grid>
            <Grid item xs={6} md={4}>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                <DateTimePicker
                    renderInput={(props) => <TextField {...props} />}
                    label="Selesai (Opsional)"
                    value={cf_waktu_resepsi_end}
                    onChange={(newValue) => {
                        setCf_waktu_resepsi_end(newValue);
                        props.cf_waktu_resepsi_end(newValue.format("YYYY-MM-D HH:mm:SS"));
                    }}
                />
                </LocalizationProvider>
            </Grid>
            <Grid item xs={12}>
                <Typography variant={mobile ? "caption" : "body2"} className="font-jost" sx={{color: '#DC2626'}} mt={1} mb={2}>
                 *) Kosongkan kolom "Selesai (Opsional)" jika tidak ada batas waktu.
                </Typography>
            </Grid>
            
            <Grid item xs={12}>
                <FormGroup>
                    <FormControlLabel control={<Checkbox checked={checkedCBResepsi} onChange={handleChangeCBResepsi} />} label="Lokasi Sesuai Akad / Pemberkatan ?" />
                </FormGroup>
            </Grid>

        </Grid>
        {
            checkedCBResepsi === false &&
            <div style={{width: '100%'}}>
            <Grid container
                direction="row"
                justifyContent="flex-start" spacing={2} sx={{mt: 1}}>
                    <Grid item xs={12} md={6} >
                        <TextField
                            id="cf_tempat_resepsi"
                            label="Lokasi"
                            color='primary'
                            className='input'
                            value={cf_tempat_resepsi} 
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            id="cf_gmaps_resepsi"
                            label="Link Google Maps"
                            color='primary'
                            className='input'
                            value={cf_gmaps_resepsi} 
                            onChange={handleChange}
                        />
                    </Grid>
                </Grid>
            </div>
        }
        
    </section>    

        
  );
}

export default Acara;