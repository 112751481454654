import React from "react";
import { useTheme, useMediaQuery } from '@material-ui/core';
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import AppBar from '@mui/material/AppBar';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import Toolbar from '@mui/material/Toolbar';

import '../LandingPage.css';

import Home from "./momentkami/Home";
import Design from "./momentkami/Design";
import Harga from "./momentkami/Harga";
import Footer from "./momentkami/Footer";

import { Link } from "react-scroll";

const Momentkami = (props) => {
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('xs'));

  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);


  const drawerWidth = mobile ? 280 : 760;

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  React.useEffect(() => {
    // window.onload = () => {
    //   if(window.location.pathname == '/') {
    //     window.location.pathname = '/Home';
    //     pathActive = 'Home';   
    //   }
    // }
  },[])

  return (
      <div className='landingPage-Section'>          

        <div id="home"><Home /></div>
        <div id="design"><Design /></div>
        <div id="harga"><Harga /></div>

        <Footer/>
      </div>

  );
}
export default Momentkami;