import * as React from 'react';
import { slice } from 'lodash'
import axios from 'axios';
import GlobalVar from '../../GlobalVar';
import { useTheme, useMediaQuery } from '@material-ui/core';
import Typography from '@mui/material/Typography';
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';
import Button from '@mui/material/Button';
import CloseIcon from '@mui/icons-material/Close';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import { AnimationOnScroll } from 'react-animation-on-scroll';

import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Divider from '@mui/material/Divider';

import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import TimelineOppositeContent, {
    timelineOppositeContentClasses,
  } from '@mui/lab/TimelineOppositeContent';

const server = GlobalVar.server;

const SectionStory = (props) =>  {
    const theme = useTheme();
    const mobile = useMediaQuery(theme.breakpoints.down('xs'));
    const pathParts = window.location.pathname.split('/'); 

    React.useEffect(() => {
        
        const path = window.location.search;
        const urlParams = new URLSearchParams(path);

    }, []);

  return ( 
    <div className=''>
        
        <Box
            component="form"
            sx={{
                '& .MuiTextField-root': { my: 1 },
            }}
            noValidate
            autoComplete="off"
        >
            <Container>
            <center>
                <AnimationOnScroll animateIn="animate__fadeIn">
                    <Typography variant="h3" pt={4} className="font-curly">
                        Cerita Kami
                    </Typography>     
                    <Typography variant="body2"  mb={4} mx={2} className='font-kulim font-thin'> 
                        Perjalanan kami yang penuh dengan kebahagiaan dan syukur
                    </Typography>
                </AnimationOnScroll>

                <Timeline 
                    sx={{
                        [`& .${timelineOppositeContentClasses.root}`]: {
                        flex: 0.2,
                        },
                    }}>
                    <TimelineItem>
                        <TimelineOppositeContent color="text.secondary">
                        2018
                        </TimelineOppositeContent>
                        <TimelineSeparator>
                        <TimelineDot />
                        <TimelineConnector />
                        </TimelineSeparator>
                        <TimelineContent>
                            <Card >
                                <CardContent>
                                    <Typography variant="body1" component="div">
                                    Moment Bertemu
                                    </Typography>
                                    <Typography variant="body2">
                                    Lorem ipsum...
                                    </Typography>
                                </CardContent>
                            </Card>
                        </TimelineContent>
                    </TimelineItem>
                    <TimelineItem>
                        <TimelineOppositeContent color="text.secondary">
                        2019
                        </TimelineOppositeContent>
                        <TimelineSeparator>
                        <TimelineDot />
                        <TimelineConnector />
                        </TimelineSeparator>
                        <TimelineContent>
                            <Card >
                                <CardContent>
                                    <Typography variant="body1" component="div">
                                    Moment Awal Kami
                                    </Typography>
                                    <Typography variant="body2">
                                    Lorem ipsum...
                                    </Typography>
                                </CardContent>
                            </Card>
                        </TimelineContent>
                    </TimelineItem>
                    <TimelineItem>
                        <TimelineOppositeContent color="text.secondary">
                        2021
                        </TimelineOppositeContent>
                        <TimelineSeparator>
                        <TimelineDot />
                        <TimelineConnector />
                        </TimelineSeparator>
                        <TimelineContent>
                            <Card >
                                <CardContent>
                                    <Typography variant="body1" component="div">
                                    Moment Bahagia Kami
                                    </Typography>
                                    <Typography variant="body2">
                                    Lorem ipsum...
                                    </Typography>
                                </CardContent>
                            </Card>
                        </TimelineContent>
                    </TimelineItem>
                </Timeline>

            </center>        
            </Container>
            
        </Box>        
      
    </div>
  );
}

export default SectionStory;