import * as React from 'react';
import { useTheme, useMediaQuery } from '@material-ui/core';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

const SectionNoPage = (props) =>  {

    const theme = useTheme();
    const mobile = useMediaQuery(theme.breakpoints.down('xs'));


  return ( 
    <section class="page_404">
      <center>

        <Typography variant={mobile ? "h3" : "h3"} className="font-kulim">
          404
        </Typography>
        <Typography variant={mobile ? "h5" : "h4"} className="font-kulim font-thin">
          Page Not Found
        </Typography>

        <div class="four_zero_four_bg"></div>
        
        <Typography variant={mobile ? "h6" : "h5"} mt={4} className="font-kulim">
          Wrong wedding invitation URL
        </Typography>       
        
        <Typography variant={mobile ? "body1" : "h6"} className="font-kulim font-thin">
          please make sure the url you input is correct
        </Typography>   
        
      </center>
    </section>

        
  );
}

export default SectionNoPage;