import * as React from 'react';
import { useTheme, useMediaQuery } from '@material-ui/core';
import { Container, Typography, ImageList, ImageListItem, ImageListItemBar, Card, CardActions, CardContent, CardMedia,
  Chip, Tab, Box, Stack, Divider, Button } from '@mui/material';
  import WhatsAppIcon from '@mui/icons-material/WhatsApp';
  import VisibilityIcon from '@mui/icons-material/Visibility';

const itemData = [
  {
    id : 16,
    img: require('../../images/theme/elegant2-removebg.png'),
    title: 'ELEGANT 2',
    author: '#ELEGANT2',  
    link: 'https://momentkami.com/bintangbulan2/?to=Tamu+Undangan'
  },
  {
    id : 15,
    img: require('../../images/theme/red_classic-removebg.png'),
    title: 'CLASSIC RED',
    author: '#Classic_Red',
    link: 'https://momentkami.com/bintangbulan_cred/?to=Tamu+Undangan'
  },
  {
    id : 14,
    img: require('../../images/theme/green_classic-removebg.png'),
    title: 'CLASSIC GREEN',
    author: '#Classic_Green',
    link: 'https://momentkami.com/bintangbulan_cgreen/?to=Tamu+Undangan'
  },
  {
    id : 13,
    img: require('../../images/theme/orange_classic-removebg.png'),
    title: 'CLASSIC ORANGE',
    author: '#Classic_Orange',
    link: 'https://momentkami.com/bintangbulan_corange/?to=Tamu+Undangan'
  },
  {
    id : 12,
    img: require('../../images/theme/blue_classic-removebg.png'),
    title: 'CLASSIC BLUE',
    author: '#Classic_Blue',
    link: 'https://momentkami.com/bintangbulan_cblue/?to=Tamu+Undangan'
  },
  {
    id : 11,
    img: require('../../images/theme/pink_pastel-removebg.png'),
    title: 'PINK PASTEL',
    author: '#Pink_Pastel',
    link: 'https://momentkami.com/bintangbulan_pink/?to=Tamu+Undangan'
  },
  {
    id : 10,
    img: require('../../images/theme/green_pastel-removebg.png'),
    title: 'GREEN PASTEL',
    author: '#Green_Pastel',
    link: 'https://momentkami.com/bintangbulan_green/?to=Tamu+Undangan'
  },
  {
    id : 9,
    img: require('../../images/theme/yellow_pastel-removebg.png'),
    title: 'YELLOW PASTEL',
    author: '#Yellow_Pastel',
    link: 'https://momentkami.com/bintangbulan_yellow/?to=Tamu+Undangan'
  },
  {
    id : 8,
    img:  require('../../images/theme/blue_pastel-removebg.png'),
    title: 'BLUE PASTEL',
    author: '#Blue_Pastel',
    link: 'https://momentkami.com/bintangbulan_blue/?to=Tamu+Undangan'
  },
  {
    id : 7,
    img: require('../../images/theme/elegant-removebg.png'),
    title: 'ELEGANT',
    author: '#Elegant',
    link: 'https://momentkami.com/bintangbulan/?to=Tamu+Undangan'
  },
  {
    id : 6,
    img:  require('../../images/theme/premium-elegant-removebg.png'),
    title: 'PREMIUM ELEGANT',
    author: '#Premium_Elegant',
    link: 'https://momentkami.com/wulankimjongun/?to=Tamu+Undangan'
  },
];

const Theme2 = (props) =>  {
    const theme = useTheme();
    const mobile = useMediaQuery(theme.breakpoints.down('xs'));
    const tablet = useMediaQuery(theme.breakpoints.down('md'));


    const demo = (link) => {
        window.open(link, '_blank', 'noopener, noreferrer'); 
      }
      
      const WAMsg = (val) => {
          const msg = 'Hi, saya tertarik ingin membuat website nikah dengan tema '+val+' di momentkami.com, bisakah saya berdiskusi lebih lanjut ?';
          window.open('https://api.whatsapp.com/send?phone=+6281912428620&text='+msg+'&app_absent=0', '_blank', 'noopener,noreferrer'); 
      }
      
  return ( 
    <ImageList cols={mobile ? 1 : tablet ? 2 : 3} gap={mobile ? 5 : 20}>
      {itemData.map((item) => (
          <center>
            <Card sx={{ maxWidth: '100%'}} elevation={0}>
              <CardContent>
                <img
                  src={`${item.img}`}
                  alt={item.title}
                  loading="lazy" 
                  style={{width: mobile ? 160 : 200}}
                />
                <Typography gutterBottom variant="h5" component="div" mt={4}>
                  {item.title}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {item.author}
                </Typography>
                <Stack
                  direction="row"
                  divider={<Divider orientation="vertical" flexItem />}
                  spacing={2}
                  mt={2}
                  justifyContent={'center'}
                >
                  <Button className='btnDarkWht' size={mobile ? 'small' : 'medium'} sx={{padding: mobile ? '.5rem 1rem' : '.5rem 1.25rem' }} startIcon={<VisibilityIcon />} onClick={() => {demo(item.link); }}>
                    Demo
                  </Button>
                  <Button className='btnLightPurple' size={mobile ? 'small' : 'medium'} sx={{padding: mobile ? '.5rem 1rem' : '.5rem 1.25rem' }} startIcon={<WhatsAppIcon />} onClick={() => {WAMsg(item.title); }}>
                    Pesan
                  </Button>
                </Stack>
              </CardContent>
            </Card>
          </center>
      ))}
    </ImageList>
  );
}

export default Theme2;