import * as React from 'react';
import { useTheme, useMediaQuery } from '@material-ui/core';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Avatar from '@mui/material/Avatar';
import Container from '@mui/material/Container';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { AnimationOnScroll } from 'react-animation-on-scroll';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';

import MonetizationOnOutlinedIcon from '@mui/icons-material/MonetizationOnOutlined';
import DevicesIcon from '@mui/icons-material/Devices';
import ElectricBoltIcon from '@mui/icons-material/ElectricBolt';
import ShareOutlinedIcon from '@mui/icons-material/ShareOutlined';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import PostAddIcon from '@mui/icons-material/PostAdd';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import FavoriteIcon from '@mui/icons-material/Favorite';

import Fitur from './Fitur';

const Item = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(1),
}));

const bronze = [
  {
    text : 'Musik (Bebas Pilih)',
    bool : true
  },
  {
    text : 'Countdown Acara',
    bool : true
  },
  {
    text : 'Dompet Digital',
    bool : false
  },
  {
    text : 'Konfirmasi Kehadiran',
    bool : true
  },
  {
    text : 'Galeri Foto (Max. 6)',
    bool : true
  },
  {
    text : 'Video',
    bool : false
  },
  {
    text : 'Live Streaming',
    bool : false
  },
  {
    text : 'Navigasi Google Maps',
    bool : true
  },
  {
    text : 'Generate WhatsApp (Unlimited)',
    bool : true
  },
  {
    text : 'Masa Aktif (1 Bulan)',
    bool : true
  },
  {
    text : 'Revisi',
    bool : false
  },
];

const silver = [
  {
    text : 'Musik (Bebas Pilih)',
    bool : true
  },
  {
    text : 'Countdown Acara',
    bool : true
  },
  {
    text : 'Dompet Digital',
    bool : true
  },
  {
    text : 'Konfirmasi Kehadiran',
    bool : true
  },
  {
    text : 'Galeri Foto (Max. 6)',
    bool : true
  },
  {
    text : 'Video',
    bool : false
  },
  {
    text : 'Live Streaming',
    bool : false
  },
  {
    text : 'Navigasi Google Maps',
    bool : true
  },
  {
    text : 'Generate WhatsApp (Unlimited)',
    bool : true
  },
  {
    text : 'Masa Aktif (2 Bulan)',
    bool : true
  },
  {
    text : 'Revisi (Max. 1)',
    bool : true
  },
];

const Gold = [
  {
    text : 'Musik (Bebas Pilih)',
    bool : true
  },
  {
    text : 'Countdown Acara',
    bool : true
  },
  {
    text : 'Dompet Digital',
    bool : true
  },
  {
    text : 'Konfirmasi Kehadiran',
    bool : true
  },
  {
    text : 'Galeri Foto (Max. 12)',
    bool : true
  },
  {
    text : 'Video',
    bool : false
  },
  {
    text : 'Live Streaming',
    bool : true
  },
  {
    text : 'Navigasi Google Maps',
    bool : true
  },
  {
    text : 'Generate WhatsApp (Unlimited)',
    bool : true
  },
  {
    text : 'Masa Aktif (3 Bulan)',
    bool : true
  },
  {
    text : 'Revisi (Max. 3)',
    bool : true
  },
];

const SFY = [
  {
    text : 'Sertakan Gambar Background jika ada perubahan',
    bool : true
  },
  {
    text : 'Sertakan nama Font Fype jika ada perubahan',
    bool : true
  },
  {
    text : 'Sertakan Warna dalam bentuk HEX / RGB jika ada perubahan',
    bool : true
  },
  {
    text : 'Effect / Animasi tidak dapat diubah (default)',
    bool : true
  },
  {
    text : 'Revisi Max. 3',
    bool : true
  },
];

// const diamond = [
//   {
//     text : 'Musik (Bebas Pilih)',
//     bool : true
//   },
//   {
//     text : 'Countdown Acara',
//     bool : true
//   },
//   {
//     text : 'Dompet Digital',
//     bool : true
//   },
//   {
//     text : 'Doa & Ucapan',
//     bool : true
//   },
//   {
//     text : 'Konfirmasi Kehadiran',
//     bool : true
//   },
//   {
//     text : 'Galeri Foto (Unlimited)',
//     bool : true
//   },
//   {
//     text : 'Video',
//     bool : true
//   },
//   {
//     text : 'Live Streaming',
//     bool : true
//   },
//   {
//     text : 'Navigasi Google Maps',
//     bool : true
//   },
//   {
//     text : 'Generate Tamu Undangan (Unlimited)',
//     bool : true
//   },
//   {
//     text : 'Kirim WhatsApp',
//     bool : true
//   },
//   {
//     text : 'Revisi (Sampai Hari H)',
//     bool : true
//   },
//   {
//     text : 'Masa Aktif (Tanpa Batas)',
//     bool : true
//   }
// ];

const Harga = (props) =>  {
  const [dense, setDense] = React.useState(false);
  const [secondary, setSecondary] = React.useState(false);

    const theme = useTheme();
    const mobile = useMediaQuery(theme.breakpoints.down('xs'));

    React.useEffect(() => {
        
    }, []);

    const WAMsg = () => {
      const msg = 'Hi, saya tertarik ingin membuat website nikah dengan momentkami.com, bisakah saya berdiskusi lebih lanjut ?';
      window.open('https://api.whatsapp.com/send?phone=+6281912428620&text='+msg+'&app_absent=0', '_blank', 'noopener,noreferrer'); 
  }
    
  return ( 
    <div className='section harga'>

      <div className='sectionBackground'>

        <Container>
            <center>
              <AnimationOnScroll animateIn="animate__zoomIn">
                <Typography variant={mobile ? "h5" : "h4"} className="font-jost">Pilihan Paket Kami.</Typography>
                <Typography variant={mobile ? "body1" : "h6"} width={mobile ? '100%' : '80%'} mt={2} mb={mobile ? 0 : 6} className="font-jost font-thin">
                  Rekomendasi Pilihan Paket Berdasarkan Kebutuhan Undangan.</Typography>                
              </AnimationOnScroll>
            </center>
            
            <Grid container 
              mt={2}>
                <Grid item xs={12} md={4}>
                    <AnimationOnScroll animateIn="animate__fadeIn">
                      <Card style={{boxShadow: 'none'}}>
                        <CardContent>
                          <Typography variant={mobile ? "h6" : "h5"} sx={{color: '#cd7f32'}} gutterBottom className="font-jost">
                            <b>BRONZE</b>
                          </Typography>
                          <Typography variant={mobile ? "h4" : "h3"} sx={{ mt: 1.5, mb: 1 }} className="font-jost">
                            Rp. 50.000
                          </Typography>
                          <Typography variant={mobile ? "caption" : "body2"} color="#6750a4">
                            <b>Tema bebas pilih</b>
                          </Typography>
                          <List dense={dense}>
                            {bronze.map((item) =>
                              <ListItem key={item.text}>
                                { item.bool ? 
                                  <ListItemIcon>
                                    <CheckIcon sx={{color: '#059669'}} />
                                  </ListItemIcon>
                                  :
                                  <ListItemIcon>
                                    <CloseIcon sx={{color: '#DC2626'}} />
                                  </ListItemIcon> 
                                }
                                <ListItemText>
                                  <Typography variant={mobile ? "body2" : "body1"} className="font-jost">{item.text}</Typography>
                                </ListItemText>
                              </ListItem>
                            )}
                          </List>
                        </CardContent>
                      </Card>
                    </AnimationOnScroll>
                </Grid>
                <Grid item xs={12} md={4}>
                    <AnimationOnScroll animateIn="animate__fadeIn">
                      <Card style={{boxShadow: 'none'}}>
                        <CardContent>
                          <Typography variant={mobile ? "h6" : "h5"} sx={{color: '#c0c0c0'}} gutterBottom className="font-jost">
                            <b>SILVER</b>
                          </Typography>
                          <Typography variant={mobile ? "h4" : "h3"} sx={{ mt: 1.5, mb: 1 }} className="font-jost">
                            Rp. 75.000
                          </Typography>
                          <Typography variant={mobile ? "caption" : "body2"} color="#6750a4">
                            <b>Tema bebas pilih</b>
                          </Typography>
                          <List dense={dense}>
                            {silver.map((item) =>
                              <ListItem key={item.text}>
                                { item.bool ? 
                                  <ListItemIcon>
                                    <CheckIcon sx={{color: '#059669'}} />
                                  </ListItemIcon>
                                  :
                                  <ListItemIcon>
                                    <CloseIcon sx={{color: '#DC2626'}} />
                                  </ListItemIcon> 
                                }
                                <ListItemText>
                                  <Typography variant={mobile ? "body2" : "body1"} className="font-jost">{item.text}</Typography>
                                </ListItemText>
                              </ListItem>
                            )}
                          </List>
                        </CardContent>
                      </Card>
                    </AnimationOnScroll>
                </Grid>

                <Grid item xs={12} md={4} sx={{mt:mobile ? 6 : 0}}>
                    <AnimationOnScroll animateIn="animate__fadeIn">
                      <Card style={{boxShadow: 'none'}}>
                        {/* <CardHeader
                          avatar={<AutoAwesomeIcon sx={{color: '#EEC373'}}/>}
                          title="Terlaris"
                        /> */}
                        <CardContent>
                          <Typography variant={mobile ? "h6" : "h5"} sx={{color: '#dfbd69'}} gutterBottom className="font-jost">
                            <b>GOLD</b> <Typography variant={mobile ? "body2" : "body1"} ml={1} component='span' sx={{color: '#6750a4'}} className="font-jost"><AutoAwesomeIcon sx={{color: '#6750a4', fontSize: mobile ? 20 : 22}}/> Terlaris</Typography>
                          </Typography>
                          <Typography variant={mobile ? "h4" : "h3"} sx={{ mt: 1.5, mb: 1 }} className="font-jost">
                            Rp. 110.000
                          </Typography>
                          <Typography variant={mobile ? "caption" : "body2"} color="#6750a4">
                            <b>Tema bebas pilih</b>
                          </Typography>
                          <List dense={dense}>
                            {Gold.map((item) =>
                                <ListItem key={item.text}>
                                { item.bool ? 
                                  <ListItemIcon>
                                    <CheckIcon sx={{color: '#059669'}} />
                                  </ListItemIcon>
                                  :
                                  <ListItemIcon>
                                    <CloseIcon sx={{color: '#DC2626'}} />
                                  </ListItemIcon> 
                                }
                                <ListItemText>
                                  <Typography variant={mobile ? "body2" : "body1"} className="font-jost">{item.text}</Typography>
                                </ListItemText>
                              </ListItem>
                            )}
                          </List>
                        </CardContent>
                      </Card>
                    </AnimationOnScroll>
                </Grid>

                <Grid item xs={12} sx={{mt: 6}} style={{background: 'rgba(103, 80, 164, 0.175)'}}>
                    <center>
                    <AnimationOnScroll animateIn="animate__fadeIn">
                      <Card style={{boxShadow: 'none',background: 'transparent'}}>
                        <CardContent>
                          <Typography variant={mobile ? "h6" : "h5"} sx={{color: '#9b111e'}} gutterBottom className="font-jost">
                            <b>SPECIAL FOR YOU</b> <FavoriteIcon sx={{color: '#9b111e', fontSize: 24}}/>
                          </Typography>
                          <Typography variant={mobile ? "h4" : "h3"} sx={{ mt: 1.5, mb: 1 }} className="font-jost">
                            Paket + Rp. 150.000
                          </Typography>
                          <Typography variant={mobile ? "caption" : "body2"} color="#6750a4">
                            <b>Tema Custom</b>
                          </Typography>
                          <List>
                              <Grid container mt={2}>
                                  {SFY.map((item) =>
                                    <Grid xs={12} md={6}>
                                        <ListItem key={item.text}>
                                        { item.bool ? 
                                          <ListItemIcon>
                                            *)
                                          </ListItemIcon>
                                          :
                                          <ListItemIcon>
                                            <CloseIcon sx={{color: '#DC2626'}} />
                                          </ListItemIcon> 
                                        }
                                        <ListItemText>
                                          <Typography ml={-3} variant={mobile ? "body2" : "body1"} className="font-jost">{item.text}</Typography>
                                        </ListItemText>
                                      </ListItem>
                                    </Grid>
                                  )}
                              </Grid>
                          </List>
                        </CardContent>
                      </Card>
                    </AnimationOnScroll>
                    </center>
                </Grid>

                {/* <Grid item xs={12} md={4}>
                    <Item className=''>
                    <AnimationOnScroll animateIn="animate__fadeIn">
                      <Card style={{boxShadow: 'none'}}>
                        <CardContent>
                          <Typography variant={mobile ? "h6" : "h5"} gutterBottom className="font-jost">
                            Diamond
                          </Typography>
                          <Typography variant={mobile ? "h4" : "h3"} sx={{ mt: 1.5, mb: 1 }} className="font-jost">
                            Rp. 550.000
                          </Typography>
                          <Typography variant={mobile ? "caption" : "body2"} color="#6750a4">
                            <b>Tema bebas pilih</b>
                          </Typography>
                          <List dense={dense}>
                            {diamond.map((item) =>
                              <ListItem key={item.text}>
                                { item.bool ? 
                                  <ListItemIcon>
                                    <CheckIcon sx={{color: '#059669'}} />
                                  </ListItemIcon>
                                  :
                                  <ListItemIcon>
                                    <CloseIcon sx={{color: '#DC2626'}} />
                                  </ListItemIcon> 
                                }
                                <ListItemText>
                                  <Typography variant={mobile ? "body2" : "body1"} className="font-jost">{item.text}</Typography>
                                </ListItemText>
                              </ListItem>
                            )}
                          </List>
                        </CardContent>
                      </Card>
                    </AnimationOnScroll>
                    </Item>
                </Grid> */}

            </Grid>
        </Container>

          <div className='daftar'>
            <center>
              <AnimationOnScroll animateIn="animate__zoomIn">
                <Typography variant={mobile ? "h5" : "h4"} gutterBottom className="font-jost">
                  Bagi Moment Bahagia Semudah & Secepat Itu.
                </Typography>
              </AnimationOnScroll>

              <AnimationOnScroll animateIn="animate__zoomIn">
                <Typography variant={mobile ? "body2" : "body1"} gutterBottom mb={2} className="font-jost font-thin">
                  Anda Pasti Sudah Tidak Sabar Lagi Untuk Membagikan Moment Bahagianya Kan
                </Typography>
              </AnimationOnScroll>

              <AnimationOnScroll animateIn="animate__zoomIn">
                <Typography variant={mobile ? "h4" : "h3"} gutterBottom mb={2} className="font-curly">
                  Ayo Segera Hubungi Kami Untuk Pemesanan !
                </Typography>
              </AnimationOnScroll>

              <AnimationOnScroll animateIn="animate__fadeInUp">
                <Button 
                  variant="outlined" 
                  onClick={WAMsg}
                  size={mobile ? 'small' : 'large'}    
                  sx={{padding: mobile ? '.5rem 1.25rem' : '.75rem 1.5rem'}}    
                  className='btnLight'
                  startIcon={<WhatsAppIcon />}
                  >
                    Hubungi Kami
                </Button>
              </AnimationOnScroll>
            </center>
              <Fitur />
          </div>

          <svg style={{transform: 'rotate(180deg)'}} id="svg" viewBox="0 0 1440 590" xmlns="http://www.w3.org/2000/svg" class="transition duration-300 ease-in-out delay-150">             
              {/* <defs><linearGradient id="gradient" x1="0%" y1="51%" x2="100%" y2="49%"><stop offset="5%" stop-color="#abb8c3"></stop><stop offset="95%" stop-color="#6750a4"></stop></linearGradient></defs><path d="M 0,600 C 0,600 0,150 0,150 C 140.21428571428572,134.71428571428572 280.42857142857144,119.42857142857143 378,115 C 475.57142857142856,110.57142857142857 530.5,117.00000000000001 660,115 C 789.5,112.99999999999999 993.5714285714287,102.57142857142857 1136,107 C 1278.4285714285713,111.42857142857143 1359.2142857142858,130.71428571428572 1440,150 C 1440,150 1440,600 1440,600 Z" stroke="none" stroke-width="0" fill="url(#gradient)" fill-opacity="0.4" class="transition-all duration-300 ease-in-out delay-150 path-0"></path> */}
              <defs><linearGradient id="gradient" x1="0%" y1="51%" x2="100%" y2="49%"><stop offset="5%" stop-color="#abb8c3"></stop><stop offset="95%" stop-color="#6750a4"></stop></linearGradient></defs><path d="M 0,600 C 0,600 0,300 0,300 C 120.39285714285714,323.1428571428571 240.78571428571428,346.2857142857143 368,325 C 495.2142857142857,303.7142857142857 629.2500000000001,237.99999999999994 749,243 C 868.7499999999999,248.00000000000006 974.2142857142856,323.7142857142858 1087,345 C 1199.7857142857144,366.2857142857142 1319.8928571428573,333.1428571428571 1440,300 C 1440,300 1440,600 1440,600 Z" stroke="none" stroke-width="0" fill="url(#gradient)" fill-opacity="0.53" class="transition-all duration-300 ease-in-out delay-150 path-1"></path>
              <defs><linearGradient id="gradient" x1="0%" y1="51%" x2="100%" y2="49%"><stop offset="5%" stop-color="#abb8c3"></stop><stop offset="95%" stop-color="#6750a4"></stop></linearGradient></defs><path d="M 0,600 C 0,600 0,450 0,450 C 114.57142857142858,416.25 229.14285714285717,382.5 339,395 C 448.85714285714283,407.5 554,466.24999999999994 670,470 C 786,473.75000000000006 912.8571428571429,422.50000000000006 1043,410 C 1173.142857142857,397.49999999999994 1306.5714285714284,423.75 1440,450 C 1440,450 1440,600 1440,600 Z" stroke="none" stroke-width="0" fill="url(#gradient)" fill-opacity="1" class="transition-all duration-300 ease-in-out delay-150 path-2"></path>
            </svg>
        </div>
    </div>
  );
}

export default Harga;