import * as React from 'react';
import { useTheme, useMediaQuery } from '@material-ui/core';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import { AnimationOnScroll } from 'react-animation-on-scroll';
import ReactAudioPlayer from 'react-audio-player';
import GlobalVar from '../../GlobalVar';
  
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import PauseCircleIcon from '@mui/icons-material/PauseCircle';

const SectionFooterClassic = (props) =>  {
    const [custname, setCustname] = React.useState('');
    const theme = useTheme();
    const mobile = useMediaQuery(theme.breakpoints.down('xs'));
    const pathParts = window.location.pathname.split('/'); 

    const [year, setYear] = React.useState(null);
    
    const WAMsg = () => {
        const msg = 'Hi, saya tertarik ingin membuat website nikah dengan momentkami.com, bisakah saya berdiskusi lebih lanjut ?';
        window.open('https://api.whatsapp.com/send?phone=+6281912428620&text='+msg+'&app_absent=0', '_blank', 'noopener,noreferrer'); 
    }

    React.useEffect(() => {
        const path = window.location.search;
        const urlParams = new URLSearchParams(path);
        setCustname(pathParts[1]);
        const d = new Date();
        setYear(d.getFullYear());
    }, []);


  return ( 
    <div style={{ marginTop: -16, paddingBottom: 100 }} className={props.themePage == 'Classic_Blue' ? 'sec-classic-footer Blue' 
    : 
        props.themePage == 'Classic_Green' ? 'sec-classic-footer Green' 
    : 
        props.themePage == 'Classic_Orange' ? 'sec-classic-footer Orange' 
    : 
        props.themePage == 'Classic_Red' ? 'sec-classic-footer Red' 
    :  'sec-classic-footer '}  >
        
            {/* <ReactAudioPlayer
            src={GlobalVar.audio+'_'+props.audioMempelai+'.mp3'}
            className='audioPlayer'
            autoPlay
            muted={play}
            loop={true}
            /> */}
            

        <center>
            <AnimationOnScroll animateIn="animate__fadeInDown">
                <Typography variant="h6" mt={4} mx={2} className="font-jost font-thin" style={{letterSpacing: 4}} >Made With 
                <FavoriteBorderIcon sx={{ fontSize: 20 }} style={{position: 'relative',left: 5,top: 3}}/> By</Typography>
            </AnimationOnScroll>

            <Typography variant={mobile ? "h3" : "h2"} my={2} mx={2} className="font-curly logotxt" style={{cursor: 'pointer'}}
             onClick={()=>window.open('https://momentkami.com', '_blank')}><b>Momentkami</b></Typography>

            <Typography variant={mobile ? "caption" : "body2"} mx={2} className="font-jost font-thin" >
                Copyright © {year}. All rights reserved
            </Typography>
        </center>
    </div>
  );
}

export default SectionFooterClassic;