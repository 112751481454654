import * as React from 'react';
import axios from 'axios';
import { useTheme, useMediaQuery } from '@material-ui/core';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import Collapse from '@mui/material/Collapse';
import Alert from '@mui/material/Alert';
import CloseIcon from '@mui/icons-material/Close';
import GlobalVar from '../GlobalVar';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Snackbar from '@material-ui/core/Snackbar';

import { AnimationOnScroll } from 'react-animation-on-scroll';

import GroupAddOutlinedIcon from '@mui/icons-material/GroupAddOutlined';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import CheckIcon from '@mui/icons-material/Check';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { isEmpty } from 'lodash';

const server = GlobalVar.server;

const GenerateInvitation = (props) =>  {
    const [openSnackbar, setOpenSnackbar] = React.useState(false);
    const [openAlert, setOpenAlert] = React.useState(false);
    const [alertMsg, setAlertMsg] = React.useState('');
    const [alertInit, setAlertInit] = React.useState('');
    const [lsu_nama, setLsu_nama] = React.useState('');
    const [lsu_pesan, setLsu_pesan] = React.useState('');
    const [pathLink, setPathLink] = React.useState('');
    const [dataTamu, setDataTamu] = React.useState([]);

    const theme = useTheme();
    const mobile = useMediaQuery(theme.breakpoints.down('xs'));

    const path = window.location.search;
    const urlParams = new URLSearchParams(path);
    const pathParts = window.location.pathname.split('/');    
    // setPathLink(`https://momentkami.com/?n=${urlParams.get('n')}&to=${event.target.value}`);

    let text = "Tanpa mengurangi rasa hormat, perkenankan kami mengundang Bapak/Ibu/Saudara/i nama_tamu untuk menghadiri acara kami.\n\n"+
    
    "*Berikut link undangan kami*, untuk info lengkap dari acara bisa kunjungi :\n\n"+ 
    // `data:image/*;base64,${props.fotoShared}` +
    
    "link_undangan \n\n"+
    
    "Merupakan suatu kebahagiaan bagi kami apabila Bapak/Ibu/Saudara/i berkenan untuk hadir dan memberikan doa restu.\n\n"+
    
    "Terima kasih banyak atas perhatiannya.";
    
    let textPujaBudi = "Assalamu'alaikum Wr. Wb\n"+
    "Bismillahirahmanirrahim.\n\n"+
    "Yth. nama_tamu \n\n"+ 
    "Tanpa mengurangi rasa hormat, perkenankan kami memberitahukan Bapak/Ibu/Saudara/i, teman sekaligus sahabat, acara pernikahan kami :\n\n"+
    "Puja & Budi \n\n"+ 
    "Berikut link undangan kami untuk info lengkap dari acara bisa kunjungi :\n\n"+
    "link_undangan \n\n"+
    "Merupakan suatu kebahagiaan bagi kami apabila Bapak/Ibu/Saudara/i berkenan memberikan doa restu."+
    "Mohon maaf perihal undangan hanya di bagikan melalui pesan ini. Terima kasih banyak atas perhatiannya. \n\n" +
    "Wassalamu'alaikum Wr. Wb.\n" +
    "Terima Kasih.";

    let textRizal = "Assalamualaikum Warahmatullahi Wabarakaatuh. \n\n"+
    
    "Yth. nama_tamu \n\n"+

    "Semoga dalam keadaan sehat selalu \n\n"+

    "Tanpa mengurangi rasa hormat, perkenankan kami mengundang Bapak/Ibu/Saudara/i, untuk dapat hadir di acara bahagia momen akad dan resepsi pernikahan  : \n\n"+

    "Ditta Nisa Rofa & Muhammad Rizal \n\n"+

    "Berikut tautan link undangan  : \n\n"+

    "link_undangan \n\n"+

    "merupakan suatu kebahagiaan apabila Bapak/Ibu/Saudara/i berkenan untuk hadir dan memberikan doa restu. Kami ucapkan terima kasih atas perhatian Bapak/ibu/Saudara/i. \n\n"+

    "Wassalamualaikum Warahmatullahi Wabarakaatuh";
    
    let textMaya = "Bismillahirrahmanirrahim. \n\n"+
    
    "Assalamualaikum Wr. Wb. \n\n"+

    "Tanpa mengurangi rasa hormat, perkenankan kami mengundang Bapak/Ibu/Saudara/i nama_tamu untuk menghadiri acara pernikahan kami. \n\n"+

    "*Rizki Maulana & Sri Maya Sari* \n\n"+

    "*Akad Nikah* \n"+
    
    "Hari, Tgl : Jumat, 5 Mei 2023 \n"+
    
    "Jam : 09.00 - 10.00 WIB \n"+
    
    "Live Instagram : @srimayasari27 \n\n"+ 
    
    "*Resepsi* \n"+ 
    
    "Hari, Tgl : Sabtu, 6 Mei 2023 \n"+ 
    
    "Jam : 09.00 WIB - selesai \n"+ 
    
    "Lokasi : Perumahan Bakti Karya Asri 2 Blok D No. 4 Jalan Karya Masa, Pekanbaru \n\n"+ 

    "*Berikut link undangan kami*, untuk info lengkap dari acara bisa kunjungi : \n\n"+

    "link_undangan \n\n"+

    "Merupakan suatu kebahagiaan bagi kami apabila Bapak/Ibu/Saudara/i berkenan untuk hadir dan memberikan doa restu. \n\n"+

    "*Mohon maaf perihal undangan hanya di bagikan melalui pesan ini.* \n\n"+

    "Terima kasih banyak atas perhatiannya. \n\n"+

    "Wassalamualaikum Wr. Wb.";

    const handleChangeNama = (event: React.ChangeEvent<HTMLInputElement>) => {
        setLsu_nama(event.target.value);
    };

    const handleChangePesan = (event: React.ChangeEvent<HTMLInputElement>) => {
        setLsu_pesan(event.target.value);
    };

    const handleClickSnackbar = () => {
        setOpenSnackbar(true);
    };

    const handleCloseSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
        return;
        }

        setOpenSnackbar(false);
    };

    const copyLink = (nama) => {
        let textRes = lsu_pesan;

        var mapObj = {
            nama_tamu: nama,
            link_undangan: `https://momentkami.com/${pathParts[1]}/?to=${nama.split(' ').join('+')}`,
         };

         textRes = textRes.replace(/nama_tamu|link_undangan/gi, function(matched){
           return mapObj[matched];
         });

        //  alert(encodeURI(textRes))
        // `data:image/*;base64,${props.fotoShared}`+' '+
        setTimeout(() => {
            window.open('https://api.whatsapp.com/send?phone=&text='+encodeURIComponent(textRes), '_blank', 'noopener,noreferrer');    
            // window.open('https://wa.me/?text='+encodeURIComponent(textRes)+'&media='+props.fotoShared, '_blank', 'noopener');           
        }, 200);
        //  navigator.clipboard.writeText(textRes); handleClickSnackbar();

    }

    const saveTamu = () => {
        let arr = lsu_nama.trim().split('\n');
        let dataInsert = {
            lsu_cs_name: pathParts[1],
            lsu_nama : arr
        }

        axios.post(server+'PostTamuUndangan.php', dataInsert).then(function(response){
            let res = JSON.stringify(response.data);
            let resP = JSON.parse(res);

            if(resP.statusCode==200){
                axios.get(server+'GetTamuUndangan.php?&n='+pathParts[1]).then(function(response){
                    setDataTamu(response.data)
                    setLsu_nama('');
                    setAlertMsg("Data tamu berhasil ditambah :)");
                    setAlertInit('OK');
                });
            }
            else if(resP.statusCode==500){
                setAlertMsg("Maaf, terjadi gangguan di server, tolong dicoba kembali..");
                setAlertInit('Err');
            }

            setOpenAlert(true);
        });     
        
    }

    React.useEffect(() => {

        axios.get(server+'GetTamuUndangan.php?&n='+pathParts[1]).then(function(response){
            setDataTamu(response.data)
        });
    
        pathParts[1] == 'rizalditta' ?  setLsu_pesan(textRizal) : pathParts[1] == 'kikimaya' ? setLsu_pesan(textMaya) : pathParts[1] == 'pujabudi' ? setLsu_pesan(textPujaBudi) : setLsu_pesan(text);

    }, []);

  return ( 
    <div className='SectionGenerate'>
        
            <Typography variant={mobile ? "h6" : "h5"} mt={4} className="font-kulim" style={{letterSpacing: 1}} >Silahkan Masukan Pesan</Typography>
            <Typography variant={mobile ? "body2" : "body1"} mt={1} className="font-kulim font-thin">
                * Jangan hapus text ini <b>link_undangan</b> agar otomatis tercantumkan link kehalaman undangan.</Typography>
            <Typography variant={mobile ? "body2" : "body1"} mb={4} className="font-kulim font-thin">
                * Jangan hapus text ini <b>nama_tamu</b> agar otomatis tercantumkan link kehalaman undangan.</Typography>

                <div>
                    <TextField
                        id="tbu_pesan"
                        label="Pesan"
                        multiline
                        rows={6}
                        defaultValue=""
                        color='primary'
                        className='input'
                        value={lsu_pesan} 
                        onChange={handleChangePesan}
                        focused
                        style={{marginBottom: 20}}
                        // sx={{ '.css-1sumxir-MuiFormLabel-root-MuiInputLabel-root.Mui-focused': { color: 'rgb(0,0,0,.67)', }, 
                        // '.css-dpjnhs-MuiInputBase-root-MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {border: '1px solid rgb(0,0,0,.67)'}}} 
                    />
                </div>

<Collapse in={openAlert}>
    <Alert 
        icon={alertInit == 'OK' ? <CheckIcon fontSize="inherit" /> : <ErrorOutlineIcon fontSize="inherit" /> } 
        severity={alertInit == 'OK' ? "success" : "error"}
        action={
        <IconButton
            aria-label="close"
            color="inherit"
            size="small"
            onClick={() => {
                setOpenAlert(false);
            }}
        >
            <CloseIcon fontSize="inherit" />
        </IconButton>
    }
    sx={{ mb: 2, fontSize: mobile ? '13px' : '14px' }}
    >
    {alertMsg}
    </Alert>
</Collapse>
            <Typography variant={mobile ? "h6" : "h5"} className="font-kulim" style={{letterSpacing: 1}} >Silahkan Masukan Nama Tamu</Typography>
            <Typography variant={mobile ? "body2" : "body1"} mt={1} mb={4} className="font-kulim font-thin">
            * Gunakan baris baru <b>(↵)</b> untuk memisahkan nama yang akan Anda undang.
            </Typography>
            {/* <AnimationOnScroll animateIn="animate__fadeIn"> */}
                <div>
                    <TextField 
                    id="lsu_nama" 
                    label="Nama" 
                    placeholder="Tulis nama tamu undangan disini..."
                    variant="outlined" 
                    color='primary' 
                    className='input' 
                    multiline
                    rows={6}
                    value={lsu_nama} 
                    onChange={handleChangeNama} 
                    // sx={{ '.css-1sumxir-MuiFormLabel-root-MuiInputLabel-root.Mui-focused': { color: 'rgb(0,0,0,.67)', }, 
                    // '.css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {border: '1px solid rgb(0,0,0,.67)'}}} 
                    focused/>
                </div>
            {/* </AnimationOnScroll> */}
        <center>
            <Button disableElevation startIcon={<GroupAddOutlinedIcon />} size={ mobile ? "small" : "large" } className="btnPrimary" style={{marginTop: 20, marginBottom: 40, }} 
                onClick={saveTamu}
            >Add Guests</Button>
        </center>

<Snackbar
    anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
    }}
    open={openSnackbar}
    autoHideDuration={2000}
    onClose={handleCloseSnackbar}
    message="Link copied to clipboard"
    action={
        <React.Fragment>
            <IconButton size="small" aria-label="close" color="inherit" onClick={handleCloseSnackbar}>
                <CloseIcon fontSize="small" />
            </IconButton>
        </React.Fragment>
    }
/>
                        <TableContainer component={Paper} className='tblTamu' sx={{ maxHeight: 400 }}>
                            <Table stickyHeader aria-label="sticky table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="center">No.</TableCell>
                                        <TableCell align="center">Nama Tamu</TableCell>
                                        <TableCell align="center">Share</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                {dataTamu.map((item, index) => (
                                    
                                    <TableRow
                                        key={item.lsu_id}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <TableCell align="center" component="th" scope="row">
                                            {item.lsu_id}
                                        </TableCell>
                                        <TableCell align="center">
                                            {item.lsu_nama}
                                        </TableCell>
                                        <TableCell align="center">
                                            <IconButton aria-label="copy" size={'small'} className='btnSendWA' onClick={() => {copyLink(item.lsu_nama); }}>
                                                <Typography variant={mobile ? "caption" : "body2"} className="font-kulim" style={{color: '#fff', letterSpacing: 1, marginRight: 5}} >Send</Typography> 
                                                <WhatsAppIcon fontSize="inherit" sx={{color: '#fff'}} /> 
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                ))}
                                </TableBody>
                            </Table>
                        </TableContainer>

    </div>
  );

}

export default GenerateInvitation;




