import * as React from 'react';
import { useTheme, useMediaQuery } from '@material-ui/core';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import { AnimationOnScroll } from 'react-animation-on-scroll';
import ReactAudioPlayer from 'react-audio-player';
import GlobalVar from '../../GlobalVar';
  
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import PauseCircleIcon from '@mui/icons-material/PauseCircle';

const SectionFooterUlos = (props) =>  {
    // const [play, setPlay] = React.useState(false);
    const [custname, setCustname] = React.useState('');

    const [year, setYear] = React.useState(null);

    const theme = useTheme();
    const mobile = useMediaQuery(theme.breakpoints.down('xs'));
    
    const WAMsg = () => {
        const msg = 'Hi, saya tertarik ingin membuat website nikah dengan momentkami.com, bisakah saya berdiskusi lebih lanjut ?';
        window.open('https://api.whatsapp.com/send?phone=+6281912428620&text='+msg+'&app_absent=0', '_blank', 'noopener,noreferrer'); 
    }

    // const playOrPause = () => {
    //     let audio = document.querySelector(".audio");
    //     play ? setPlay(false) : setPlay(true);
    //     play ? audio.play() : audio.pause();
    // }

    const pathParts = window.location.pathname.split('/');  
    React.useEffect(() => {
        
        const path = window.location.search;
        const urlParams = new URLSearchParams(path);
        setCustname(pathParts[1]);
        const d = new Date();
        setYear(d.getFullYear());
        // if(!play) {
        //     setTimeout(() => {
        //         document.getElementById("audio").play();
        //     }, 300);
        // }
    }, []);


  return ( 
    <div style={{ marginTop: -16, paddingBottom: 100, background: 'rgba(0,0,0,.8)', color: '#e1e1e1'}}>
        {/* <ReactAudioPlayer
        src={GlobalVar.audio+'_'+props.audioMempelai+'.mp3'}
        className='audioPlayer'
        autoPlay
        muted={play}
        loop={true}
        /> */}
            {/* <audio id='audio' className='audio' src={GlobalVar.audio+'_'+props.audioMempelai+'.mp3'} loop autoPlay>
                <source src={GlobalVar.audio+'_'+props.audioMempelai+'.mp3'} type="audio/mpeg" ></source>
                <source src={GlobalVar.audio+'_'+props.audioMempelai+'.mp3'} type="audio/ogg" ></source>
            </audio>
        <IconButton
            aria-label="audio"
            color="inherit"
            size="medium"
            onClick={playOrPause}  
            id="audioBtn" 
                                 
        >
            { play ? <PlayCircleIcon fontSize="inherit" className='audioPlayer audioUlos' sx={{ fontSize: 40 }} /> : <PauseCircleIcon fontSize="inherit" 
                className='audioPlayer audioUlos' sx={{ fontSize: 40 }} /> }            
        </IconButton> */}

        <center>
            <AnimationOnScroll animateIn="animate__fadeInDown">
                <Typography variant="h6" mt={4} mx={2} className="font-kulim font-thin" style={{letterSpacing: 4}} >Made With 
                <FavoriteBorderIcon sx={{ fontSize: 20 }} style={{position: 'relative',left: 5,top: 3, color: 'rgba(220, 38, 38, 1)'}}/> By</Typography>
            </AnimationOnScroll>

            <Typography variant={mobile ? "h3" : "h2"} my={2} mx={2} className="font-curly" style={{color: 'rgba(220, 38, 38, 1)', cursor: 'pointer'}}
            onClick={()=>window.open('https://momentkami.com', '_blank')}><b>Momentkami</b></Typography>

            <Typography variant={mobile ? "caption" : "body2"} mx={2} className="font-kulim font-thin" >
                Copyright © {year}. All rights reserved
            </Typography>
        </center>
        
    </div>
  );
}

export default SectionFooterUlos;