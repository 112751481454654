import * as React from 'react';
import { useTheme, useMediaQuery, Divider } from '@material-ui/core';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import { AnimationOnScroll } from 'react-animation-on-scroll';
import GlobalVar from '../../GlobalVar';
  
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';

const Footer = (props) =>  {
    const [custname, setCustname] = React.useState('');
    const theme = useTheme();
    const mobile = useMediaQuery(theme.breakpoints.down('xs'));

    const [year, setYear] = React.useState(null);

    React.useEffect(() => {
        
        const path = window.location.search;
        const urlParams = new URLSearchParams(path);
        setCustname(urlParams.get('n'));

        const d = new Date();
        setYear(d.getFullYear());

    }, []);


  return ( 
    <div className='footer' style={{ paddingBottom: 40}}>
        <center>
            <AnimationOnScroll animateIn="animate__fadeInDown">
                <Typography variant={mobile ? "h6" : "h5"} mt={4} mx={2} className="font-kulim font-thin" style={{letterSpacing: 4}} >Made With 
                <FavoriteBorderIcon sx={{ fontSize: mobile ? 20 : 30 }} style={{position: 'relative',left: 5,top: 3, color: '#210d41'}}/> By</Typography>
            </AnimationOnScroll>

                <Typography variant={mobile ? "h3" : "h2"} my={2} mx={2} className="font-curly" style={{color: '#210d41'}}><b>Momentkami</b></Typography>

                <Typography variant={mobile ? "caption" : "body2"} mx={2} className="font-kulim font-thin" >
                    Copyright © {year}. All rights reserved
                </Typography>
        </center>
    </div>
  );
}

export default Footer;