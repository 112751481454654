export const bankData = [
    {
        "name": "BANK BRI",
        "code": "002",
        "logo": require("./images/Bank/BRI.png")
    },
    {
        "name": "BANK MANDIRI",
        "code": "008",
        "logo": require("./images/Bank/MANDIRI.png")
    },
    {
        "name": "BANK BNI",
        "code": "009",
        "logo": require("./images/Bank/BNI.png")
    },
    {
        "name": "PERMATA BANK",
        "code": "013",
        "logo": require("./images/Bank/PERMATA.png")
    },
    {
        "name": "BANK BCA",
        "code": "014",
        "logo": require("./images/Bank/BCA.png")
    },
    {
        "name": "BANK SYARIAH INDONESIA",
        "code": "451",
        "logo": require("./images/Bank/BSI.png")
    },
    {
        "name": "BANK JAGO",
        "code": "542",
        "logo": require("./images/Bank/JAGO.png")
    },
    {
        "name": "BANK CIMB",
        "code": "022",
        "logo": require("./images/Bank/CIMB.png")
    },
    {
        "name": "BANK DKI",
        "code": "111",
        "logo": require("./images/Bank/DKI.png")
    },
    {
        "name": "BANK JABAR",
        "code": "110",
        "logo": require("./images/Bank/JABAR.png")
    },
    {
        "name": "BANK JATENG",
        "code": "113",
        "logo": require("./images/Bank/JATENG.png")
    },
    {
        "name": "BANK SUMUT",
        "code": "117",
        "logo": require("./images/Bank/SUMUT.png")
    },
    {
        "name": "DANA",
        "code": "777",
        "logo": require("./images/Bank/DANA.png")
    },
    {
        "name": "OVO",
        "code": "888",
        "logo": require("./images/Bank/OVO.png")
    },
    {
        "name": "GOPAY",
        "code": "999",
        "logo": require("./images/Bank/GOPAY.png")
    }
]