import * as React from 'react';
import { useTheme, useMediaQuery } from '@material-ui/core';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Container from '@mui/material/Container';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import { CardActionArea } from '@mui/material';
import Modal from '@mui/material/Modal';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Fade from '@mui/material/Fade';
import { AnimationOnScroll } from 'react-animation-on-scroll';
import Snackbar from '@material-ui/core/Snackbar';

import LocationOnIcon from '@mui/icons-material/LocationOn';
import IconButton from '@mui/material/IconButton';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import CardGiftcardIcon from '@mui/icons-material/CardGiftcard';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import CelebrationIcon from '@mui/icons-material/Celebration';
import CloseIcon from '@mui/icons-material/Close';
import PinDropIcon from '@mui/icons-material/PinDrop';
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';

import { useCountdown } from '../useCountdown';

const Item = styled(Paper)(({ theme }) => ({
    padding: theme.spacing(1),
    color: theme.palette.text.secondary,
  }));
  
const SectionAcaraPastel = (props) =>  {
    const path = window.location.search;
    const urlParams = new URLSearchParams(path);
    const pathParts = window.location.pathname.split('/'); 
    
    const [openSnackbar, setOpenSnackbar] = React.useState(false);

    const handleClickSnackbar = () => {
        setOpenSnackbar(true);
    };

    const handleCloseSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
        return;
        }

        setOpenSnackbar(false);
    };
    
    const [day, hour, minute, second] = useCountdown(pathParts[1] == 'nagasuryana' || pathParts[1] == 'tyasarif' ? props.tglAkadS : props.unduhOnly == 1 ? props.tglUnduhS : props.tglResepsiS);
    
    const [openModalGift, setopenModalGift] = React.useState(false);
    const handleopenModalGift = () => setopenModalGift(true);
    const handleCloseModalGift = () => setopenModalGift(false);

    const [openModalBank, setopenModalBank] = React.useState(false);
    const handleopenModalBank = () => setopenModalBank(true);
    const handleCloseModalBank = () => setopenModalBank(false);      

    const theme = useTheme();
    const mobile = useMediaQuery(theme.breakpoints.down('xs'));

  function addZero(i) {
    if (i < 10) {i = "0" + i}
    return i;
  }

  const days = ['Minggu', 'Senin', 'Selasa', 'Rabu', 'Kamis', 'Jumat', 'Sabtu'];
  const monthNames = ["Januari", "Februari", "Maret","April", "May", "Juni",
    "Juli", "Agustus", "September", "Oktober", "November", "Desember"
  ];
  let newTglAkadS = new Date(props.unduhOnly == 1 ?  props.tglUnduhS : props.tglAkadS == null ? props.tglResepsiS : props.tglAkadS);
  let newNDAkadS = days[newTglAkadS.getDay()];
  let newDAkadS = newTglAkadS.getDate();
  let newMAkadS = monthNames[newTglAkadS.getMonth()]; //Month from 0 to 11
  let newYAkadS = newTglAkadS.getFullYear();
  let newHAkadS = addZero(newTglAkadS.getHours());
  let newMTAkadS = addZero(newTglAkadS.getMinutes());
      
    let newTglAkadE = new Date(props.tglAkadE);
    let newNDAkadE = days[newTglAkadE.getDay()];
    let newDAkadE = newTglAkadE.getDate();
    let newMAkadE = monthNames[newTglAkadE.getMonth()]; //Month from 0 to 11
    let newYAkadE = newTglAkadE.getFullYear();
    let newHAkadE = addZero(newTglAkadE.getHours());
    let newMTAkadE = addZero(newTglAkadE.getMinutes());
  
        let newTglResepsiS = new Date(props.tglResepsiS);
        let newNDResepsiS = days[newTglResepsiS.getDay()];
        let newDResepsiS = newTglResepsiS.getDate();
        let newMResepsiS = monthNames[newTglResepsiS.getMonth()]; //Month from 0 to 11
        let newYResepsiS = newTglResepsiS.getFullYear();
        let newHResepsiS = addZero(newTglResepsiS.getHours());
        let newMTResepsiS = addZero(newTglResepsiS.getMinutes());
  
            let newTglResepsiE = new Date(props.tglResepsiE);
            let newNDResepsiE = days[newTglResepsiE.getDay()];
            let newDResepsiE = newTglResepsiE.getDate();
            let newMResepsiE = monthNames[newTglResepsiE.getMonth()]; //Month from 0 to 11
            let newYResepsiE = newTglResepsiE.getFullYear();
            let newHResepsiE = addZero(newTglResepsiE.getHours());
            let newMTResepsiE = addZero(newTglResepsiE.getMinutes());

              let newTglS = new Date(props.tglUnduhS);
              let newNDS = days[newTglS.getDay()];
              let newDS = newTglS.getDate();
              let newMS = monthNames[newTglS.getMonth()]; //Month from 0 to 11
              let newYS = newTglS.getFullYear();
              let newHS = addZero(newTglS.getHours());
              let newMTS = addZero(newTglS.getMinutes());
              
                let newTglE = new Date(props.tglUnduhE);
                let newNDE = days[newTglE.getDay()];
                let newDE = newTglE.getDate();
                let newME = monthNames[newTglE.getMonth()]; //Month from 0 to 11
                let newYE = newTglE.getFullYear();
                let newHE = addZero(newTglE.getHours());
                let newMTE = addZero(newTglE.getMinutes());


    let bgAcara = {
      backgroundImage: `url("data:image/*;base64,${props.fotoCover2}")`,
    };

    const openInNewTab = url => {
      window.open(url, '_blank', 'noopener,noreferrer');
    };

  return ( 
    <div className={props.themePage == 'Blue_Pastel' ? 'Section sec-pastel BluePastel' 
        : 
            props.themePage == 'Yellow_Pastel' ? 'Section sec-pastel YellowPastel' 
        : 
            props.themePage == 'Green_Pastel' ? 'Section sec-pastel GreenPastel' 
        :  
            props.themePage == 'GreenEl_Pastel' ? 'Section sec-pastel GreenElPastel' 
        :
            props.themePage == 'Pink_Pastel' ? 'Section sec-pastel PinkPastel' 
        :  'Section sec-pastel '} style={{marginBottom: props.foto1 != ''  ? 0 : -340}} >            
            <Container>
                <center style={{paddingTop: 60}}>
                    <AnimationOnScroll animateIn="animate__fadeInDown">
                        <Typography variant="h4" className="font-jost">Moment Bahagia.</Typography>
                        <Typography variant="body1" mb={4} mx={2} className='font-jost font-thin'>Suatu kehormatan bagi kami apabila Anda berkenan hadir</Typography>
                    </AnimationOnScroll>

                    <AnimationOnScroll animateIn="animate__flipInX">
                    <Stack
                            direction="row"
                            justifyContent="center"
                            alignItems="center" 
                            spacing={1}
                            mt={2}
                        >
                            <Item className='day'>{day}</Item>
                            <Item className='hour'>{hour}</Item>
                            <Item className='minute'>{minute}</Item>
                            <Item className='second'>{second}</Item>
                    </Stack>
                    </AnimationOnScroll>
                </center>

                    <img src={require('../../images/bg_pastel/ornament2.png')} className="img-ornament2-top" width={120} />
                    <img src={require('../../images/bg_pastel/ornament2.png')} className="img-ornament2-bottom" width={120} />

                <Grid container 
                direction="row"
                justifyContent="center"
                alignItems="center" 
                spacing={2} mt={4} px={2} mb={4}> 

                    <Grid item xs={12} className='bgTransparent'>
                    <Item elevation={1} className='bgTransparentWBorder'>
                        <Card elevation={1} className='cardAcara' style={{background: props.themePage == 'GreenEl_Pastel' ? 'rgba(15, 118, 110, .25)': '#fff'}}>
                            <CardActionArea>
                                <CardContent>
                                    <center>
                                        
                                    {
                                        props.tglAkadS != null ?
                                        <div>
                                            <AnimationOnScroll animateIn="animate__zoomIn">
                                                { pathParts[1] != 'nagasuryana' && <SupervisedUserCircleIcon sx={{fontSize: 40}} /> }
                                                
                                                <Typography variant="h4" className="font-jost" mt={2}>
                                                    {
                                                        pathParts[1] == 'nagasuryana' ? 'Mangalo - alo Mora'
                                                    :
                                                        `Akad Nikah ${pathParts[1] == 'tyasarif'? ' & Resepsi 1': ''}`  
                                                    }
                                                </Typography>
                                                <Typography variant="h6" className='font-jost' mb={.5} mt={2} >{newNDAkadS}, {newDAkadS} {newMAkadS} {newYAkadS}</Typography>
                                            </AnimationOnScroll>
                                            <AnimationOnScroll animateIn="animate__zoomIn">
                                                <Typography variant="body1" className='font-jost' >{newHAkadS}:{newMTAkadS} - {props.tglAkadE == null ? "Selesai" : newHAkadE+":"+newMTAkadE} (WIB)</Typography>
                                            </AnimationOnScroll>
                                            <AnimationOnScroll animateIn="animate__zoomIn">
                                                <Typography variant="body1" className="font-jost" my={2} >{props.lokAkad}</Typography>
                                            </AnimationOnScroll>
                                        </div>
                                        : ''
                                    }
                                    </center>
                                </CardContent>
                            </CardActionArea>
                            </Card>
                        </Item>
                    </Grid>

                    {
                        props.lokAkad != props.lokResepsi &&
                            <Grid item xs={12} className='bgTransparent'>
                            <Item elevation={0} className='bgTransparent'>
                                <Card elevation={0} className='bgTransparentWBorder' style={{background: props.themePage == 'GreenEl_Pastel' ? 'rgba(15, 118, 110, .25)': '#fff'}}>
                                    <CardActionArea>
                                        <CardContent>
                                            <center>
                                                <AnimationOnScroll animateIn="animate__zoomIn">
                                                    <iframe src={props.gmapsIframeAkad} allowfullscreen="true" className='gmaps'></iframe>
                                                </AnimationOnScroll>
                                            </center>
                                        </CardContent>
                                    </CardActionArea>
                                </Card>
                            </Item>
                            </Grid>
                    }

                    <Grid item xs={12} className='bgTransparent'>
                    <Item elevation={1} className='bgTransparentWBorder'>
                        <Card elevation={1} className='cardAcara' style={{background: props.themePage == 'GreenEl_Pastel' ? 'rgba(15, 118, 110, .25)': '#fff'}}>
                            <CardActionArea>
                                <CardContent>
                                    <center>
                                    {
                                        props.tglResepsiS != null ?
                                        <div>
                                            <AnimationOnScroll animateIn="animate__zoomIn">
                                                { pathParts[1] != 'nagasuryana' && <CelebrationIcon sx={{fontSize: 40}} /> }
                                                <Typography variant="h4" className="font-jost" mt={2}>
                                                    {
                                                        pathParts[1] == 'nagasuryana' ? 'Margondang'
                                                    :
                                                        `Resepsi ${pathParts[1] == 'tyasarif'? '2': ''}`  
                                                    }
                                                </Typography>
                                                <Typography variant="h6" className='font-jost' mb={.5} mt={2}>{newNDResepsiS}, {newDResepsiS} {newMResepsiS} {newYResepsiS}</Typography>
                                            </AnimationOnScroll>
                                            <AnimationOnScroll animateIn="animate__zoomIn">
                                                <Typography variant="body1" className='font-jost' >{newHResepsiS}:{newMTResepsiS} - {props.tglResepsiE == null ? "Selesai" : newHResepsiE+":"+newMTResepsiE} (WIB)</Typography>
                                            </AnimationOnScroll>
                                            <AnimationOnScroll animateIn="animate__zoomIn">
                                                <Typography variant="body1" className="font-jost" my={2} >{props.lokResepsi}</Typography>
                                            </AnimationOnScroll>
                                        </div>
                                        : ''
                                    }
                                    </center>
                                </CardContent>
                            </CardActionArea>
                            </Card>
                        </Item>
                    </Grid>

                </Grid>     
                
                    
                <Grid item xs={12} className='bgTransparent'>
                <Item elevation={0} className='bgTransparent'>
                    <Card elevation={0} className='bgTransparentWBorder' style={{background: props.themePage == 'GreenEl_Pastel' ? 'rgba(15, 118, 110, .25)': '#fff'}}>
                        <CardActionArea>
                            <CardContent>
                                <center>
                                    <AnimationOnScroll animateIn="animate__zoomIn">
                                        <iframe src={props.gmapsIframeResepsi} allowfullscreen="true" className='gmaps'></iframe>
                                    </AnimationOnScroll>
                                </center>
                            </CardContent>
                        </CardActionArea>
                    </Card>
                </Item>
                </Grid>

            </Container> 


            { pathParts[1] == 'amirahdidi' &&
                <>
                    <center style={{marginTop:40, color: '#333', margin: '0px 20px', padding: '10px 20px', border: '2px solid rgba(15, 118, 110, 0.55)'}}>
                        <AnimationOnScroll animateIn="animate__zoomIn">
                            <Typography variant="h6" mt={2} mb={2} className="font-jost">
                                <u>Alamat Rumah Mempelai :</u>
                            </Typography>
                            <Divider width={140}/>
                            <Typography variant="body2" mb={1} className="font-jost" style={{maxWidth: 400}}>Jl. Terusan No. 9/13, Dusun 6 Pasar 15, Desa Bandar Setia, Kec. Percut Sei Tuan, Kab. Deli Serdang</Typography>
                            <IconButton aria-label="copy" size={'small'} sx={{color: '#222', zIndex: 999}}  mb={4} onClick={() => {navigator.clipboard.writeText('Jl. Terusan No. 9/13, Dusun 6 Pasar 15, Desa Bandar Setia, Kec. Percut Sei Tuan, Kab. Deli Serdang'); handleClickSnackbar(); }}>
                                <FileCopyIcon fontSize="inherit" />
                            </IconButton>       
                        </AnimationOnScroll>  
                    </center>
                    <div className='bgAyatGreen' style={{ marginTop: 10, marginBottom: -42}}>
                        <Box>
                            <center>
                            <AnimationOnScroll animateIn="animate__fadeInDown">
                                <Typography variant="body1" pt={2} mx={2} className='font-kulim font-thin' style={{width:'85%'}}>
                                " Dan di antara tanda-tanda (kebesaran)-Nya ialah Dia menciptakan pasangan-pasangan untukmu dari jenismu sendiri, agar kamu cenderung dan merasa tenteram kepadanya, dan Dia menjadikan di antaramu rasa kasih dan sayang. Sungguh, pada demikian itu benar-benar terdapat tanda-tanda (kebesaran Allah) bagi kaum yang berpikir. "
                                </Typography>            
                            </AnimationOnScroll>
                            <AnimationOnScroll animateIn="animate__fadeInUp">
                                <Typography variant="body1" pb={2} mt={2} mx={2} className="font-kulim ">
                                ( QS. Ar-Rum ayat 21 )</Typography>
                            </AnimationOnScroll>
                            </center> 
                        </Box>
                    </div>
                </>
            }
                
            <div>
            {
                props.alamatHadiah != null || props.bank1Rek != null  ?
                <>
                <Grid container 
                    direction="row"
                    justifyContent="center"
                    alignItems="center" 
                    spacing={2} mt={4} px={2}>
                    {props.linkStreaming && 

                        <Grid item xs={12} className='bgTransparent'>
                            <Item elevation={1} className='bgTransparentWBorder'>
                                <center>  
                                    <AnimationOnScroll animateIn="animate__zoomIn">
                                        <Typography variant="h4" className="font-jost">
                                            Live Moment.
                                        </Typography>     
                                        <Typography variant="body2" mb={2} mx={2} className='font-jost font-thin'>
                                            Temui kami secara virtual untuk menyaksikan moment bahagia kami. Silahkan untuk join live moment.
                                        </Typography>

                                        <Button disableElevation startIcon={<PlayArrowIcon />} size="medium" style={{ marginBottom: 10}} 
                                        onClick={() => openInNewTab(props.linkStreaming)}
                                        className="btnPrimary font-jost" > Live Moment</Button>
                                    </AnimationOnScroll>
                                    
                                </center>  
                            </Item>
                        </Grid>
                    }

                    {
                        pathParts[1] != 'amirahdidi' &&
                            <Grid item xs={12} className='bgTransparent' mb={15}>
                                <Item elevation={1} className='bgTransparentWBorder'>
                                    <center>  
                                        <AnimationOnScroll animateIn="animate__zoomIn">
                                            <Typography variant="h4" className="font-jost">
                                                Kirim Hadiah.
                                            </Typography>     
                                            <Typography variant="body2" mb={2} mx={2} className='font-jost font-thin'>Bagi keluarga dan sahabat yang ingin mengirimkan hadiah, Silahkan 
                                                mengirimkannya melalui tautan berikut :
                                            </Typography>
                                        </AnimationOnScroll>
        
                                        {props.alamatHadiah &&
                                            <AnimationOnScroll animateIn="animate__zoomIn">
                                                <Typography variant="h6" mt={2} mb={2} className="font-jost">
                                                    <u>Alamat Pengiriman :</u>
                                                </Typography>
                                                <Divider width={140}/>
                                                <Typography variant="body2" mb={1} className="font-jost">{props.alamatHadiah}</Typography>
                                                <IconButton aria-label="copy" size={'small'} sx={{color: '#222'}}  mb={4} onClick={() => {navigator.clipboard.writeText(props.alamatHadiah); handleClickSnackbar(); }}>
                                                    <FileCopyIcon fontSize="inherit" />
                                                </IconButton>       
                                            </AnimationOnScroll>  
                                        }      
                                        
                                        {props.bank1Rek != null  &&
                                            <AnimationOnScroll animateIn="animate__zoomIn">
                                                <Typography variant="h6" mt={4} mb={2} className="font-jost">
                                                    <u>No Rekening :</u>
                                                </Typography>
                                                <Divider width={140}/>
                                                <Grid container 
                                                    direction="row"
                                                    justifyContent="center"
                                                    alignItems="center" 
                                                    spacing={1} px={2} mb={4}
                                                >
                                                    <Grid item xs={11}>
                                                        <Item elevation={0} className='bgTransparent'>
                                                            <Card elevation={1} className='cardBank'>
                                                                <CardActionArea className='cardBank'>
                                                                <CardContent className='cardBankOverlay'>
                                                                    <img src={`${props.bank1Name}`} width={85} className="bank-img"/>  
                                                                    <Typography gutterBottom variant="body2" className='font-jost bank-rek' style={{letterSpacing: 2}} component="div" mt={1}>
                                                                        <IconButton aria-label="copy" size="small" style={{marginRight: 5}} onClick={() =>  {navigator.clipboard.writeText(props.bank1Rek); handleClickSnackbar(); }}>
                                                                        <FileCopyIcon fontSize="inherit" sx={{color: '#222'}} />
                                                                        </IconButton>
                                                                        {props.bank1Rek} 
                                                                    </Typography> 
                                                                    <Typography variant="caption" className="font-jost bank-an" >{props.bank1An}</Typography>
                                                                </CardContent>
                                                                </CardActionArea>
                                                            </Card>
                                                        </Item>
                                                    </Grid>
                                                { props.bank2Rek &&
                                                    <Grid item xs={11}>
                                                        <Item elevation={0} className='bgTransparent'>
                                                            <Card elevation={1} className='cardBank'>
                                                                <CardActionArea className='cardBank'>   
                                                                <CardContent className='cardBankOverlay'>
                                                                    <img src={`${props.bank2Name}`} width={85} className="bank-img"/>  
                                                                    <Typography gutterBottom variant="body2" className='font-jost bank-rek' style={{letterSpacing: 2}} component="div" mt={2}>
                                                                        <IconButton aria-label="copy" size="small" style={{marginRight: 5}} onClick={() => {navigator.clipboard.writeText(props.bank2Rek); handleClickSnackbar(); }}>
                                                                            <FileCopyIcon fontSize="inherit" sx={{color: '#222'}} />
                                                                        </IconButton>
                                                                        {props.bank2Rek} 
                                                                    </Typography> 
                                                                    <Typography variant="caption" className="font-jost bank-an" >{props.bank2An}</Typography>
                                                                </CardContent>
                                                                </CardActionArea>
                                                            </Card>
                                                        </Item>
                                                    </Grid>
                                                }
                                                    
                                                </Grid>
                                            </AnimationOnScroll>
                                        
                                        }
                                        
                                        <svg style={{position: 'absolute', bottom: -10, width: '100%', left: 0}} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
                                            <path fill="#fff" fill-opacity="1" d="M0,192L26.7,208C53.3,224,107,256,160,272C213.3,288,267,288,320,240C373.3,192,427,96,480,80C533.3,64,587,128,640,144C693.3,160,747,128,800,112C853.3,96,907,96,960,128C1013.3,160,1067,224,1120,218.7C1173.3,213,1227,139,1280,101.3C1333.3,64,1387,64,1413,64L1440,64L1440,320L1413.3,320C1386.7,320,1333,320,1280,320C1226.7,320,1173,320,1120,320C1066.7,320,1013,320,960,320C906.7,320,853,320,800,320C746.7,320,693,320,640,320C586.7,320,533,320,480,320C426.7,320,373,320,320,320C266.7,320,213,320,160,320C106.7,320,53,320,27,320L0,320Z"></path>
                                        </svg>
                                        
                                    </center>  
                                </Item>
                            </Grid>
                    }
                </Grid>      
                </>
            :
                ''
            } 
                </div>

<Snackbar
    anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
    }}
    open={openSnackbar}
    autoHideDuration={2000}
    onClose={handleCloseSnackbar}
    message="Copied to clipboard"
    action={
        <React.Fragment>
            <IconButton size="small" aria-label="close" color="inherit" onClick={handleCloseSnackbar}>
                <CloseIcon fontSize="small" />
            </IconButton>
        </React.Fragment>
    }
/>

    </div>
  );
}

export default SectionAcaraPastel;