import * as React from 'react';
import axios from 'axios';
import { useTheme, useMediaQuery } from '@material-ui/core';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { bankData } from '../../Bank.js'
            

import GlobalVar from '../../GlobalVar';

const Hadiah = (props) =>  {

    const theme = useTheme();
    const mobile = useMediaQuery(theme.breakpoints.down('xs'));

    const path = window.location.search;
    const urlParams = new URLSearchParams(path);

    const [openLoading, setOpenLoading] = React.useState(false);
    const handleCloseLoading = () => {
      setOpenLoading(false);
    };
    const handleToggleLoading = () => {
      setOpenLoading(!openLoading);
    };

    const [cf_bank1_kode, setCf_bank1_kode] = React.useState('');
    const [cf_bank1_rekening, setCf_bank1_rekening] = React.useState('');
    const [cf_bank1_an, setCf_bank1_an] = React.useState('');
    const [cf_bank2_kode, setCf_bank2_kode] = React.useState('');
    const [cf_bank2_rekening, setCf_bank2_rekening] = React.useState('');
    const [cf_bank2_an, setCf_bank2_an] = React.useState('');
    const [cf_alamat_hadiah, setCf_alamat_hadiah] = React.useState('');

    const handleChangeKode1 = (event: SelectChangeEvent) => {
      setCf_bank1_kode(event.target.value);
      props.cf_bank1_kode(event.target.value);
    };

    const handleChangeKode2 = (event: SelectChangeEvent) => {
      setCf_bank2_kode(event.target.value);
      props.cf_bank2_kode(event.target.value);
    };

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if(event.target.id == 'cf_bank1_rekening') {
            setCf_bank1_rekening(event.target.value);
            props.cf_bank1_rekening(event.target.value);
        } else if(event.target.id == 'cf_bank2_rekening') {
            setCf_bank2_rekening(event.target.value);
            props.cf_bank2_rekening(event.target.value);
        } else if(event.target.id == 'cf_bank1_an') {
            setCf_bank1_an(event.target.value);
            props.cf_bank1_an(event.target.value);
        } else if(event.target.id == 'cf_bank2_an') {
            setCf_bank2_an(event.target.value);
            props.cf_bank2_an(event.target.value);
        } else if(event.target.id == 'cf_alamat_hadiah') {
            setCf_alamat_hadiah(event.target.value);
            props.cf_alamat_hadiah(event.target.value);
        }    
    };
    const pathParts = window.location.pathname.split('/');  

    React.useEffect(() => {
        handleToggleLoading(); 

        axios.get(GlobalVar.server+'GetData.php?n='+pathParts[1]).then(function(response){
            let res = JSON.stringify(response.data);
            let resP = JSON.parse(res);
            handleCloseLoading(); 

            setCf_bank1_rekening(resP[0].cf_bank1_rekening)
            setCf_bank1_an(resP[0].cf_bank1_an)
            setCf_bank1_kode(resP[0].cf_bank1_kode)
            setCf_bank2_rekening(resP[0].cf_bank2_rekening)
            setCf_bank2_an(resP[0].cf_bank2_an)
            setCf_bank2_kode(resP[0].cf_bank2_kode)
            setCf_alamat_hadiah(resP[0].cf_alamat_hadiah)

            props.cf_bank1_rekening(resP[0].cf_bank1_rekening)
            props.cf_bank1_an(resP[0].cf_bank1_an)
            props.cf_bank1_kode(resP[0].cf_bank1_kode)
            props.cf_bank2_rekening(resP[0].cf_bank2_rekening)
            props.cf_bank2_an(resP[0].cf_bank2_an)
            props.cf_bank2_kode(resP[0].cf_bank2_kode)
            props.cf_alamat_hadiah(resP[0].cf_alamat_hadiah)
            
                         
        });

    }, []);

  return ( 
    <section>
        <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={openLoading}
            // onClick={handleCloseLoading}
            >
            <CircularProgress color="inherit" />
        </Backdrop>

      <center>

        <Grid container spacing={2} sx={{mt: 2}}>
          <Grid item xs={12} style={{textAlign: 'left'}}>
              <Typography variant={mobile ? "h5" : "h4"} className="font-jost">
              Alamat Pengiriman Paket Hadiah
              </Typography>
          </Grid>
          <Grid item xs={12}>
              <TextField
                  id="cf_alamat_hadiah"
                  label="Alamat Pengiriman"
                  multiline
                  rows={4}
                  color='primary'
                  className='input'
                  value={cf_alamat_hadiah} 
                  onChange={handleChange}
              />
          </Grid>
{/* =============== BANK 1 =============== */}
            <Grid item xs={12} style={{textAlign: 'left'}}>
                <Typography variant={mobile ? "h5" : "h4"} className="font-jost">
                Bank 1
                </Typography>
            </Grid>
            <Grid item xs={6}>
                <TextField
                    id="cf_bank1_rekening"
                    label="No. Rekening 1"
                    color='primary'
                    className='input'
                    value={cf_bank1_rekening} 
                    onChange={handleChange}
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <TextField
                    id="cf_bank1_an"
                    label="A/n Rekening 1"
                    color='primary'
                    className='input'
                    value={cf_bank1_an} 
                    onChange={handleChange}
                />
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl fullWidth>
                <InputLabel id="cf_bank1_kode">Pilih Bank 1</InputLabel>
                <Select
                  id="cf_bank1_kode"
                  value={cf_bank1_kode}
                  label="Pilih Bank 1"
                  onChange={handleChangeKode1}
                >
                  {bankData.map((item) => (
                    <MenuItem value={item.code}>{item.name}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
        </Grid>

        <Grid container spacing={2} sx={{mt: 4}}>
{/* =============== BANK 2 =============== */}
            <Grid item xs={12} style={{textAlign: 'left'}}>
                <Typography variant={mobile ? "h5" : "h4"} className="font-jost">
                Bank 2
                </Typography>
            </Grid>
            <Grid item xs={6}>
                <TextField
                    id="cf_bank2_rekening"
                    label="No. Rekening 2"
                    color='primary'
                    className='input'
                    value={cf_bank2_rekening} 
                    onChange={handleChange}
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <TextField
                    id="cf_bank2_an"
                    label="A/n Rekening 2"
                    color='primary'
                    className='input'
                    value={cf_bank2_an} 
                    onChange={handleChange}
                />
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl fullWidth>
                <InputLabel id="cf_bank2_kode">Pilih Bank 2</InputLabel>
                <Select
                  id="cf_bank2_kode"
                  value={cf_bank2_kode}
                  label="Pilih Bank 2"
                  onChange={handleChangeKode2}
                >
                  {bankData.map((item) => (
                    <MenuItem value={item.code}>{item.name}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
        </Grid>
        
      </center>
    </section>

        
  );
}

export default Hadiah;