import * as React from 'react';
import { useTheme, useMediaQuery } from '@material-ui/core';
import { Container, Typography, ImageList, ImageListItem, ImageListItemBar, Card, CardActions, CardContent, CardMedia,
  Chip, Tab, Box, Stack, Divider, Button } from '@mui/material';
// import Button from '@mui/material-next/Button';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { AnimationOnScroll } from 'react-animation-on-scroll';

import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import SettingsAccessibilityIcon from '@mui/icons-material/SettingsAccessibility';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import VisibilityIcon from '@mui/icons-material/Visibility';


import Theme1 from './Theme1';
import Theme2 from './Theme2';

const Design = (props) =>  {
    const theme = useTheme();
    const mobile = useMediaQuery(theme.breakpoints.down('xs'));
    const tablet = useMediaQuery(theme.breakpoints.down('md'));
    const [valueTabs, setValueTabs] = React.useState('1');
    const handleChangeTabs = (event, newValue) => {
      setValueTabs(newValue);
    };

    React.useEffect(() => {
        
    }, []);
    
  return ( 
    <div className='section design'>  
      <Container>
        <center>
            <AnimationOnScroll animateIn="animate__zoomIn" initiallyVisible={true}>
              <Typography variant={mobile ? "h5" : "h4"} className="font-jost">Contoh Tema Kami.</Typography>
              <Typography variant={mobile ? "body1" : "h6"} width={mobile ? '100%' : '80%'} mb={mobile ? 6 : 12} mt={2} className="font-jost font-thin">Kami hadir sebagai sebuah solusi untuk membantu anda yang berbahagia untuk membuat undangan online dengan cepat dan mudah.</Typography>                
            </AnimationOnScroll>
        </center>

        <AnimationOnScroll animateIn="animate__fadeIn">
          <TabContext value={valueTabs}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <TabList onChange={handleChangeTabs} aria-label="lab API tabs example">
                <Tab label={<Chip icon={<SettingsAccessibilityIcon />} label="Adat" sx={{cursor: 'pointer'}} />} value="1" />
                <Tab label={<Chip icon={<AutoAwesomeIcon />} label="Premium" sx={{cursor: 'pointer'}} />} value="2" />
              </TabList>
            </Box>
            <TabPanel value="1">
                <center>
                  <Theme1 />
                </center>
            </TabPanel>
            
            <TabPanel value="2">
                <center>
                  <Theme2 />
                </center>
            </TabPanel>
          </TabContext>
        </AnimationOnScroll>
                
      </Container>
    </div>
  );
}

export default Design;