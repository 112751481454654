import * as React from 'react';
import axios from 'axios';
import { useTheme, useMediaQuery } from '@material-ui/core';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import GlobalVar from '../GlobalVar';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import RestartAltIcon from '@mui/icons-material/RestartAlt';

import { AnimationOnScroll } from 'react-animation-on-scroll';

import Mempelai from './admin/Mempelai';
import Acara from './admin/Acara';
import Hadiah from './admin/Hadiah';
import Galeri from './admin/Galeri';

const server = GlobalVar.server;

const steps = ['Mempelai', 'Acara', 'Hadiah'];

const MPanel = (props) =>  {
    const [openConfirm, setOpenConfirm] = React.useState(false);
  
    const handleClickOpenConfirm = () => {
      setOpenConfirm(true);
    };
  
    const handleCloseConfirm = () => {
      setOpenConfirm(false);
    };
    
    const [activeStep, setActiveStep] = React.useState(0);
    const theme = useTheme();
    const mobile = useMediaQuery(theme.breakpoints.down('xs'));

    const path = window.location.search;
    const urlParams = new URLSearchParams(path);

    const [nickPria, setNickPria] = React.useState('');
    const [fullPria, setFullPria] = React.useState('');
    const [ayahPria, setAyahPria] = React.useState('');
    const [ibuPria, setIbuPria] = React.useState('');
    // const [fotoPria, setFotoPria] = React.useState(null);
    const [nickWanita, setNickWanita] = React.useState('');
    const [fullWanita, setFullWanita] = React.useState('');
    const [ayahWanita, setAyahWanita] = React.useState('');
    const [ibuWanita, setIbuWanita] = React.useState(''); 
    // const [fotoWanita, setFotoWanita] = React.useState(null);

    const [cf_waktu_akad_start, setCf_waktu_akad_start] = React.useState('');
    const [cf_waktu_akad_end, setCf_waktu_akad_end] = React.useState('');
    const [cf_tempat_akad, setCf_tempat_akad] = React.useState('');
    const [cf_gmaps_akad, setCf_gmaps_akad] = React.useState('');
    const [cf_waktu_resepsi_start, setCf_waktu_resepsi_start] = React.useState('');
    const [cf_waktu_resepsi_end, setCf_waktu_resepsi_end] = React.useState('');
    const [cf_tempat_resepsi, setCf_tempat_resepsi] = React.useState('');
    const [cf_gmaps_resepsi, setCf_gmaps_resepsi] = React.useState('');
    
    const [cf_bank1_kode, setCf_bank1_kode] = React.useState('');
    const [cf_bank1_rekening, setCf_bank1_rekening] = React.useState('');
    const [cf_bank1_an, setCf_bank1_an] = React.useState('');
    const [cf_bank2_kode, setCf_bank2_kode] = React.useState('');
    const [cf_bank2_rekening, setCf_bank2_rekening] = React.useState('');
    const [cf_bank2_an, setCf_bank2_an] = React.useState('');  
    const [cf_alamat_hadiah, setCf_alamat_hadiah] = React.useState('');
    
    const [openLoading, setOpenLoading] = React.useState(false);
    const handleCloseLoading = () => {
      setOpenLoading(false);
    };
    const handleToggleLoading = () => {
      setOpenLoading(!openLoading);
    };
    const pathParts = window.location.pathname.split('/');  
  
    const handleNext = (step) => {
        handleToggleLoading(); 
        let username = pathParts[1];

        if(step == 0){
            const dataUpdate = {
                nickPria,
                fullPria,
                ayahPria,
                ibuPria,
                // fotoPria,
                nickWanita,
                fullWanita,
                ayahWanita,
                ibuWanita,
                // fotoWanita,
                username
            }
            
            axios.post(GlobalVar.server+'PostMempelai.php', dataUpdate).then(function(response){
                let res = JSON.stringify(response.data);
                let resP = JSON.parse(res);

                if(resP.statusCode==200){
                    // alert('Mempelai berhasil diupdate.');
                    setActiveStep((prevActiveStep) => prevActiveStep + 1);
                    handleCloseLoading();  
                }
                else if(resP.statusCode==500){
                    alert('Gagal update data ! Silahkan dicoba kembali.');
                    handleCloseLoading();  
                }
            });
        } else if(step == 1){
            const dataUpdate = {
                cf_waktu_akad_start,
                cf_waktu_akad_end,
                cf_tempat_akad,
                cf_gmaps_akad,
                cf_waktu_resepsi_start,
                cf_waktu_resepsi_end,
                cf_tempat_resepsi,
                cf_gmaps_resepsi,
                username
            }
            
            axios.post(GlobalVar.server+'PostAcara.php', dataUpdate).then(function(response){
                let res = JSON.stringify(response.data);
                let resP = JSON.parse(res);

                if(resP.statusCode==200){
                    // alert('Mempelai berhasil diupdate.');
                    setActiveStep((prevActiveStep) => prevActiveStep + 1);
                    handleCloseLoading();  
                }
                else if(resP.statusCode==500){
                    alert('Gagal update data ! Silahkan dicoba kembali.');
                    handleCloseLoading();  
                }
            });
        }
    };

    const handleSimpan = () => {
        handleToggleLoading(); 
        let username = pathParts[1];

        const dataUpdate = {
            cf_bank1_kode,
            cf_bank1_rekening,
            cf_bank1_an,
            cf_bank2_kode,
            cf_bank2_rekening,
            cf_bank2_an,
            cf_alamat_hadiah,
            username
        }
        
        axios.post(GlobalVar.server+'PostHadiah.php', dataUpdate).then(function(response){
            let res = JSON.stringify(response.data);
            let resP = JSON.parse(res);

            if(resP.statusCode==200){
                // alert('Mempelai berhasil diupdate.');
                setActiveStep((prevActiveStep) => prevActiveStep + 1);
                handleCloseLoading();  
                handleCloseConfirm();
            }
            else if(resP.statusCode==500){
                alert('Gagal update data ! Silahkan dicoba kembali.');
                handleCloseLoading(); 
                handleCloseConfirm(); 
            }
        });
    };
  
    const handleBack = () => {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleReset = () => {
      setActiveStep(0);
    };

    React.useEffect(() => {

    }, []);

  return ( 
    <div className='SectionAdmin'>
        <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={openLoading}
            // onClick={handleCloseLoading}
            >
            <CircularProgress color="inherit" />
        </Backdrop>

        <Container>
            { props.adminLock == 0 ?
                <div>
                <Stepper activeStep={activeStep}>
                    {steps.map((label, index) => {
                    const stepProps = {};
                    const labelProps = {};
                    return (
                        <Step key={label} {...stepProps}>
                        <StepLabel {...labelProps}>{label}</StepLabel>
                        </Step>
                    );
                    })}
                </Stepper>
                {activeStep === steps.length ? (
                    <React.Fragment>
                    <Typography sx={{ mt: 2, mb: 1 }}>
                        Terima kasih sudah mengisi semua data, website akan segera kami proses ^^
                    </Typography>
                    {/* <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                        <Box sx={{ flex: '1 1 auto' }} />
                        <Button onClick={handleReset} className='btnPrimary'><RestartAltIcon fontSize='small' sx={{mr: 1}} />Ubah</Button>
                    </Box> */}
                    </React.Fragment>
                ) : (
                    <React.Fragment>

                    {activeStep === 0 ?
                        <Mempelai nickPria={setNickPria} fullPria={setFullPria} ayahPria={setAyahPria} ibuPria={setIbuPria} //fotoPria={setFotoPria}
                            nickWanita={setNickWanita} fullWanita={setFullWanita} ayahWanita={setAyahWanita} ibuWanita={setIbuWanita} />//fotoWanita={setFotoWanita} />
                    : activeStep === 1 ?
                        <Acara cf_tempat_akad={setCf_tempat_akad} cf_gmaps_akad={setCf_gmaps_akad} cf_waktu_akad_start={setCf_waktu_akad_start} cf_waktu_akad_end={setCf_waktu_akad_end}
                        cf_tempat_resepsi={setCf_tempat_resepsi} cf_gmaps_resepsi={setCf_gmaps_resepsi} cf_waktu_resepsi_start={setCf_waktu_resepsi_start} cf_waktu_resepsi_end={setCf_waktu_resepsi_end} />
                    : activeStep === 2 ?
                        <Hadiah cf_bank1_kode={setCf_bank1_kode} cf_bank1_rekening={setCf_bank1_rekening} cf_bank1_an={setCf_bank1_an}
                        cf_bank2_kode={setCf_bank2_kode} cf_bank2_rekening={setCf_bank2_rekening} cf_bank2_an={setCf_bank2_an} cf_alamat_hadiah={setCf_alamat_hadiah} />
                    : 
                        <Galeri />
                        
                    }
                    <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                        <IconButton aria-label="kembali" className='btnPrimary2Icon' onClick={handleBack} disabled={activeStep === 0}>
                            <ArrowBackIosNewIcon />
                        </IconButton>
                        
                        <Box sx={{ flex: '1 1 auto' }} />
                        {activeStep === steps.length - 1 ?
                            <Button onClick={handleClickOpenConfirm} className="btnPrimary">
                                <SaveAltIcon fontSize='small' sx={{mr: .5}} />Submit
                            </Button>
                        :
                            <IconButton aria-label="lanjut" onClick={() => handleNext(activeStep)} className='btnPrimaryIcon'>
                                <ArrowForwardIosIcon />
                            </IconButton>
                        }
                        </Box>
                    </React.Fragment>
                )}
                </div>

            :   
                <center>
                    <Typography variant={mobile ? "h5" : "h5"} className="font-jost">
                    Data sedang kami proses, hubungi kami jika ingin merubah data ^^
                    </Typography>
                    <img src={require('../images/buildingweb.jpg')} width={360} />
                </center>
            }

<Dialog
    open={openConfirm}
    onClose={handleCloseConfirm}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
>
<DialogTitle id="alert-dialog-title">
    {"Anda yakin ingin submit ?"}
</DialogTitle>
<DialogContent>
    <DialogContentText id="alert-dialog-description">
        Setelah submit, data akan di proses oleh admin kami, tapi tenang saja anda dapat melakukan perubahan data dengan menghubungi admin kami.
    </DialogContentText>
</DialogContent>
<DialogActions>
    <Button onClick={handleCloseConfirm} className='btnPrimary4'>Batal</Button>
    <Button onClick={handleSimpan} className='btnPrimary3' autoFocus>
    Ya
    </Button>
</DialogActions>
</Dialog>

        </Container>

    </div>
  );

}

export default MPanel;




