import * as React from 'react';
import { slice } from 'lodash'
import axios from 'axios';
import GlobalVar from '../../GlobalVar';
import { useTheme, useMediaQuery } from '@material-ui/core';
import Typography from '@mui/material/Typography';
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';
import Button from '@mui/material/Button';
import CloseIcon from '@mui/icons-material/Close';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import { AnimationOnScroll } from 'react-animation-on-scroll';

import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Divider from '@mui/material/Divider';

  
import SendIcon from '@mui/icons-material/Send';
import CheckIcon from '@mui/icons-material/Check';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import QuestionMarkSharpIcon from '@mui/icons-material/QuestionMarkSharp';
import CheckSharpIcon from '@mui/icons-material/CheckSharp';
import CloseSharpIcon from '@mui/icons-material/CloseSharp';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import RefreshIcon from '@mui/icons-material/Refresh';
import AccessTimeIcon from '@mui/icons-material/AccessTime';

const server = GlobalVar.server;

const SectionRSVPPremiumUlos = (props) =>  {
    const [openAlert, setOpenAlert] = React.useState(false);
    const [alertMsg, setAlertMsg] = React.useState('');
    const [alertInit, setAlertInit] = React.useState('');
    const [listItem, setListItem] = React.useState([]);
    const [index, setIndex] = React.useState(5);
    const initialPosts = slice(listItem, 0, index);
    const [isMore, setIsMore] = React.useState(true)
    
    const[tbu_pesan, SetTbu_pesan] = React.useState('');
    const[tbu_nama, SetTbu_nama] = React.useState('');
    const[tbu_konfirmasi_hadir, SetTbu_konfirmasi_hadir] = React.useState('hadir');
    const[usrname, SetUsrname] = React.useState('');
    const kehadiran = [
        {
          value: 'hadir',
          label: 'Ya, Saya Akan Hadir',
        },
        {
            value: 'tidak',
            label: 'Maaf, Saya Tidak Bisa Hadir',
        },
        {
            value: 'ragu',
            label: 'Saya Masih Ragu',
        },
    ]

    const theme = useTheme();
    const mobile = useMediaQuery(theme.breakpoints.down('xs'));

    const handleChangeNama = (event: React.ChangeEvent<HTMLInputElement>) => {
        SetTbu_nama(event.target.value);
    };
    const handleChangePesan = (event: React.ChangeEvent<HTMLInputElement>) => {
        SetTbu_pesan(event.target.value);
    };
    const handleChangeKehadiran = (event: React.ChangeEvent<HTMLInputElement>) => {
        SetTbu_konfirmasi_hadir(event.target.value);
    };
    const loadMoreLess = () => {
        if (index >= listItem.length) {
            setIndex(5);
        } else {
            setIndex(index + 5);
        }        
    }
    const pathParts = window.location.pathname.split('/');  

    React.useEffect(() => {
        
        const path = window.location.search;
        const urlParams = new URLSearchParams(path);
        SetUsrname(pathParts[1]);

        axios.get(server+'GetRSVP.php?n='+pathParts[1]).then(function(response){
            setListItem(response.data)
        });
        

    }, []);

    const refreshData = () => {
        axios.get(server+'GetRSVP.php?n='+usrname).then(function(response){
            setListItem(response.data)
        });
    }

    const submitForm = () => {

        if(tbu_nama!="" && tbu_pesan !="" && tbu_konfirmasi_hadir != ""){

            const dataInsert = {
                usrname,
                tbu_nama,
                tbu_pesan,
                tbu_konfirmasi_hadir
            }

            axios.post(server+'PostRSVP.php', dataInsert).then(function(response){
                let res = JSON.stringify(response.data);
                let resP = JSON.parse(res);

                if(resP.statusCode==200){
                    axios.get(server+'GetRSVP.php?&n='+usrname).then(function(response){
                        setListItem(response.data)
                        setAlertMsg('Terima kasih Ucapan & Doa nya :)');
                        SetTbu_pesan('');
                        SetTbu_nama('');
                        SetTbu_konfirmasi_hadir('hadir');
                        setAlertInit('OK');
                    });
                }
                else if(resP.statusCode==500){
                    setAlertMsg("Maaf, ucapan tidak berhasil dikirim, tolong dicoba kembali..");
                    setAlertInit('Err');
                }

                setOpenAlert(true);
            });
                
            
        }
        else{
            setAlertMsg('Maaf, Semua data harus diisi..');
            setAlertInit('Err');
            setOpenAlert(true);
        }
    }

  return ( 
    <div className='App-header-section-ulos-rsvp' style={{minHeight: '100vh', paddingTop: 40}}>
        
        <Box
            component="form"
            sx={{
                '& .MuiTextField-root': { my: 1 },
            }}
            noValidate
            autoComplete="off"
        >
            <Container>
            <center>
                    <Typography variant={mobile ? "h3" : "h2"} mx={2} className="font-minang">Ucapan untuk Kami</Typography>
                    <Typography variant={mobile ? "body2" : "body1"} mb={4} mx={2} className='font-kulim'> 
                        Berikan ucapan & doa restu anda untuk kami
                    </Typography>
                                        <Collapse in={openAlert}>
                                            <Alert 
                                                icon={alertInit == 'OK' ? <CheckIcon fontSize="inherit" /> : <ErrorOutlineIcon fontSize="inherit" /> } 
                                                severity={alertInit == 'OK' ? "success" : "error"}
                                                action={
                                                <IconButton
                                                    aria-label="close"
                                                    color="inherit"
                                                    size="small"
                                                    onClick={() => {
                                                        setOpenAlert(false);
                                                    }}
                                                >
                                                    <CloseIcon fontSize="inherit" />
                                                </IconButton>
                                            }
                                            sx={{ mb: 2, fontSize: mobile ? '13px' : '14px' }}
                                            >
                                            {alertMsg}
                                            </Alert>
                                        </Collapse>
                <div>
                    <TextField 
                    id="tbu_nama" 
                    label="Nama" 
                    placeholder="Tulis nama anda / keluarga disini..."
                    variant="outlined" 
                    color='primary' 
                    className='input' 
                    value={tbu_nama} 
                    onChange={handleChangeNama} 
                    // sx={{ '.css-1sumxir-MuiFormLabel-root-MuiInputLabel-root.Mui-focused': { color: 'rgb(0,0,0,.67)', }, 
                    // '.css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {border: '1px solid rgb(0,0,0,.67)'}}} 
                    focused/>
                </div>
                    
                <div>
                    <TextField
                        id="tbu_konfirmasi_hadir"
                        select
                        label="Konfirmasi kehadiran"
                        value={tbu_konfirmasi_hadir}
                        onChange={handleChangeKehadiran}
                        helperText=""
                        className='input'
                        sx={{ textAlign: 'left' }}
                        // '.css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {border: '1px solid rgb(0,0,0,.67)'}}} 
                    >
                    {kehadiran.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                            {option.label}
                        </MenuItem>
                    ))}
                    </TextField>
                </div>
 
                <div>
                    <TextField
                        id="tbu_pesan"
                        label="Pesan"
                        placeholder="Tulis Ucapan & Doa anda disini..."
                        multiline
                        rows={6}
                        defaultValue=""
                        color='primary'
                        className='input'
                        value={tbu_pesan} 
                        onChange={handleChangePesan}
                        focused
                        // sx={{ '.css-1sumxir-MuiFormLabel-root-MuiInputLabel-root.Mui-focused': { color: 'rgb(0,0,0,.67)', }, 
                        // '.css-dpjnhs-MuiInputBase-root-MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {border: '1px solid rgb(0,0,0,.67)'}}} 
                    />
                </div>
                
                <Button disableElevation startIcon={<SendIcon />} size={ mobile ? "small" : "large" } onClick={submitForm}
                className="btnPrimaryUlosB font-XanhMono " style={{marginTop: 20, marginBottom: 20}} >Send Message</Button>

        </center>

                {
                    listItem.length > 0 ?
                    <div>

                    <center>
                        <IconButton
                            aria-label="refresh"
                            color="inherit"
                            size={mobile ? "medium" : "large"}
                            onClick={refreshData}       
                            sx={{marginBottom: 1}}                        
                        >
                            <RefreshIcon fontSize="inherit" />
                        </IconButton>
                    </center>
                            {
                                initialPosts.map((item) => (
                                <Card sx={{ overflowWrap: 'break-word', borderLeft: '1px solid rgba(48, 36, 36, 1)',borderRight: '1px solid rgba(48, 36, 36, 1)',borderTop: '1px solid rgba(48, 36, 36, 1)',  borderBottom: '3px solid rgba(48, 36, 36, 1)', padding: mobile ? '0px 5px' : '0px 15px', background: 'transparent', color: 'rgba(48, 36, 36, 1)' }} 
                                elevation={0}>
                                    <CardContent>
                                        <Typography variant={mobile ? "caption" : "body2"} component="div" color="text.secondary" className="font-XanhMono ">
                                        
                                        </Typography>
                                        <Typography variant={mobile ? "body1" : "h6"} component="div" className="font-XanhMono ">
                                            {item.tbu_nama} { item.tbu_konfirmasi_hadir == 'hadir' ? <CheckSharpIcon sx={{ fontSize: mobile ? 14: 16,color: '#4D7C0F', marginRight: 1 }}/> 
                                        : item.tbu_konfirmasi_hadir == 'tidak' ? <CloseSharpIcon sx={{ fontSize: mobile ? 14: 16,color: '#F87171', marginRight: 1 }} />
                                        : <QuestionMarkSharpIcon sx={{ fontSize: mobile ? 14: 16,color: '#FACC15', marginRight: 1 }} />}  
                                        </Typography>
                                        <Typography variant={mobile ? "body1" : "body1"} className="font-XanhMono font-thin">
                                            {item.tbu_pesan}
                                        </Typography>
                                        <Typography variant={mobile ? "caption" : "caption"} component="span" color="rgba(48, 36, 36, 1)" className="font-XanhMono " >
                                            <i><AccessTimeIcon sx={{ fontSize: 12, marginTop: 1 }}  /> {item.timeAgo}</i>
                                        </Typography>
                                    </CardContent>
                                </Card>
                                ))
                            
                            }
                            <br></br>
                            <center>
                            {   
                                index >= listItem.length ? 
                                    listItem.length > 5 &&
                                    (
                                        <Button disableElevation startIcon={<ExpandLessIcon />} size={ mobile ? "medium" : "large" } style={{marginTop: 10, marginBottom: 40}} 
                                        onClick={loadMoreLess} className="btnPrimaryUlos"> Show Less</Button>
                                    ) 
                                    
                                : 
                                    listItem.length > 5 &&
                                (
                                    <Button disableElevation startIcon={<ExpandMoreIcon />} size={ mobile ? "medium" : "large" } style={{marginTop: 10, marginBottom: 40}} 
                                    onClick={loadMoreLess} className="btnPrimaryUlos" > Show More</Button>                                  
                                )
                            }
                            </center>
                    </div>
                : ''
                }
                    
            </Container>
            
        </Box>        
      
    </div>
  );
}

export default SectionRSVPPremiumUlos;