import * as React from 'react';
import { useTheme, useMediaQuery } from '@material-ui/core';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import { CardActionArea } from '@mui/material';
import { AnimationOnScroll } from 'react-animation-on-scroll';

import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';

const Item = styled(Paper)(({ theme }) => ({
    padding: theme.spacing(0.25),
    color: theme.palette.text.secondary,
  }));
  
const SectionMempelai2 = (props) => {
    const theme = useTheme();
    const mobile = useMediaQuery(theme.breakpoints.down('xs'));
    const tablet = useMediaQuery(theme.breakpoints.down('sm'));

    const pathParts = window.location.pathname.split('/'); 
  
  return (
    <div className='Section'>
        <img src={require('../../images/elegant2bgFlowerTop.jpg')} width={'100%'} />
            <Box className='bg-flower'>
            { props.islam &&
                <center>  
                    <AnimationOnScroll animateIn="animate__fadeInDown">
                        <Typography variant="body1" className="font-kulim font-thin" mx={2} mb={2}>
                            {props.islam == 1 ? 'Semoga Allah memberkahimu di waktu bahagia dan memberkahimu di waktu susah dan semoga Allah menyatukan kalian berdua dalam kebaikan'
                             : 'Tuhan membuat segala sesuatu indah pada waktu-Nya. Indah ketika mempertemukan kami, indah saat menumbuhkan kasih di antara kami, dan indah saat Ia mempersatukan kami dalam pernikahan yang kudus.'}
                        </Typography>
                        {props.islam == 1 &&
                            <Typography variant="body1" className="font-kulim font-thin" mx={2} mt={-1} mb={2}>
                                (HR. Abu Daud)
                            </Typography>
                        }
                        
                    </AnimationOnScroll>
                    <Divider sx={{ width: 200 }}><FavoriteBorderIcon sx={{ fontSize: 20 }} style={{color: props.cs_color == 'Green' ? '#5F7161' : '#F87171'}}/></Divider>      
                </center>
            }
        
                <Grid container 
                    direction="row"
                    justifyContent="center"
                    alignItems="center" 
                    px={2}
                >
                    
                    <Grid item xs={12}>
                        <Item elevation={0} className='bgTransparent'>
                            <Card elevation={0} className='bgTransparent'>
                                {
                                    props.fotoWanita &&
                                        <AnimationOnScroll animateIn="animate__fadeIn">
                                            <center>
                                                <CardMedia
                                                    component="img"
                                                    sx={{ width: 250 }}
                                                    image={`data:image/png;base64, ${props.fotoWanita}`}
                                                    className="img-mempelai2"
                                                    alt="mempelai wanita"
                                                />
                                            </center>
                                        </AnimationOnScroll>
                                }
                                
                                <CardActionArea className='cardNoAction'>
                                    <CardContent>
                                        <center>
                                        {
                                            pathParts[1] != 'syntayose' &&
                                                <AnimationOnScroll animateIn="animate__zoomIn">
                                                    <Typography variant="h4" mb={1} className='font-satisfy text-shadow' component="div">
                                                        {props.wanita}
                                                    </Typography>
                                                </AnimationOnScroll>
                                        }
                                        <AnimationOnScroll animateIn="animate__zoomIn">
                                        {
                                            pathParts[1] != 'syntayose' ?
                                                <>
                                                    <Typography gutterBottom variant="h5" 
                                                    className={props.cs_color == 'Green' ? 'font-HelveticaB text-green' : 'font-HelveticaB'} component="div">
                                                        {props.wanitaFull}
                                                    </Typography>
                                                    <Typography variant="body1" className="font-kulim" color="text.secondary" mt={2} mb={1}> Putri dari </Typography>
                                                </>
                                            :
                                                <>
                                                    <Typography gutterBottom variant="h4" 
                                                    className={props.cs_color == 'Green' ? 'font-satisfy text-green' : 'font-satisfy'} component="div">
                                                        {props.wanitaFull}
                                                    </Typography>
                                                    <Typography variant="body1" className="font-kulim" color="text.secondary" mt={2} mb={1}> Putri pertama dari </Typography>
                                                </>
                                        }   
                                            <Typography variant="body1" className="font-kulim font-thin" >Bapak {props.ayahWanita} &</Typography>
                                            <Typography variant="body1" className="font-kulim font-thin" >Ibu {props.ibuWanita}</Typography>
                                        </AnimationOnScroll>
                                        </center>
                                    </CardContent>
                                </CardActionArea>
                            </Card>
                        </Item>
                    </Grid>
                        <Typography variant="h1" my={-2} className='font-curly' component="div">
                            &
                        </Typography>
                    <Grid item xs={12}>
                        <Item elevation={0} className='bgTransparent'>
                            <Card elevation={0} className='bgTransparent'>
                                {
                                    props.fotoPria &&
                                        <AnimationOnScroll animateIn="animate__fadeIn">
                                            <center>
                                                <CardMedia
                                                    component="img"
                                                    sx={{ width: 250 }}
                                                    image={`data:image/png;base64, ${props.fotoPria}`}
                                                    className="img-mempelai"
                                                    alt="mempelai pria"
                                                />
                                            </center>
                                        </AnimationOnScroll>
                                }
                                <CardActionArea className='cardNoAction'>
                                    <CardContent>
                                        <center>
                                        {
                                            pathParts[1] != 'syntayose' &&
                                                <AnimationOnScroll animateIn="animate__zoomIn">
                                                    <Typography variant="h4" mb={1} className='font-satisfy text-shadow' component="div">
                                                        {props.pria}
                                                    </Typography>
                                                </AnimationOnScroll>
                                        }

                                        <AnimationOnScroll animateIn="animate__zoomIn">
                                        {
                                            pathParts[1] != 'syntayose' ?
                                                <>
                                                    <Typography gutterBottom variant="h5" 
                                                    className={props.cs_color == 'Green' ? 'font-HelveticaB text-green' : 'font-HelveticaB'} component="div">
                                                        {props.priaFull}
                                                    </Typography>
                                                    <Typography variant="body1" className="font-kulim" color="text.secondary" mt={2} mb={1}> Putra dari </Typography>
                                                </>
                                            :
                                                <>
                                                    <Typography gutterBottom variant="h4" 
                                                    className={props.cs_color == 'Green' ? 'font-satisfy text-green' : 'font-satisfy'} component="div">
                                                        {props.priaFull}
                                                    </Typography>
                                                    <Typography variant="body1" className="font-kulim" color="text.secondary" mt={2} mb={1}> Putra kedua dari </Typography>
                                                </>
                                        }  
                                            
                                            <Typography variant="body1" className="font-kulim font-thin" >Bapak {props.ayahPria} &</Typography>
                                            <Typography variant="body1" className="font-kulim font-thin" >Ibu {props.ibuPria}</Typography>
                                        </AnimationOnScroll>
                                        </center>
                                    </CardContent>
                                </CardActionArea>
                            </Card>
                        </Item>
                    </Grid>
                </Grid>
                <img src={require('../../images/elegant2bgFlowerBottom.png')} width={'100%'} />
            </Box>
            { props.unduhOnly == 0 && 
                <div className={props.cs_color == 'Green' ? 'bgAyatGreen' : 'bgAyat'}>
                    <Box>
                    { props.islam &&
                        <center>
                        {
                            pathParts[1] == 'syntayose' || pathParts[1] == 'pujabudi' ?
                                <AnimationOnScroll animateIn="animate__fadeIn">
                                    <Typography variant="body1" pt={2} mx={2} className='font-kulim font-thin' style={{width:'85%'}}>
                                    "Dan di antara tanda-tanda (kebesaran)-Nya ialah Dia menciptakan pasangan-pasangan untukmu dari jenismu sendiri, agar kamu cenderung dan merasa tenteram kepadanya, dan Dia menjadikan di antaramu rasa kasih dan sayang."                                    
                                    </Typography>            
                                </AnimationOnScroll>
                            :
                                <AnimationOnScroll animateIn="animate__fadeIn">
                                    <Typography variant="body1" pt={2} mx={2} className='font-kulim font-thin' style={{width:'85%'}}>
                                    {props.islam == 1 ? '"Ya Tuhanku, anugerahkanlah aku ilham untuk tetap mensyukuri nikmat-Mu yang telah Engkau anugerahkan kepadaku dan kepada kedua orang tuaku dan agar aku mengerjakan kebajikan yang Engkau ridhai; dan masukkanlah aku dengan rahmat-Mu ke dalam golongan hamba-hamba-Mu yang shalih"'
                                    : '"Sebab pada awal dunia, Allah menjadikan mereka laki-laki dan perempuan, sebab itu laki-laki akan meninggalkan ayahnya dan ibunya dan bersatu dengan isterinya, sehingga keduanya itu menjadi satu daging. Demikianlah mereka bukan lagi dua, melainkan satu. Karena itu, apa yang telah dipersatukan Allah, tidak boleh diceraikan manusia."'}
                                    </Typography>            
                                </AnimationOnScroll>

                        }
                        {
                            pathParts[1] == 'syntayose' || pathParts[1] == 'pujabudi' ?
                                <AnimationOnScroll animateIn="animate__fadeIn">
                                    <Typography variant="body1" pb={2} mt={2} mx={2} className="font-kulim ">
                                    ( QS. Ar-Rum ayat 21 )</Typography>
                                </AnimationOnScroll>
                            :
                                <AnimationOnScroll animateIn="animate__fadeIn">
                                    <Typography variant="body1" pb={2} mt={2} mx={2} className="font-kulim ">
                                    {props.islam == 1 ? '( QS. An-Naml ayat 19 )' : '( Markus 10:6-9 )'}</Typography>
                                </AnimationOnScroll>

                        }
                        </center> 
                    }
                    </Box>
                </div>
            }
    </div>
  );
}

export default SectionMempelai2;
