import * as React from 'react';
import { useTheme, useMediaQuery } from '@material-ui/core';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Fade from '@mui/material/Fade';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import { CardActionArea } from '@mui/material';
import { AnimationOnScroll } from 'react-animation-on-scroll';

import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';

const Item = styled(Paper)(({ theme }) => ({
    padding: theme.spacing(0.25),
    color: theme.palette.text.secondary,
  }));
  
const SectionMempelaiClassic = (props) => {
    const theme = useTheme();
    const mobile = useMediaQuery(theme.breakpoints.down('xs'));
    const tablet = useMediaQuery(theme.breakpoints.down('sm'));

    const pathParts = window.location.pathname.split('/'); 
  
  return (
    <div className='secMempelaiClassic'>
            <Box>
            { props.islam &&
                <center>  
                    { props.islam == 1 &&
                        <Fade in={props.visible}
                            {...(props.visible ? { timeout: 4000 } : {})} > 
                            <Typography variant="body2" className='font-jost font-thin' px={2} pt={4} gutterBottom >Dengan memohon ridho dan rahmat Allah SWT, kami mengundang Bapak/Ibu/Saudara/i untuk hadir pada acara pernikahan putra putri kami</Typography>
                        </Fade>
                    }
                </center>
            }
        
                <Grid container 
                    direction="row"
                    justifyContent="center"
                    alignItems="center" 
                    spacing={2} mt={4} px={2} mb={4}
                >                                        
                    <Grid item xs={12}>
                        {
                            props.fotoWanita &&
                                <AnimationOnScroll animateIn="animate__fadeIn">
                                    <center>
                                        {
                                            // pathParts[1] != 'rizalditta' &&
                                            // <Typography variant="h5" className='text-orangeSecond font-satisfy mempelaiClassic' component="div">BRIDE</Typography>
                                        }
                                        <img src={`data:image/*;base64,${props.fotoWanita}`} width={200} className="img-mempelaiClassic2" alt="mempelai wanita"/>
                                    </center>
                                </AnimationOnScroll>
                        }
                        <center>
                            <AnimationOnScroll animateIn="animate__fadeInDown">
                                <Typography variant="h4" className={props.themePage == 'Classic_Blue' ? 'text-blue font-satisfy' : 
                                props.themePage == 'Classic_Green' ? 'text-green font-satisfy' :
                                props.themePage == 'Classic_Orange' ? 'text-orange font-satisfy' : props.themePage == 'Classic_Red' ? 'text-red font-satisfy' :
                                 'font-satisfy' } mt={2} component="div">
                                    {props.wanitaFull}
                                </Typography>
                            </AnimationOnScroll>
                            <AnimationOnScroll animateIn="animate__fadeInUp">
                                <Typography variant="body1" className='font-jost font-thin' mt={2} mb={1}> Putri dari </Typography>
                                <Typography variant="body1" className='font-jost font-thin' >Bapak {props.ayahWanita} &</Typography>
                                <Typography variant="body1" className='font-jost font-thin' >Ibu {props.ibuWanita}</Typography>
                            </AnimationOnScroll>
                        </center>
                    </Grid>
                    
                    <Grid item xs={12}>
                        {
                            props.fotoPria &&
                            <AnimationOnScroll animateIn="animate__fadeIn">
                                <center>
                                    {
                                        // pathParts[1] != 'rizalditta' &&
                                        //     <Typography variant="h5"  className='text-orangeSecond font-satisfy mempelaiClassic' component="div">GROOM</Typography>
                                    }
                                    <img src={`data:image/*;base64,${props.fotoPria}`} width={200} className="" alt="mempelai pria"/>
                                </center>
                            </AnimationOnScroll>
                        }
                        <center>
                            <AnimationOnScroll animateIn="animate__fadeInDown">
                                <Typography variant="h4" className={props.themePage == 'Classic_Blue' ? 'text-blue font-satisfy' : 
                                props.themePage == 'Classic_Green' ? 'text-green font-satisfy' :
                                props.themePage == 'Classic_Orange' ? 'text-orange font-satisfy' : props.themePage == 'Classic_Red' ? 'text-red font-satisfy' :
                                 'font-satisfy' } mt={2} component="div">
                                    {props.priaFull}
                                </Typography>
                            </AnimationOnScroll>

                            <AnimationOnScroll animateIn="animate__fadeInUp">
                                <Typography variant="body1" className='font-jost font-thin' mt={2} mb={1}> Putra dari </Typography>
                                <Typography variant="body1" className='font-jost font-thin' >Bapak {props.ayahPria} &</Typography>
                                <Typography variant="body1" className='font-jost font-thin' >Ibu {props.ibuPria}</Typography>
                            </AnimationOnScroll>
                        </center>
                    </Grid>
                </Grid>
            </Box>
    </div>
  );
}

export default SectionMempelaiClassic;
