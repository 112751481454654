import * as React from 'react';
import Typography from '@mui/material/Typography';
import "animate.css/animate.min.css";
import './App.css';
import OpeningInvitation from './components/OpeningInvitation';
// ============================================================== ELEGANT THEME
import SectionHeader from './components/Elegant/Section-header';
import SectionMempelai from './components/Elegant/Section-mempelai';
import SectionAcara from './components/Elegant/Section-acara';
import SectionGallery from './components/Elegant/Section-gallery';
import SectionRSVP from './components/Elegant/Section-rsvp';
import SectionFooter from './components/Elegant/Section-footer';
import SectionStory from './components/Elegant/Section-story';
// ============================================================== ELEGANT2 THEME
import SectionHeader2 from './components/Elegant2/Section-header';
import SectionMempelai2 from './components/Elegant2/Section-mempelai';
import SectionAcara2 from './components/Elegant2/Section-acara';
import SectionGallery2 from './components/Elegant2/Section-gallery';
import SectionRSVP2 from './components/Elegant2/Section-rsvp';
import SectionFooter2 from './components/Elegant2/Section-footer';
// ============================================================== ULOS THEME
import SectionHeaderUlos from './components/Ulos/Section-header-ulos';
import SectionHMempelaiUlos from './components/Ulos/Section-mempelai-ulos';
import SectionAcaraUlos from './components/Ulos/Section-acara-ulos';
import SectionRSVPUlos from './components/Ulos/Section-rsvp-ulos.js';
import SectionGalleryUlos from './components/Ulos/Section-gallery-ulos';
import SectionFooterUlos from './components/Ulos/Section-footer-ulos';
// ============================================================== JAWA THEME
import SectionHeaderJawa from './components/Jawa/Section-header-jawa';
import SectionMempelaiJawa from './components/Jawa/Section-mempelai-jawa';
import SectionAcaraJawa from './components/Jawa/Section-acara-jawa';
import SectionRSVPJawa from './components/Jawa/Section-rsvp-jawa';
import SectionGalleryJawa from './components/Jawa/Section-gallery-jawa';
import SectionFooterJawa from './components/Jawa/Section-footer-jawa';
// ============================================================== PREMIUM THEME
import SectionAll from './components/premium/Section-all';
// ============================================================== PREMIUM ULOS THEME
import SectionAllUlos from './components/premium_ulos/Section-all';
// ============================================================== PREMIUM JAWA THEME
import SectionAllJawa from './components/premium_jawa/Section-all';
// ============================================================== PASTEL THEME
import SectionHeaderPastel from './components/pastel/Section-header';
import SectionMempelaiPastel from './components/pastel/Section-mempelai';
import SectionAcaraPastel from './components/pastel/Section-acara';
import SectionRSVPPastel from './components/pastel/Section-rsvp';
import SectionGalleryPastel from './components/pastel/Section-gallery';
import SectionFooterPastel from './components/pastel/Section-footer';
// ============================================================== MINANG THEME
import SectionHeaderMinang from './components/Minang/Section-header-minang';
import SectionMempelaiMinang from './components/Minang/Section-mempelai-minang';
import SectionAcaraMinang from './components/Minang/Section-acara-minang';
import SectionRSVPMinang from './components/Minang/Section-rsvp-minang';
import SectionGalleryMinang from './components/Minang/Section-gallery-minang';
import SectionFooterMinang from './components/Minang/Section-footer-minang';
// ============================================================== CLASSIC THEME
import SectionHeaderClassic from './components/Classic/Section-header';
import SectionMempelaiClassic from './components/Classic/Section-mempelai';
import SectionAcaraClassic from './components/Classic/Section-acara';
import SectionGalleryClassic from './components/Classic/Section-gallery';
import SectionRSVPClassic from './components/Classic/Section-rsvp';
import SectionFooterClassic from './components/Classic/Section-footer';


import { Link, animateScroll as scroll } from "react-scroll";
import Fullscreen from 'fullscreen-react';

import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import SupervisedUserCircleOutlinedIcon from '@mui/icons-material/SupervisedUserCircleOutlined';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import MenuBookOutlinedIcon from '@mui/icons-material/MenuBookOutlined';
import CollectionsOutlinedIcon from '@mui/icons-material/CollectionsOutlined';

const App = () => {
  const [valueMenus, setValueMenus] = React.useState('recents');
  const handleChangeMenus = (event, newValue) => {
    setValueMenus(newValue);
  };
  
//   React.useEffect(() => {
//     const handleContextmenu = e => {
//         e.preventDefault();
//         alert('tes');
//     }
//     document.addEventListener('contextmenu', handleContextmenu)
//     return function cleanup() {
//         document.removeEventListener('contextmenu', handleContextmenu)
//     }
// }, [ ])

  const [visible, setVisible] = React.useState(false);

  const [pria, setPria] = React.useState('');
  const [wanita, setWanita] = React.useState('');
  const [priaFull, setPriaFull] = React.useState('');
  const [wanitaFull, setWanitaFull] = React.useState('');
  const [tglUnduhS, setTglUnduhS] = React.useState('');
  const [tglUnduhE, setTglUnduhE] = React.useState('');
  const [lokUnduh, setLokUnduh] = React.useState('');
  const [tglAkadS, setTglAkadS] = React.useState('');
  const [tglAkadE, setTglAkadE] = React.useState('');
  const [lokAkad, setLokAkad] = React.useState('');
  const [tglResepsiS, setTglResepsiS] = React.useState('');
  const [tglResepsiE, setTglResepsiE] = React.useState('');
  const [lokResepsi, setLokResepsi] = React.useState('');
  const [ayahPria, setAyahPria] = React.useState('');
  const [ibuPria, setIbuPria] = React.useState('');
  const [ayahWanita, setAyahWanita] = React.useState('');
  const [ibuWanita, setIbuWanita] = React.useState('');
  const [unduhOnly, setUnduhOnly] = React.useState(0);
  const [fotoPria, setFotoPria] = React.useState('');
  const [fotoWanita, setFotoWanita] = React.useState('');
  const [fotoCover1, setFotoCover1] = React.useState('');
  const [fotoCover2, setFotoCover2] = React.useState('');
  const [fotoOpening, setFotoOpening] = React.useState('');
  const [foto1, setFoto1] = React.useState('');
  const [foto2, setFoto2] = React.useState('');
  const [foto3, setFoto3] = React.useState('');
  const [foto4, setFoto4] = React.useState('');
  const [foto5, setFoto5] = React.useState('');
  const [foto6, setFoto6] = React.useState('');
  const [foto7, setFoto7] = React.useState('');
  const [foto8, setFoto8] = React.useState('');
  const [foto9, setFoto9] = React.useState('');
  const [foto10, setFoto10] = React.useState('');
  const [foto11, setFoto11] = React.useState('');
  const [foto12, setFoto12] = React.useState('');
  const [unduhGmaps, setUnduhGmaps] = React.useState('');
  const [akadGmaps, setAkadGmaps] = React.useState('');
  const [resepsiGmaps, setResepsiGmaps] = React.useState('');
  const [bank1Name, setBank1Name] = React.useState('');
  const [bank1Rek, setBank1Rek] = React.useState('');
  const [bank1An, setBank1An] = React.useState('');
  const [bank2Name, setBank2Name] = React.useState('');
  const [bank2Rek, setBank2Rek] = React.useState('');
  const [bank2An, setBank2An] = React.useState('');
  const [alamatHadiah, setAlamatHadiah] = React.useState('');
  const [linkStreaming, setLinkStreaming] = React.useState('');
  const [paketID, setPaketID] = React.useState('');
  const [theme, setTheme] = React.useState('');
  const [islam, setIslam] = React.useState('');
  const [plusResepsi, setPlusResepsi] = React.useState('');
  const [fotoShared, setFotoShared] = React.useState('');
  const [audioMempelai, setAudioMempelai] = React.useState('');
  const [gmapsIframeAkad, setGmapsIframeAkad] = React.useState('');
  const [gmapsIframeResepsi, setGmapsIframeResepsi] = React.useState('');  
  const [cs_color, setCs_color] = React.useState('');    

  const [ls_pict_awal, setLs_pict_awal] = React.useState('');
  const [ls_awal, setLs_awal] = React.useState('');
  const [ls_pict_mengenal, setLs_pict_mengenal] = React.useState('');
  const [ls_mengenal, setLs_mengenal] = React.useState('');
  const [ls_pict_akhir, setLs_pict_akhir] = React.useState('');
  const [ls_akhir, setLs_akhir] = React.useState('');
  
  const pathParts = window.location.pathname.split('/');  

  return (
    <div className='Main'>

      <OpeningInvitation visible={setVisible} theme={setTheme}/>
{
      visible ? 
      // <Fullscreen isEnter={true} >
      <div>
        {
          theme == 'Elegant' ?
          <div className='divCenter'>
            <div className='divCenterContent'>
              <div id="headerElegant"><SectionHeader visible={visible} pria={setPria} wanita={setWanita} priaFull={setPriaFull} wanitaFull={setWanitaFull} tglUnduhS={setTglUnduhS}  
              tglUnduhE={setTglUnduhE} lokUnduh={setLokUnduh} tglAkadS={setTglAkadS} tglAkadE={setTglAkadE} lokAkad ={setLokAkad} tglResepsiS={setTglResepsiS}  
              tglResepsiE={setTglResepsiE} lokResepsi ={setLokResepsi} ayahPria={setAyahPria} ibuPria={setIbuPria} ayahWanita={setAyahWanita} ibuWanita={setIbuWanita}  
              unduhOnly={setUnduhOnly} fotoPria={setFotoPria} fotoWanita={setFotoWanita} unduhGmaps={setUnduhGmaps} akadGmaps={setAkadGmaps} resepsiGmaps={setResepsiGmaps} fotoCover1={setFotoCover1} fotoCover2={setFotoCover2} fotoOpening={setFotoOpening}
              foto1={setFoto1} foto2={setFoto2} foto3={setFoto3} foto4={setFoto4} foto5={setFoto5} foto6={setFoto6} foto7={setFoto7} foto8={setFoto8} foto9={setFoto9} foto10={setFoto10} 
              foto11={setFoto11} foto12={setFoto12} bank1Name={setBank1Name} bank1Rek={setBank1Rek} bank1An={setBank1An} bank2Name={setBank2Name} bank2Rek={setBank2Rek} bank2An={setBank2An}
              alamatHadiah={setAlamatHadiah} linkStreaming={setLinkStreaming} paketID={setPaketID} islam={setIslam} plusResepsi={setPlusResepsi} fotoShared={setFotoShared} audioMempelai={setAudioMempelai}
              ls_pict_awal={setLs_pict_awal} ls_awal={setLs_awal} ls_pict_mengenal={setLs_pict_mengenal} ls_mengenal={setLs_mengenal} ls_pict_akhir={setLs_pict_akhir} ls_akhir={setLs_akhir} cs_color={setCs_color} /></div>

              <div id="mempelaiElegant"><SectionMempelai visible={visible} pria={pria} wanita={wanita} priaFull={priaFull} wanitaFull={wanitaFull} fotoPria={fotoPria} fotoWanita={fotoWanita} ayahPria={ayahPria} ibuPria={ibuPria} 
              ayahWanita={ayahWanita} ibuWanita={ibuWanita} unduhOnly={unduhOnly} paketID={paketID} islam={islam} ls_pict_awal={ls_pict_awal} ls_awal={ls_awal} 
              ls_pict_mengenal={ls_pict_mengenal} ls_mengenal={ls_mengenal} ls_pict_akhir={ls_pict_akhir} ls_akhir={ls_akhir} cs_color={cs_color}/> </div>
          
              <div id="acaraElegant"><SectionAcara visible={visible} tglUnduhS={tglUnduhS} tglUnduhE={tglUnduhE} lokUnduh={lokUnduh} tglAkadS={tglAkadS} tglAkadE={tglAkadE} lokAkad ={lokAkad} tglResepsiS={tglResepsiS}  
              tglResepsiE={tglResepsiE} lokResepsi ={lokResepsi} unduhGmaps={unduhGmaps} akadGmaps={akadGmaps} resepsiGmaps={resepsiGmaps} fotoCover2={fotoCover2} 
              unduhOnly={unduhOnly} bank1Name={bank1Name} bank1Rek={bank1Rek} bank1An={bank1An} bank2Name={bank2Name} bank2Rek={bank2Rek} bank2An={bank2An} 
              alamatHadiah={alamatHadiah} linkStreaming={linkStreaming} paketID={paketID} islam={islam} plusResepsi={plusResepsi} cs_color={cs_color} /> </div>

              {/* <div><SectionStory /></div> */}
              
              <div id="rsvpElegant"><SectionRSVP visible={visible} cs_color={cs_color} /></div>

              <div id="galleryElegant"><SectionGallery visible={visible} foto1={foto1} foto2={foto2} foto3={foto3} foto4={foto4} foto5={foto5} foto6={foto6}
              foto7={foto7} foto8={foto8} foto9={foto9} foto10={foto10} foto11={foto11} foto12={foto12} paketID={paketID} /> </div>                   

              <SectionFooter audioMempelai={audioMempelai} cs_color={cs_color} />
            </div>
          </div>

          :
          theme == 'Elegant2' ?
          <div className='divCenter'>
            <div className='divCenterContent'>
              <div id="headerElegant2"><SectionHeader2 visible={visible} pria={setPria} wanita={setWanita} priaFull={setPriaFull} wanitaFull={setWanitaFull} tglUnduhS={setTglUnduhS}  
              tglUnduhE={setTglUnduhE} lokUnduh={setLokUnduh} tglAkadS={setTglAkadS} tglAkadE={setTglAkadE} lokAkad ={setLokAkad} tglResepsiS={setTglResepsiS}  
              tglResepsiE={setTglResepsiE} lokResepsi ={setLokResepsi} ayahPria={setAyahPria} ibuPria={setIbuPria} ayahWanita={setAyahWanita} ibuWanita={setIbuWanita}  
              unduhOnly={setUnduhOnly} fotoPria={setFotoPria} fotoWanita={setFotoWanita} unduhGmaps={setUnduhGmaps} akadGmaps={setAkadGmaps} resepsiGmaps={setResepsiGmaps} fotoCover1={setFotoCover1} fotoCover2={setFotoCover2} fotoOpening={setFotoOpening}
              foto1={setFoto1} foto2={setFoto2} foto3={setFoto3} foto4={setFoto4} foto5={setFoto5} foto6={setFoto6} foto7={setFoto7} foto8={setFoto8} foto9={setFoto9} foto10={setFoto10} 
              foto11={setFoto11} foto12={setFoto12} bank1Name={setBank1Name} bank1Rek={setBank1Rek} bank1An={setBank1An} bank2Name={setBank2Name} bank2Rek={setBank2Rek} bank2An={setBank2An}
              alamatHadiah={setAlamatHadiah} linkStreaming={setLinkStreaming} paketID={setPaketID} islam={setIslam} plusResepsi={setPlusResepsi} fotoShared={setFotoShared} audioMempelai={setAudioMempelai}
              ls_pict_awal={setLs_pict_awal} ls_awal={setLs_awal} ls_pict_mengenal={setLs_pict_mengenal} ls_mengenal={setLs_mengenal} ls_pict_akhir={setLs_pict_akhir} ls_akhir={setLs_akhir} cs_color={setCs_color} /></div>

              <div id="mempelaiElegant2"><SectionMempelai2 visible={visible} pria={pria} wanita={wanita} priaFull={priaFull} wanitaFull={wanitaFull} fotoPria={fotoPria} fotoWanita={fotoWanita} ayahPria={ayahPria} ibuPria={ibuPria} 
              ayahWanita={ayahWanita} ibuWanita={ibuWanita} unduhOnly={unduhOnly} paketID={paketID} islam={islam} ls_pict_awal={ls_pict_awal} ls_awal={ls_awal} 
              ls_pict_mengenal={ls_pict_mengenal} ls_mengenal={ls_mengenal} ls_pict_akhir={ls_pict_akhir} ls_akhir={ls_akhir} cs_color={cs_color}/> </div>
          
              <div id="acaraElegant2"><SectionAcara2 visible={visible} tglUnduhS={tglUnduhS} tglUnduhE={tglUnduhE} lokUnduh={lokUnduh} tglAkadS={tglAkadS} tglAkadE={tglAkadE} lokAkad ={lokAkad} tglResepsiS={tglResepsiS}  
              tglResepsiE={tglResepsiE} lokResepsi ={lokResepsi} unduhGmaps={unduhGmaps} akadGmaps={akadGmaps} resepsiGmaps={resepsiGmaps} fotoCover2={fotoCover2} 
              unduhOnly={unduhOnly} bank1Name={bank1Name} bank1Rek={bank1Rek} bank1An={bank1An} bank2Name={bank2Name} bank2Rek={bank2Rek} bank2An={bank2An} 
              alamatHadiah={alamatHadiah} linkStreaming={linkStreaming} paketID={paketID} islam={islam} plusResepsi={plusResepsi} cs_color={cs_color} /> </div>

              {/* <div><SectionStory /></div> */}
              
              <div id="rsvpElegant2"><SectionRSVP2 visible={visible} cs_color={cs_color} /></div>

              <div id="galleryElegant2"><SectionGallery2 visible={visible} foto1={foto1} foto2={foto2} foto3={foto3} foto4={foto4} foto5={foto5} foto6={foto6}
              foto7={foto7} foto8={foto8} foto9={foto9} foto10={foto10} foto11={foto11} foto12={foto12} paketID={paketID} /> </div>                   

              <SectionFooter2 audioMempelai={audioMempelai} cs_color={cs_color} />
            </div>
          </div>

          :
          
          theme == 'Ulos' ?
          
        <div className='divCenter bgUlos'>
          <div className='divCenterContent'>
            <div id="headerUlos"><SectionHeaderUlos visible={visible} pria={setPria} wanita={setWanita} priaFull={setPriaFull} wanitaFull={setWanitaFull} tglUnduhS={setTglUnduhS}  
            tglUnduhE={setTglUnduhE} lokUnduh={setLokUnduh} tglAkadS={setTglAkadS} tglAkadE={setTglAkadE} lokAkad ={setLokAkad} tglResepsiS={setTglResepsiS}  
            tglResepsiE={setTglResepsiE} lokResepsi ={setLokResepsi} ayahPria={setAyahPria} ibuPria={setIbuPria} ayahWanita={setAyahWanita} ibuWanita={setIbuWanita}  
            unduhOnly={setUnduhOnly} fotoPria={setFotoPria} fotoWanita={setFotoWanita} unduhGmaps={setUnduhGmaps} akadGmaps={setAkadGmaps} resepsiGmaps={setResepsiGmaps} fotoCover1={setFotoCover1} fotoCover2={setFotoCover2} fotoOpening={setFotoOpening}
            foto1={setFoto1} foto2={setFoto2} foto3={setFoto3} foto4={setFoto4} foto5={setFoto5} foto6={setFoto6} foto7={setFoto7} foto8={setFoto8} foto9={setFoto9} foto10={setFoto10} 
            foto11={setFoto11} foto12={setFoto12} bank1Name={setBank1Name} bank1Rek={setBank1Rek} bank1An={setBank1An} bank2Name={setBank2Name} bank2Rek={setBank2Rek} bank2An={setBank2An} 
            alamatHadiah={setAlamatHadiah} linkStreaming={setLinkStreaming} paketID={setPaketID} islam={setIslam} plusResepsi={setPlusResepsi} fotoShared={setFotoShared} audioMempelai={setAudioMempelai} /></div>

            <div id="mempelaiUlos"><SectionHMempelaiUlos visible={visible} pria={pria} wanita={wanita} priaFull={priaFull} wanitaFull={wanitaFull} fotoPria={fotoPria} fotoWanita={fotoWanita} ayahPria={ayahPria} ibuPria={ibuPria} 
            ayahWanita={ayahWanita} ibuWanita={ibuWanita} unduhOnly={unduhOnly} paketID={paketID} islam={islam} /></div>
        
            <div id="acaraUlos"><SectionAcaraUlos visible={visible} tglUnduhS={tglUnduhS} tglUnduhE={tglUnduhE} lokUnduh={lokUnduh} tglAkadS={tglAkadS} tglAkadE={tglAkadE} lokAkad ={lokAkad} tglResepsiS={tglResepsiS}  
            tglResepsiE={tglResepsiE} lokResepsi ={lokResepsi} unduhGmaps={unduhGmaps} akadGmaps={akadGmaps} resepsiGmaps={resepsiGmaps} unduhOnly={unduhOnly} 
            bank1Name={bank1Name} bank1Rek={bank1Rek} bank1An={bank1An} bank2Name={bank2Name} bank2Rek={bank2Rek} bank2An={bank2An} 
            alamatHadiah={alamatHadiah} linkStreaming={linkStreaming} paketID={paketID} islam={islam} plusResepsi={plusResepsi} /></div> 

            <div id="rsvpUlos"><SectionRSVPUlos visible={visible} /></div>

            <div id="galleryUlos"><SectionGalleryUlos visible={visible} foto1={foto1} foto2={foto2} foto3={foto3} foto4={foto4} foto5={foto5} foto6={foto6}
            foto7={foto7} foto8={foto8} foto9={foto9} foto10={foto10} foto11={foto11} foto12={foto12} paketID={paketID} /></div>   

            <SectionFooterUlos audioMempelai={audioMempelai} />
          
          </div>
        </div>

        :

        theme == 'Jawa' ?

        <div className='divCenter bgJawa'>
          <div className='divCenterContent'>
            <div id="headerJawa"><SectionHeaderJawa visible={visible} pria={setPria} wanita={setWanita} priaFull={setPriaFull} wanitaFull={setWanitaFull} tglUnduhS={setTglUnduhS}  
            tglUnduhE={setTglUnduhE} lokUnduh={setLokUnduh} tglAkadS={setTglAkadS} tglAkadE={setTglAkadE} lokAkad ={setLokAkad} tglResepsiS={setTglResepsiS}  
            tglResepsiE={setTglResepsiE} lokResepsi ={setLokResepsi} ayahPria={setAyahPria} ibuPria={setIbuPria} ayahWanita={setAyahWanita} ibuWanita={setIbuWanita}  
            unduhOnly={setUnduhOnly} fotoPria={setFotoPria} fotoWanita={setFotoWanita} unduhGmaps={setUnduhGmaps} akadGmaps={setAkadGmaps} resepsiGmaps={setResepsiGmaps} fotoCover1={setFotoCover1} fotoCover2={setFotoCover2} fotoOpening={setFotoOpening}
            foto1={setFoto1} foto2={setFoto2} foto3={setFoto3} foto4={setFoto4} foto5={setFoto5} foto6={setFoto6} foto7={setFoto7} foto8={setFoto8} foto9={setFoto9} foto10={setFoto10} 
            foto11={setFoto11} foto12={setFoto12} bank1Name={setBank1Name} bank1Rek={setBank1Rek} bank1An={setBank1An} bank2Name={setBank2Name} bank2Rek={setBank2Rek} bank2An={setBank2An} 
            alamatHadiah={setAlamatHadiah} linkStreaming={setLinkStreaming} paketID={setPaketID} islam={setIslam} plusResepsi={setPlusResepsi} fotoShared={setFotoShared} audioMempelai={setAudioMempelai}
            ls_pict_awal={setLs_pict_awal} ls_awal={setLs_awal} ls_pict_mengenal={setLs_pict_mengenal} ls_mengenal={setLs_mengenal} ls_pict_akhir={setLs_pict_akhir} ls_akhir={setLs_akhir}  /></div>


            <div id="mempelaiJawa"><SectionMempelaiJawa visible={visible} pria={pria} wanita={wanita} priaFull={priaFull} wanitaFull={wanitaFull} fotoPria={fotoPria} fotoWanita={fotoWanita} ayahPria={ayahPria} ibuPria={ibuPria} 
            ayahWanita={ayahWanita} ibuWanita={ibuWanita} unduhOnly={unduhOnly} paketID={paketID} islam={islam} ls_pict_awal={ls_pict_awal} ls_awal={ls_awal} ls_pict_mengenal={ls_pict_mengenal} ls_mengenal={ls_mengenal} ls_pict_akhir={ls_pict_akhir} ls_akhir={ls_akhir} /></div>
        
            <div id="acaraJawa"><SectionAcaraJawa visible={visible} tglUnduhS={tglUnduhS} tglUnduhE={tglUnduhE} lokUnduh={lokUnduh} tglAkadS={tglAkadS} tglAkadE={tglAkadE} lokAkad ={lokAkad} tglResepsiS={tglResepsiS}  
            tglResepsiE={tglResepsiE} lokResepsi ={lokResepsi} unduhGmaps={unduhGmaps} akadGmaps={akadGmaps} resepsiGmaps={resepsiGmaps} unduhOnly={unduhOnly} 
            bank1Name={bank1Name} bank1Rek={bank1Rek} bank1An={bank1An} bank2Name={bank2Name} bank2Rek={bank2Rek} bank2An={bank2An} 
            alamatHadiah={alamatHadiah} linkStreaming={linkStreaming} paketID={paketID} islam={islam} plusResepsi={plusResepsi} /></div> 

            <div id="rsvpJawa"><SectionRSVPJawa visible={visible} /></div>

          <div id="galleryJawa"><SectionGalleryJawa visible={visible} foto1={foto1} foto2={foto2} foto3={foto3} foto4={foto4} foto5={foto5} foto6={foto6}
          foto7={foto7} foto8={foto8} foto9={foto9} foto10={foto10} foto11={foto11} foto12={foto12} paketID={paketID} /></div>   

          <SectionFooterJawa audioMempelai={audioMempelai} />
        </div>

        </div>

        :
          theme == 'Premium_Elegant' ?
            <SectionAll visible={visible} fotoShared={setFotoShared} />
        :
          theme == 'Premium_Ulos' ?
            <SectionAllUlos visible={visible} fotoShared={setFotoShared} />
        :
          theme == 'Premium_Jawa' ?
            <SectionAllJawa visible={visible} fotoShared={setFotoShared} />
        :
        theme == 'Blue_Pastel' || theme == 'Yellow_Pastel' || theme == 'Green_Pastel' || theme == 'Pink_Pastel' || theme == 'GreenEl_Pastel' ?
          <div className='divCenter'>
            <div className='pastelTheme divCenterContent'>
              <div id="headerPastel"><SectionHeaderPastel themePage={theme} visible={visible} pria={setPria} wanita={setWanita} priaFull={setPriaFull} wanitaFull={setWanitaFull} tglUnduhS={setTglUnduhS}  
              tglUnduhE={setTglUnduhE} lokUnduh={setLokUnduh} tglAkadS={setTglAkadS} tglAkadE={setTglAkadE} lokAkad ={setLokAkad} tglResepsiS={setTglResepsiS}  
              tglResepsiE={setTglResepsiE} lokResepsi ={setLokResepsi} ayahPria={setAyahPria} ibuPria={setIbuPria} ayahWanita={setAyahWanita} ibuWanita={setIbuWanita}  
              unduhOnly={setUnduhOnly} fotoPria={setFotoPria} fotoWanita={setFotoWanita} unduhGmaps={setUnduhGmaps} akadGmaps={setAkadGmaps} resepsiGmaps={setResepsiGmaps} fotoCover1={setFotoCover1} fotoCover2={setFotoCover2} fotoOpening={setFotoOpening}
              foto1={setFoto1} foto2={setFoto2} foto3={setFoto3} foto4={setFoto4} foto5={setFoto5} foto6={setFoto6} foto7={setFoto7} foto8={setFoto8} foto9={setFoto9} foto10={setFoto10} 
              foto11={setFoto11} foto12={setFoto12} bank1Name={setBank1Name} bank1Rek={setBank1Rek} bank1An={setBank1An} bank2Name={setBank2Name} bank2Rek={setBank2Rek} bank2An={setBank2An} 
              alamatHadiah={setAlamatHadiah} linkStreaming={setLinkStreaming} paketID={setPaketID} islam={setIslam} plusResepsi={setPlusResepsi} fotoShared={setFotoShared} audioMempelai={setAudioMempelai} gmapsIframeAkad={setGmapsIframeAkad} gmapsIframeResepsi={setGmapsIframeResepsi}/></div>

              <div id="mempelaiPastel"><SectionMempelaiPastel themePage={theme} visible={visible} pria={pria} wanita={wanita} priaFull={priaFull} wanitaFull={wanitaFull} fotoPria={fotoPria} fotoWanita={fotoWanita} ayahPria={ayahPria} ibuPria={ibuPria} 
              ayahWanita={ayahWanita} ibuWanita={ibuWanita} unduhOnly={unduhOnly} paketID={paketID} islam={islam} /> </div>
          
              <div id="acaraPastel"><SectionAcaraPastel themePage={theme} visible={visible} tglUnduhS={tglUnduhS} tglUnduhE={tglUnduhE} lokUnduh={lokUnduh} tglAkadS={tglAkadS} tglAkadE={tglAkadE} lokAkad ={lokAkad} tglResepsiS={tglResepsiS}  
              tglResepsiE={tglResepsiE} lokResepsi ={lokResepsi} unduhGmaps={unduhGmaps} akadGmaps={akadGmaps} resepsiGmaps={resepsiGmaps} fotoCover2={fotoCover2} 
              unduhOnly={unduhOnly} bank1Name={bank1Name} bank1Rek={bank1Rek} bank1An={bank1An} bank2Name={bank2Name} bank2Rek={bank2Rek} bank2An={bank2An} 
              alamatHadiah={alamatHadiah} linkStreaming={linkStreaming} paketID={paketID} islam={islam} plusResepsi={plusResepsi} foto1={foto1} gmapsIframeAkad={gmapsIframeAkad} gmapsIframeResepsi={gmapsIframeResepsi}/> </div>
              {
                 foto1 != '' &&
                 <div id="galleryPastel"><SectionGalleryPastel themePage={theme} visible={visible} foto1={foto1} foto2={foto2} foto3={foto3} foto4={foto4} foto5={foto5} foto6={foto6}
                 foto7={foto7} foto8={foto8} foto9={foto9} foto10={foto10} foto11={foto11} foto12={foto12} paketID={paketID} /> </div>  
              }
                
              <div id="rsvpPastel" style={{marginTop: pathParts[1] == 'amirahdidi' ? 350 : 0}}><SectionRSVPPastel themePage={theme} visible={visible} /></div>
              {/*  <div><SectionStory /></div>    */} 
           

              <SectionFooterPastel themePage={theme} audioMempelai={audioMempelai}/>
            </div>
          </div>
        :

          theme == 'Minang' ?

          <div className='divCenter bgMinang'>
          <div className='divCenterContent'>
            <div id="headerMinang"><SectionHeaderMinang visible={visible} pria={setPria} wanita={setWanita} priaFull={setPriaFull} wanitaFull={setWanitaFull} tglUnduhS={setTglUnduhS}  
            tglUnduhE={setTglUnduhE} lokUnduh={setLokUnduh} tglAkadS={setTglAkadS} tglAkadE={setTglAkadE} lokAkad ={setLokAkad} tglResepsiS={setTglResepsiS}  
            tglResepsiE={setTglResepsiE} lokResepsi ={setLokResepsi} ayahPria={setAyahPria} ibuPria={setIbuPria} ayahWanita={setAyahWanita} ibuWanita={setIbuWanita}  
            unduhOnly={setUnduhOnly} fotoPria={setFotoPria} fotoWanita={setFotoWanita} unduhGmaps={setUnduhGmaps} akadGmaps={setAkadGmaps} resepsiGmaps={setResepsiGmaps} fotoCover1={setFotoCover1} fotoCover2={setFotoCover2} fotoOpening={setFotoOpening}
            foto1={setFoto1} foto2={setFoto2} foto3={setFoto3} foto4={setFoto4} foto5={setFoto5} foto6={setFoto6} foto7={setFoto7} foto8={setFoto8} foto9={setFoto9} foto10={setFoto10} 
            foto11={setFoto11} foto12={setFoto12} bank1Name={setBank1Name} bank1Rek={setBank1Rek} bank1An={setBank1An} bank2Name={setBank2Name} bank2Rek={setBank2Rek} bank2An={setBank2An} 
            alamatHadiah={setAlamatHadiah} linkStreaming={setLinkStreaming} paketID={setPaketID} islam={setIslam} plusResepsi={setPlusResepsi} fotoShared={setFotoShared} audioMempelai={setAudioMempelai} /></div>

            <div id="mempelaiMinang"><SectionMempelaiMinang visible={visible} pria={pria} wanita={wanita} priaFull={priaFull} wanitaFull={wanitaFull} fotoPria={fotoPria} fotoWanita={fotoWanita} ayahPria={ayahPria} ibuPria={ibuPria} 
            ayahWanita={ayahWanita} ibuWanita={ibuWanita} unduhOnly={unduhOnly} paketID={paketID} islam={islam} /></div>

            <div id="acaraMinang"><SectionAcaraMinang visible={visible} tglUnduhS={tglUnduhS} tglUnduhE={tglUnduhE} lokUnduh={lokUnduh} tglAkadS={tglAkadS} tglAkadE={tglAkadE} lokAkad ={lokAkad} tglResepsiS={tglResepsiS}  
            tglResepsiE={tglResepsiE} lokResepsi ={lokResepsi} unduhGmaps={unduhGmaps} akadGmaps={akadGmaps} resepsiGmaps={resepsiGmaps} unduhOnly={unduhOnly} 
            bank1Name={bank1Name} bank1Rek={bank1Rek} bank1An={bank1An} bank2Name={bank2Name} bank2Rek={bank2Rek} bank2An={bank2An} 
            alamatHadiah={alamatHadiah} linkStreaming={linkStreaming} paketID={paketID} islam={islam} plusResepsi={plusResepsi} /></div> 

            <div id="rsvpMinang"><SectionRSVPMinang visible={visible} /></div>

            <div id="galleryMinang"><SectionGalleryMinang visible={visible} foto1={foto1} foto2={foto2} foto3={foto3} foto4={foto4} foto5={foto5} foto6={foto6}
            foto7={foto7} foto8={foto8} foto9={foto9} foto10={foto10} foto11={foto11} foto12={foto12} paketID={paketID} /></div>   

            <SectionFooterMinang audioMempelai={audioMempelai} />

          </div>
          </div>
        :
        theme == 'Classic_Blue' || theme == 'Classic_Green' || theme == 'Classic_Orange' || theme == 'Classic_Red' ?
          <div className='divCenter'>
            <div className='ClassicTheme divCenterContent'>
              <div id="headerClassic"><SectionHeaderClassic themePage={theme} visible={visible} pria={setPria} wanita={setWanita} priaFull={setPriaFull} wanitaFull={setWanitaFull} tglUnduhS={setTglUnduhS}  
              tglUnduhE={setTglUnduhE} lokUnduh={setLokUnduh} tglAkadS={setTglAkadS} tglAkadE={setTglAkadE} lokAkad ={setLokAkad} tglResepsiS={setTglResepsiS}  
              tglResepsiE={setTglResepsiE} lokResepsi ={setLokResepsi} ayahPria={setAyahPria} ibuPria={setIbuPria} ayahWanita={setAyahWanita} ibuWanita={setIbuWanita}  
              unduhOnly={setUnduhOnly} fotoPria={setFotoPria} fotoWanita={setFotoWanita} unduhGmaps={setUnduhGmaps} akadGmaps={setAkadGmaps} resepsiGmaps={setResepsiGmaps} fotoCover1={setFotoCover1} fotoCover2={setFotoCover2} fotoOpening={setFotoOpening}
              foto1={setFoto1} foto2={setFoto2} foto3={setFoto3} foto4={setFoto4} foto5={setFoto5} foto6={setFoto6} foto7={setFoto7} foto8={setFoto8} foto9={setFoto9} foto10={setFoto10} 
              foto11={setFoto11} foto12={setFoto12} bank1Name={setBank1Name} bank1Rek={setBank1Rek} bank1An={setBank1An} bank2Name={setBank2Name} bank2Rek={setBank2Rek} bank2An={setBank2An} 
              alamatHadiah={setAlamatHadiah} linkStreaming={setLinkStreaming} paketID={setPaketID} islam={setIslam} plusResepsi={setPlusResepsi} fotoShared={setFotoShared} audioMempelai={setAudioMempelai} gmapsIframeResepsi={setGmapsIframeResepsi}/></div>

              <div id="mempelaiClassic"><SectionMempelaiClassic themePage={theme} visible={visible} pria={pria} wanita={wanita} priaFull={priaFull} wanitaFull={wanitaFull} fotoPria={fotoPria} fotoWanita={fotoWanita} ayahPria={ayahPria} ibuPria={ibuPria} 
              ayahWanita={ayahWanita} ibuWanita={ibuWanita} unduhOnly={unduhOnly} paketID={paketID} islam={islam} /> </div>
          
              <div id="acaraClassic"><SectionAcaraClassic themePage={theme} visible={visible} pria={pria} wanita={wanita} tglUnduhS={tglUnduhS} tglUnduhE={tglUnduhE} lokUnduh={lokUnduh} tglAkadS={tglAkadS} tglAkadE={tglAkadE} lokAkad ={lokAkad} tglResepsiS={tglResepsiS}  
              tglResepsiE={tglResepsiE} lokResepsi ={lokResepsi} unduhGmaps={unduhGmaps} akadGmaps={akadGmaps} resepsiGmaps={resepsiGmaps} fotoCover2={fotoCover2} 
              unduhOnly={unduhOnly} bank1Name={bank1Name} bank1Rek={bank1Rek} bank1An={bank1An} bank2Name={bank2Name} bank2Rek={bank2Rek} bank2An={bank2An} 
              alamatHadiah={alamatHadiah} linkStreaming={linkStreaming} paketID={paketID} islam={islam} plusResepsi={plusResepsi} gmapsIframeResepsi={gmapsIframeResepsi}/> </div>
                
              <div id="rsvpClassic"><SectionRSVPClassic themePage={theme} visible={visible} /></div>

              <div id="galleryClassic"><SectionGalleryClassic themePage={theme} visible={visible} foto1={foto1} foto2={foto2} foto3={foto3} foto4={foto4} foto5={foto5} foto6={foto6}
              foto7={foto7} foto8={foto8} foto9={foto9} foto10={foto10} foto11={foto11} foto12={foto12} paketID={paketID} /> </div>  
              {/*  <div><SectionStory /></div>    */} 
            

              <SectionFooterClassic themePage={theme} audioMempelai={audioMempelai}/>
            </div>
          </div>
        :
          ''
        }

        {
          (theme != 'Premium_Elegant' && theme != 'Premium_Ulos' && theme != 'Premium_Jawa') &&
          <div className={(theme == 'Elegant' || theme == 'Elegant2') ? (cs_color == 'Green' ? "bottomNav TElegantGreen" : "bottomNav TElegant") : theme == 'Ulos' ? "bottomNav TUlos" : theme == 'Jawa' ? "bottomNav TJawa" :
          theme == 'Blue_Pastel' ? "bottomNav TBluePastel" : theme == 'Yellow_Pastel' ? "bottomNav TYellowPastel" : theme == 'Green_Pastel' ? "bottomNav TGreenPastel"  : theme == 'GreenEl_Pastel' ? "bottomNav TGreenElPastel" 
          : theme == 'Pink_Pastel' ? "bottomNav TPinkPastel": theme == 'Minang' ? "bottomNav TMinang" : theme == 'Classic_Blue' ? "bottomNav TClassicBlue" :
          theme == 'Classic_Green' ? "bottomNav TClassicGreen" : theme == 'Classic_Orange' ? "bottomNav TClassicOrange" : theme == 'Classic_Red' ? "bottomNav TClassicRed" : ""}>
            <BottomNavigation showLabels sx={{ width: 500 }} value={valueMenus} onChange={handleChangeMenus}>
                <BottomNavigationAction
                  label="Home" 
                  as={Link}
                  activeClass="active"
                  to={theme == 'Elegant' ? "headerElegant" : theme == 'Elegant2' ? "headerElegant2" : theme == 'Ulos' ? "headerUlos" : theme == 'Jawa' ? "headerJawa" : 
                  (theme == 'Blue_Pastel' || theme == 'Yellow_Pastel' || theme == 'Green_Pastel' || theme == 'GreenEl_Pastel' || theme == 'Pink_Pastel') ? "headerPastel" :
                  (theme == 'Classic_Blue' || theme == 'Classic_Green' || theme == 'Classic_Orange' || theme == 'Classic_Red') ? "headerClassic"
                  : theme == 'Minang' ? "headerMinang" : ""}
                  spy={true}
                  smooth={true}
                  icon={<HomeOutlinedIcon />}
                >
                </BottomNavigationAction>
              <BottomNavigationAction
                  label="Mempelai" 
                  as={Link}
                  activeClass="active"
                  to={theme == 'Elegant' ? "mempelaiElegant" : theme == 'Elegant2' ? "mempelaiElegant2" : theme == 'Ulos' ? "mempelaiUlos" : theme == 'Jawa' ? "mempelaiJawa" : 
                  (theme == 'Blue_Pastel' || theme == 'Yellow_Pastel' || theme == 'Green_Pastel' || theme == 'GreenEl_Pastel' || theme == 'Pink_Pastel') ? "mempelaiPastel"  :
                  (theme == 'Classic_Blue' || theme == 'Classic_Green' || theme == 'Classic_Orange' || theme == 'Classic_Red') ? "mempelaiClassic"
                  : theme == 'Minang' ? "mempelaiMinang" : ""}
                  spy={true}
                  smooth={true}
                icon={<SupervisedUserCircleOutlinedIcon />}
              />
              <BottomNavigationAction
                  label="Acara" 
                  as={Link}
                  activeClass="active"
                  to={theme == 'Elegant' ? "acaraElegant" : theme == 'Elegant2' ? "acaraElegant2" : theme == 'Ulos' ? "acaraUlos" : theme == 'Jawa' ? "acaraJawa" : 
                  (theme == 'Blue_Pastel' || theme == 'Yellow_Pastel' || theme == 'Green_Pastel' || theme == 'GreenEl_Pastel' || theme == 'Pink_Pastel') ? "acaraPastel"  :
                  (theme == 'Classic_Blue' || theme == 'Classic_Green' || theme == 'Classic_Orange' || theme == 'Classic_Red') ? "acaraClassic"
                  : theme == 'Minang' ? "acaraMinang" : ""}
                  spy={true}
                  smooth={true}
                icon={<CalendarMonthOutlinedIcon />}
              />
              <BottomNavigationAction
                  label="Rsvp" 
                  as={Link}
                  activeClass="active"
                  to={theme == 'Elegant' ? "rsvpElegant" : theme == 'Elegant2' ? "rsvpElegant2" : theme == 'Ulos' ? "rsvpUlos" : theme == 'Jawa' ? "rsvpJawa" : 
                  (theme == 'Blue_Pastel' || theme == 'Yellow_Pastel' || theme == 'Green_Pastel' || theme == 'GreenEl_Pastel' || theme == 'Pink_Pastel') ? "rsvpPastel"  :
                  (theme == 'Classic_Blue' || theme == 'Classic_Green' || theme == 'Classic_Orange' || theme == 'Classic_Red') ? "rsvpClassic"
                  : theme == 'Minang' ? "rsvpMinang" : ""}
                  spy={true}
                  smooth={true}
                icon={<MenuBookOutlinedIcon />}
              />
              {
                foto1 != '' &&
                <BottomNavigationAction
                    label="Foto" 
                    as={Link}
                    activeClass="active"
                    to={theme == 'Elegant' ? "galleryElegant" :  theme == 'Elegant2' ? "galleryElegant2" : theme == 'Ulos' ? "galleryUlos" : theme == 'Jawa' ? "galleryJawa" : 
                    (theme == 'Blue_Pastel' || theme == 'Yellow_Pastel' || theme == 'Green_Pastel' || theme == 'GreenEl_Pastel' || theme == 'Pink_Pastel') ? "galleryPastel"  :
                    (theme == 'Classic_Blue' || theme == 'Classic_Green' || theme == 'Classic_Orange' || theme == 'Classic_Red') ? "galleryClassic"
                    : theme == 'Minang' ? "galleryMinang" : ""}
                    spy={true}
                    smooth={true}
                  icon={<CollectionsOutlinedIcon />}
                />
              }
            </BottomNavigation>
          </div>
        }
       
      </div>
      // </Fullscreen> 
    : ''

}
    </div>
  );
}

export default App;
