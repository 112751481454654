import * as React from 'react';
import { useTheme, useMediaQuery } from '@material-ui/core';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import { CardActionArea } from '@mui/material';
import { AnimationOnScroll } from 'react-animation-on-scroll';

import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import InstagramIcon from '@mui/icons-material/Instagram';
import { Timeline, TimelineConnector, TimelineContent, TimelineDot, TimelineOppositeContent, TimelineSeparator } from '@mui/lab';
import TimelineItem, { timelineItemClasses } from '@mui/lab/TimelineItem';
import { FavoriteOutlined, MoodOutlined, MosqueOutlined } from '@mui/icons-material';

const Item = styled(Paper)(({ theme }) => ({
    padding: theme.spacing(0.25),
    color: theme.palette.text.secondary,
  }));
  
  
const SectionMempelaiPastel = (props) => {
    const theme = useTheme();
    const mobile = useMediaQuery(theme.breakpoints.down('xs'));
    const tablet = useMediaQuery(theme.breakpoints.down('sm'));

    const pathParts = window.location.pathname.split('/'); 

  return (
    <div className='Section sec-pastel' style={{paddingBottom: 60, background: props.themePage == 'GreenEl_Pastel' ? 'rgba(15, 118, 110, 1)': '#fff'}}>
            <Box>
                <center style={{color: props.themePage == 'GreenEl_Pastel' ? '#fff': '#000'}}>  
                    <AnimationOnScroll animateIn="animate__fadeInDown" initiallyVisible={true}>
                        <Typography variant="h6" pt={4} gutterBottom >السَّلاَمُ عَلَيْكُمْ وَرَحْمَةُ اللهِ وَبَرَكَاتُهُ</Typography>
                    </AnimationOnScroll>
                    <AnimationOnScroll animateIn="animate__fadeInDown" initiallyVisible={true}>
                        <Typography variant="body1" className="font-kulim font-thin" mx={4} mb={2}>
                            Maha Suci Allah yang telah menciptakan makhluk-Nya berpasang-pasangan
                        </Typography>
                    </AnimationOnScroll>
                        <img src={require('../../images/bg_pastel/ornament-divider.png')} className="img-ornament-divider" width={120} style={{filter: props.themePage == 'GreenEl_Pastel' ? 'invert(100%)': 'invert(0%)'}} />
                </center>
        
                <Grid container 
                    direction="row"
                    justifyContent="center"
                    alignItems="center" 
                    spacing={2} mt={4} px={2} mb={4}
                >
                {
                    pathParts[1] == 'nagasuryana' || pathParts[1] == 'amirahdidi' &&
                    <Grid item xs={12}>
                        <AnimationOnScroll animateIn="animate__fadeInDown">
                        <Card sx={{ display: 'flex', background: props.themePage == 'GreenEl_Pastel' ? 'rgb(13, 148, 136)': '#fff' }}>
                            <CardMedia
                                component="div"
                                sx={{ width: 151 }}
                                style={{backgroundImage: `url("data:image/*;base64,${props.fotoPria}")`, backgroundSize: 'cover', backgroundPosition: 'center' }}
                                alt="mempelai pria"
                            />
                            <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                                <CardContent sx={{ flex: '1 0 auto' }}>
                                    <center>
                                        <Typography component="div" variant="h5" className='font-jost' style={{color: props.themePage == 'GreenEl_Pastel' ? '#fff': '#000'}}>
                                            {props.pria}    
                                        </Typography>
                                        <Divider sx={{my: 1}}/>
                                        <Typography variant="body2" color="text.secondary" component="div" style={{color: props.themePage == 'GreenEl_Pastel' ? '#fff': '#000'}}>
                                            {props.priaFull}
                                        </Typography>
                                        <Typography variant="body2" className="font-jost" style={{color: props.themePage == 'GreenEl_Pastel' ? '#fff': '#000'}} mt={2} mb={.5}> Putra dari </Typography>
                                        <Typography variant="caption" className="font-jost font-thin" style={{color: props.themePage == 'GreenEl_Pastel' ? '#fff': '#000'}} >Bapak {props.ayahPria} &</Typography>
                                        <Typography variant="caption" className="font-jost font-thin" style={{color: props.themePage == 'GreenEl_Pastel' ? '#fff': '#000'}} >Ibu {props.ibuPria}</Typography>
                                        {/* <br />
                                        <IconButton aria-label="instagram" sx={{fontSize: 14, color: '#c36'}}>
                                            <InstagramIcon sx={{fontSize: 14}}/> <Typography variant="caption" ml={.5} mt={.25} className="font-jost font-thin" >namaig</Typography>
                                        </IconButton> */}
                                    </center>
                                </CardContent>
                                <Box sx={{ display: 'flex', alignItems: 'center', pl: 1, pb: 1 }}>

                                </Box>
                            </Box>
                        </Card>
                        </AnimationOnScroll>
                    </Grid>
                }
                    <Grid item xs={12}>
                        <AnimationOnScroll animateIn={pathParts[1] == 'nagasuryana' || pathParts[1] == 'amirahdidi' ? "animate__fadeInUp" : "animate__fadeInDown"}>
                        <Card sx={{ display: 'flex', background: props.themePage == 'GreenEl_Pastel' ? 'rgb(13, 148, 136)': '#fff' }}>
                            <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                                <CardContent sx={{ flex: '1 0 auto' }}>
                                    <center>
                                        <Typography component="div" variant="h5" className='font-jost' style={{color: props.themePage == 'GreenEl_Pastel' ? '#fff': '#000'}}>
                                            {props.wanita}    
                                        </Typography>
                                        <Divider sx={{my: 1}}/>
                                        <Typography variant="body2" color="text.secondary" component="div" style={{color: props.themePage == 'GreenEl_Pastel' ? '#fff': '#000'}}>
                                            {props.wanitaFull}
                                        </Typography>
                                        <Typography variant="body2" className="font-jost" style={{color: props.themePage == 'GreenEl_Pastel' ? '#fff': '#000'}} mt={2} mb={.5}> Putri dari </Typography>
                                        <Typography variant="caption" className="font-jost font-thin" style={{color: props.themePage == 'GreenEl_Pastel' ? '#fff': '#000'}} >Bapak {props.ayahWanita} &</Typography>
                                        <Typography variant="caption" className="font-jost font-thin" style={{color: props.themePage == 'GreenEl_Pastel' ? '#fff': '#000'}} >Ibu {props.ibuWanita}</Typography>
                                        {/* <br />
                                        <IconButton aria-label="instagram" sx={{fontSize: 14, color: '#c36'}}>
                                            <InstagramIcon sx={{fontSize: 14}}/> <Typography variant="caption" ml={.5} mt={.25} className="font-jost font-thin" >namaig</Typography>
                                        </IconButton> */}
                                    </center>
                                </CardContent>
                            </Box>
                            <CardMedia
                                component="div"
                                sx={{ width: 151 }}
                                style={{backgroundImage: `url("data:image/*;base64,${props.fotoWanita}")`, backgroundSize: 'cover', backgroundPosition: 'center' }}
                                alt="mempelai wanita"
                            />
                        </Card>
                        </AnimationOnScroll>
                    </Grid>
                {
                    pathParts[1] != 'nagasuryana' || pathParts[1] != 'amirahdidi' &&
                        <Grid item xs={12}>
                            <AnimationOnScroll animateIn="animate__fadeInUp">
                            <Card sx={{ display: 'flex', background: props.themePage == 'GreenEl_Pastel' ? 'rgb(13, 148, 136)': '#fff' }}>
                                <CardMedia
                                    component="div"
                                    sx={{ width: 151 }}
                                    style={{backgroundImage: `url("data:image/*;base64,${props.fotoPria}")`, backgroundSize: 'cover', backgroundPosition: 'center' }}
                                    alt="mempelai pria"
                                />
                                <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                                    <CardContent sx={{ flex: '1 0 auto' }}>
                                        <center>
                                            <Typography component="div" variant="h5" className='font-jost' style={{color: props.themePage == 'GreenEl_Pastel' ? '#fff': '#000'}}>
                                                {props.pria}    
                                            </Typography>
                                            <Divider sx={{my: 1}}/>
                                            <Typography variant="body2" color="text.secondary" component="div" style={{color: props.themePage == 'GreenEl_Pastel' ? '#fff': '#000'}}>
                                                {props.priaFull}
                                            </Typography>
                                            <Typography variant="body2" className="font-jost" style={{color: props.themePage == 'GreenEl_Pastel' ? '#fff': '#000'}} mt={2} mb={.5}> Putra dari </Typography>
                                            <Typography variant="caption" className="font-jost font-thin" style={{color: props.themePage == 'GreenEl_Pastel' ? '#fff': '#000'}} >Bapak {props.ayahPria} &</Typography>
                                            <Typography variant="caption" className="font-jost font-thin" style={{color: props.themePage == 'GreenEl_Pastel' ? '#fff': '#000'}} >Ibu {props.ibuPria}</Typography>
                                            {/* <br />
                                            <IconButton aria-label="instagram" sx={{fontSize: 14, color: '#c36'}}>
                                                <InstagramIcon sx={{fontSize: 14}}/> <Typography variant="caption" ml={.5} mt={.25} className="font-jost font-thin" >namaig</Typography>
                                            </IconButton> */}
                                        </center>
                                    </CardContent>
                                    <Box sx={{ display: 'flex', alignItems: 'center', pl: 1, pb: 1 }}>

                                    </Box>
                                </Box>
                            </Card>
                            </AnimationOnScroll>
                        </Grid>
                }
                {
                    pathParts[1] == 'tyasarif' &&
                        <Grid item xs={12}>
                            <AnimationOnScroll animateIn="animate__fadeInUp">
                            <Card sx={{ display: 'flex', background: props.themePage == 'GreenEl_Pastel' ? 'rgb(13, 148, 136)': '#fff' }}>
                                <CardMedia
                                    component="div"
                                    sx={{ width: 151 }}
                                    style={{backgroundImage: `url("data:image/*;base64,${props.fotoPria}")`, backgroundSize: 'cover', backgroundPosition: 'center' }}
                                    alt="mempelai pria"
                                />
                                <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                                    <CardContent sx={{ flex: '1 0 auto' }}>
                                        <center>
                                            <Typography component="div" variant="h5" className='font-jost' style={{color: props.themePage == 'GreenEl_Pastel' ? '#fff': '#000'}}>
                                                {props.pria}    
                                            </Typography>
                                            <Divider sx={{my: 1}}/>
                                            <Typography variant="body2" color="text.secondary" component="div" style={{color: props.themePage == 'GreenEl_Pastel' ? '#fff': '#000'}}>
                                                {props.priaFull}
                                            </Typography>
                                            <Typography variant="body2" className="font-jost" style={{color: props.themePage == 'GreenEl_Pastel' ? '#fff': '#000'}} mt={2} mb={.5}> Putra dari </Typography>
                                            <Typography variant="caption" className="font-jost font-thin" style={{color: props.themePage == 'GreenEl_Pastel' ? '#fff': '#000'}} >Bapak {props.ayahPria} &</Typography>
                                            <Typography variant="caption" className="font-jost font-thin" style={{color: props.themePage == 'GreenEl_Pastel' ? '#fff': '#000'}} >Ibu {props.ibuPria}</Typography>
                                            {/* <br />
                                            <IconButton aria-label="instagram" sx={{fontSize: 14, color: '#c36'}}>
                                                <InstagramIcon sx={{fontSize: 14}}/> <Typography variant="caption" ml={.5} mt={.25} className="font-jost font-thin" >namaig</Typography>
                                            </IconButton> */}
                                        </center>
                                    </CardContent>
                                    <Box sx={{ display: 'flex', alignItems: 'center', pl: 1, pb: 1 }}>

                                    </Box>
                                </Box>
                            </Card>
                            </AnimationOnScroll>
                        </Grid>
            }   
                    
                </Grid>
                <svg style={{ position: 'absolute', bottom: 0, width: '100%' }} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
                    <path fill={props.themePage == 'Blue_Pastel' ? "#00425A" : props.themePage == 'Yellow_Pastel' ? '#F2CD5C' 
                    : props.themePage == 'Green_Pastel' ? '#4e6c50' : props.themePage == 'GreenEl_Pastel' ? '#fff' : props.themePage == 'Pink_Pastel' ? 'rgba(247, 200, 224, 1)': '' } fill-opacity="1" d="M0,256L26.7,234.7C53.3,213,107,171,160,149.3C213.3,128,267,128,320,112C373.3,96,427,64,480,90.7C533.3,117,587,203,640,218.7C693.3,235,747,181,800,133.3C853.3,85,907,43,960,53.3C1013.3,64,1067,128,1120,144C1173.3,160,1227,128,1280,117.3C1333.3,107,1387,117,1413,122.7L1440,128L1440,320L1413.3,320C1386.7,320,1333,320,1280,320C1226.7,320,1173,320,1120,320C1066.7,320,1013,320,960,320C906.7,320,853,320,800,320C746.7,320,693,320,640,320C586.7,320,533,320,480,320C426.7,320,373,320,320,320C266.7,320,213,320,160,320C106.7,320,53,320,27,320L0,320Z"></path>
                </svg>
            </Box>
            {/* { props.unduhOnly == 0 && 
                <div className='bgAyat'>
                    <Box>
                        <center>
                        <AnimationOnScroll animateIn="animate__slideInDown">
                            <Typography variant="body1" pt={2} mx={2} className='font-kulim font-thin' style={{width:'85%'}}>
                            {props.islam == 1 ? '" Dan di antara tanda-tanda (kebesaran)-Nya ialah Dia menciptakan pasangan-pasangan untukmu dari jenismu sendiri, agar kamu cenderung dan merasa tenteram kepadanya, dan Dia menjadikan di antaramu rasa kasih dan sayang. Sungguh, pada demikian itu benar-benar terdapat tanda-tanda (kebesaran Allah) bagi kaum yang berpikir. "'
                            : '"Sebab pada awal dunia, Allah menjadikan mereka laki-laki dan perempuan, sebab itu laki-laki akan meninggalkan ayahnya dan ibunya dan bersatu dengan isterinya, sehingga keduanya itu menjadi satu daging. Demikianlah mereka bukan lagi dua, melainkan satu. Karena itu, apa yang telah dipersatukan Allah, tidak boleh diceraikan manusia."'}
                            </Typography>            
                        </AnimationOnScroll>
                        <AnimationOnScroll animateIn="animate__slideInUp">
                            <Typography variant="body1" pb={2} mt={2} mx={2} className="font-kulim ">
                            {props.islam == 1 ? '( QS. Ar-Rum ayat 21 )' : '( Markus 10:6-9 )'}</Typography>
                        </AnimationOnScroll>
                        </center> 

                    </Box>
                </div>
            } */}
            
            {
                    pathParts[1] == 'amirahdidi' &&
                    <>
                        <center>  
                            <AnimationOnScroll animateIn="animate__fadeInDown" initiallyVisible={true}>
                                <Typography variant="h3" gutterBottom className='font-curly' style={{color: props.themePage == 'GreenEl_Pastel' ? '#fff': '#000', fontWeight: props.themePage == 'GreenEl_Pastel' ? 'bold': 'normal',}}>Love Story</Typography>
                            </AnimationOnScroll>
                        </center>   
                        <Timeline position="alternate">
                        <TimelineItem>
                            <TimelineOppositeContent>
                                <Typography component="div" variant="body2" className='font-HelveticaB' sx={{mt: 2}} style={{color: props.themePage == 'GreenEl_Pastel' ? '#fff': '#000'}}>
                                    02 Okt 2023
                                </Typography>
                            </TimelineOppositeContent>
                            <TimelineSeparator>
                                <TimelineDot sx={{background: '#EAE7B1', color: '#4e6c50'}}>
                                    <MoodOutlined />
                                </TimelineDot>
                                <TimelineConnector />
                            </TimelineSeparator>
                            <TimelineContent>
                                <Typography component="div" variant="body2" className='font-jost' sx={{mt: 2}} style={{color: props.themePage == 'GreenEl_Pastel' ? '#fff': '#000'}}>
                                    The first time we meet
                                </Typography>
                            </TimelineContent>
                        </TimelineItem>
                        <TimelineItem>
                            <TimelineOppositeContent>
                                <Typography component="div" variant="body2" className='font-HelveticaB' sx={{mt: 2}} style={{color: props.themePage == 'GreenEl_Pastel' ? '#fff': '#000'}}>
                                    29 Okt 2023
                                </Typography>
                            </TimelineOppositeContent>
                            <TimelineSeparator>
                            <TimelineDot sx={{background: '#EAE7B1', color: '#4e6c50'}}>
                                <FavoriteOutlined />
                            </TimelineDot>
                            <TimelineConnector />
                            </TimelineSeparator>
                            <TimelineContent>
                                <Typography component="div" variant="body2" className='font-jost' sx={{mt: 2}} style={{color: props.themePage == 'GreenEl_Pastel' ? '#fff': '#000'}}>
                                    Engagement
                                </Typography>
                            </TimelineContent>
                        </TimelineItem>
                        <TimelineItem>
                            <TimelineOppositeContent>
                                <Typography component="div" variant="body2" className='font-HelveticaB' sx={{mt: 2}} style={{color: props.themePage == 'GreenEl_Pastel' ? '#fff': '#000'}}>
                                    02 Feb 2024
                                </Typography>
                            </TimelineOppositeContent>
                            <TimelineSeparator>
                            <TimelineDot sx={{background: '#EAE7B1', color: '#4e6c50'}}>
                                <MosqueOutlined />
                            </TimelineDot>
                            </TimelineSeparator>
                            <TimelineContent>
                                <Typography component="div" variant="body2" className='font-jost' sx={{mt: 2}} style={{color: props.themePage == 'GreenEl_Pastel' ? '#fff': '#000'}}>
                                    Wedding day
                                </Typography>
                            </TimelineContent>
                        </TimelineItem>
                        </Timeline>
        
                    </>
            }
    </div>
  );
}

export default SectionMempelaiPastel;
