import * as React from 'react';
import { useTheme, useMediaQuery } from '@material-ui/core';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { AnimationOnScroll } from 'react-animation-on-scroll';

import AudiotrackIcon from '@mui/icons-material/Audiotrack';
import PhonelinkIcon from '@mui/icons-material/Phonelink';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import AccessAlarmsIcon from '@mui/icons-material/AccessAlarms';
import MarkUnreadChatAltIcon from '@mui/icons-material/MarkUnreadChatAlt';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import LiveTvIcon from '@mui/icons-material/LiveTv';
import CardGiftcardIcon from '@mui/icons-material/CardGiftcard';
import ContactPageIcon from '@mui/icons-material/ContactPage';

const Item = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(1),
  height: 100
}));

const Fitur = (props) =>  {
    const theme = useTheme();
    const mobile = useMediaQuery(theme.breakpoints.down('xs'));

    React.useEffect(() => {
        
    }, []);
    
  return ( 
    <div className='section' id='fitur'>

      <div className='sectionBackground'>

        <Container>
            {/* <center>
                <Typography variant={mobile ? "h5" : "h4"} className="font-jost">Mengapa memilih momentkami ?</Typography>
                <Typography variant={mobile ? "body1" : "h6"} width={mobile ? '100%' : '80%'} mt={2} className="font-jost font-thin">Kami hadir sebagai sebuah solusi untuk membantu calon mempelai yang berbahagia untuk membuat undangan pernikahan online dengan cepat dan mudah.</Typography>                
            </center> */}
            
            <Grid container 
                spacing={2}>
                <Grid item xs={12} md={4}>
                    <Item>
                        <Grid container >
                            <Grid item xs={2} md={2}>
                                <AnimationOnScroll animateIn="animate__zoomIn" >
                                  <AudiotrackIcon sx={{ fontSize: 40, mt: 2.75, color: '#6750a4' }} />
                                </AnimationOnScroll>
                            </Grid>
                            <Grid item xs={10} md={10}>
                                  <AnimationOnScroll animateIn="animate__zoomIn" >
                                      <Typography variant={mobile ? "h6" : "h5"} gutterBottom className="font-jost">
                                        Autoplay Backsound             
                                      </Typography>
                                      <Typography variant={mobile ? "body2" : "body1"} className="font-jost font-thin">
                                        Tambahan musik untuk membuat moment lebih asik
                                      </Typography>
                                  </AnimationOnScroll>
                            </Grid>
                        </Grid>
                    </Item>
                </Grid>
                <Grid item xs={12} md={4}>
                    <Item>
                        <Grid container >
                            <Grid item xs={2} md={2}>
                                <AnimationOnScroll animateIn="animate__zoomIn" >
                                  <PhonelinkIcon sx={{ fontSize: 40, mt: 2.75, color: '#6750a4' }} />
                                </AnimationOnScroll>
                            </Grid>
                            <Grid item xs={10} md={10}>
                                  <AnimationOnScroll animateIn="animate__zoomIn" >
                                      <Typography variant={mobile ? "h6" : "h5"} gutterBottom className="font-jost">
                                        Responsive Design           
                                      </Typography>
                                      <Typography variant={mobile ? "body2" : "body1"} className="font-jost font-thin">
                                        Tampilan dinamis untuk semua perangkat
                                      </Typography>
                                  </AnimationOnScroll>
                            </Grid>
                        </Grid>
                    </Item>
                </Grid>
                <Grid item xs={12} md={4}>
                    <Item>
                        <Grid container >
                            <Grid item xs={2} md={2}>
                                <AnimationOnScroll animateIn="animate__zoomIn" >
                                  <LocationOnIcon sx={{ fontSize: 40, mt: 2.75, color: '#6750a4' }} />
                                </AnimationOnScroll>
                            </Grid>
                            <Grid item xs={10} md={10}>
                                  <AnimationOnScroll animateIn="animate__zoomIn" >
                                      <Typography variant={mobile ? "h6" : "h5"} gutterBottom className="font-jost">
                                        Google Maps          
                                      </Typography>
                                      <Typography variant={mobile ? "body2" : "body1"} className="font-jost font-thin">
                                        Berikan kemudahan tamu anda untuk mencari lokasi acara
                                      </Typography>
                                  </AnimationOnScroll>
                            </Grid>
                        </Grid>
                    </Item>
                </Grid>
                <Grid item xs={12} md={4}>
                    <Item>
                        <Grid container >
                            <Grid item xs={2} md={2}>
                                <AnimationOnScroll animateIn="animate__zoomIn" >
                                  <AccessAlarmsIcon sx={{ fontSize: 40, mt: 2.75, color: '#6750a4' }} />
                                </AnimationOnScroll>
                            </Grid>
                            <Grid item xs={10} md={10}>
                                  <AnimationOnScroll animateIn="animate__zoomIn" >
                                      <Typography variant={mobile ? "h6" : "h5"} gutterBottom className="font-jost">
                                        Countdown Acara          
                                      </Typography>
                                      <Typography variant={mobile ? "body2" : "body1"} className="font-jost font-thin">
                                        Hitung mundur untuk mengingatkan tamu anda kapan hari H acara anda
                                      </Typography>
                                  </AnimationOnScroll>
                            </Grid>
                        </Grid>
                    </Item>
                </Grid>
                <Grid item xs={12} md={4}>
                    <Item>
                        <Grid container >
                            <Grid item xs={2} md={2}>
                                <AnimationOnScroll animateIn="animate__zoomIn" >
                                  <MarkUnreadChatAltIcon sx={{ fontSize: 40, mt: 2.75, color: '#6750a4' }} />
                                </AnimationOnScroll>
                            </Grid>
                            <Grid item xs={10} md={10}>
                                  <AnimationOnScroll animateIn="animate__zoomIn" >
                                      <Typography variant={mobile ? "h6" : "h5"} gutterBottom className="font-jost">
                                        RSVP & Ucapan      
                                      </Typography>
                                      <Typography variant={mobile ? "body2" : "body1"} className="font-jost font-thin">
                                        Terima konfirmasi hadi, doa & ucapan langsung dari tamu anda
                                      </Typography>
                                  </AnimationOnScroll>
                            </Grid>
                        </Grid>
                    </Item>
                </Grid>
                <Grid item xs={12} md={4}>
                    <Item>
                        <Grid container >
                            <Grid item xs={2} md={2}>
                                <AnimationOnScroll animateIn="animate__zoomIn" >
                                  <WhatsAppIcon sx={{ fontSize: 40, mt: 2.75, color: '#6750a4' }} />
                                </AnimationOnScroll>
                            </Grid>
                            <Grid item xs={10} md={10}>
                                  <AnimationOnScroll animateIn="animate__zoomIn" >
                                      <Typography variant={mobile ? "h6" : "h5"} gutterBottom className="font-jost">
                                        WhatsApp Generator    
                                      </Typography>
                                      <Typography variant={mobile ? "body2" : "body1"} className="font-jost font-thin">
                                        Kirim undangan ke tamu anda jadi cepat dan mudah
                                      </Typography>
                                  </AnimationOnScroll>
                            </Grid>
                        </Grid>
                    </Item>
                </Grid>
                <Grid item xs={12} md={4}>
                    <Item>
                        <Grid container >
                            <Grid item xs={2} md={2}>
                                <AnimationOnScroll animateIn="animate__zoomIn" >
                                  <LiveTvIcon sx={{ fontSize: 40, mt: 2.75, color: '#6750a4' }} />
                                </AnimationOnScroll>
                            </Grid>
                            <Grid item xs={10} md={10}>
                                  <AnimationOnScroll animateIn="animate__zoomIn" >
                                      <Typography variant={mobile ? "h6" : "h5"} gutterBottom className="font-jost">
                                        Live Streaming  
                                      </Typography>
                                      <Typography variant={mobile ? "body2" : "body1"} className="font-jost font-thin">
                                        Bagikan moment anda untuk tamu undangan yang tidak bisa hadir
                                      </Typography>
                                  </AnimationOnScroll>
                            </Grid>
                        </Grid>
                    </Item>
                </Grid>
                <Grid item xs={12} md={4}>
                    <Item>
                        <Grid container >
                            <Grid item xs={2} md={2}>
                                <AnimationOnScroll animateIn="animate__zoomIn" >
                                  <CardGiftcardIcon sx={{ fontSize: 40, mt: 2.75, color: '#6750a4' }} />
                                </AnimationOnScroll>
                            </Grid>
                            <Grid item xs={10} md={10}>
                                  <AnimationOnScroll animateIn="animate__zoomIn" >
                                      <Typography variant={mobile ? "h6" : "h5"} gutterBottom className="font-jost">
                                        Hadiah Digital  
                                      </Typography>
                                      <Typography variant={mobile ? "body2" : "body1"} className="font-jost font-thin">
                                        Untuk tamu anda yang ingin memberikan hadiah secara cashless dengan mudah
                                      </Typography>
                                  </AnimationOnScroll>
                            </Grid>
                        </Grid>
                    </Item>
                </Grid>
                <Grid item xs={12} md={4}>
                    <Item>
                        <Grid container >
                            <Grid item xs={2} md={2}>
                                <AnimationOnScroll animateIn="animate__zoomIn" >
                                  <ContactPageIcon sx={{ fontSize: 40, mt: 2.75, color: '#6750a4' }} />
                                </AnimationOnScroll>
                            </Grid>
                            <Grid item xs={10} md={10}>
                                  <AnimationOnScroll animateIn="animate__zoomIn" >
                                      <Typography variant={mobile ? "h6" : "h5"} gutterBottom className="font-jost">
                                        Custom Nama Tamu
                                      </Typography>
                                      <Typography variant={mobile ? "body2" : "body1"} className="font-jost font-thin">
                                        Undangan terasa lebih personal dengan memberikan nama tamu pada website anda
                                      </Typography>
                                  </AnimationOnScroll>
                            </Grid>
                        </Grid>
                    </Item>
                </Grid>
                
            </Grid>
        </Container>
        </div>
    </div>
  );
}

export default Fitur;