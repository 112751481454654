import * as React from 'react';
import { useTheme, useMediaQuery } from '@material-ui/core';
import { Container, Typography, ImageList, ImageListItem, ImageListItemBar, Card, CardActions, CardContent, CardMedia,
  Chip, Tab, Box, Stack, Divider, Button } from '@mui/material';
  import WhatsAppIcon from '@mui/icons-material/WhatsApp';
  import VisibilityIcon from '@mui/icons-material/Visibility';

const itemData = [
  {
    id : 5,
    img:  require('../../images/theme/minang-removebg.png'),
    title: 'MINANG',
    author: '#Minang',
    link: 'https://momentkami.com/udauni/?to=Tamu+Undangan'
  },
  {
    id : 4,
    img:  require('../../images/theme/premium-jawa-removebg.png'),
    title: 'PREMIUM JAWA',
    author: '#Premium_Jawa',
    link: 'https://momentkami.com/masyu2/?to=Tamu+Undangan'
  },
  {
    id : 3,
    img:  require('../../images/theme/jawa-removebg.png'),
    title: 'ADAT JAWA',
    author: '#Jawa',
    link: 'https://momentkami.com/masyu/?to=Tamu+Undangan'
  },
  {
    id : 2,
    img:  require('../../images/theme/premium-ulos-removebg.png'),
    title: 'PREMIUM ULOS',
    author: '#Premium_Ulos',
    link: 'https://momentkami.com/ucokbutet2/?to=Tamu+Undangan'
  },
  {
    id : 1,
    img: require('../../images/theme/ulos-removebg.png'),
    title: 'ADAT ULOS',
    author: '#Ulos',
    link: 'https://momentkami.com/ucokbutet/?to=Tamu+Undangan'
  },
];

const Theme1 = (props) =>  {
    const theme = useTheme();
    const mobile = useMediaQuery(theme.breakpoints.down('xs'));
    const tablet = useMediaQuery(theme.breakpoints.down('md'));


    const demo = (link) => {
        window.open(link, '_blank', 'noopener, noreferrer'); 
      }
      
      const WAMsg = (val) => {
          const msg = 'Hi, saya tertarik ingin membuat website nikah dengan tema '+val+' di momentkami.com, bisakah saya berdiskusi lebih lanjut ?';
          window.open('https://api.whatsapp.com/send?phone=+6281912428620&text='+msg+'&app_absent=0', '_blank', 'noopener,noreferrer'); 
      }

  return ( 
    <ImageList cols={mobile ? 1 : tablet ? 2 : 3} gap={mobile ? 5 : 20}>
      {itemData.map((item) => (
          <center>
            <Card sx={{ maxWidth: '100%'}} elevation={0}>
              <CardContent>
                <img
                  src={`${item.img}`}
                  alt={item.title}
                  loading="lazy" 
                  style={{width: mobile ? 160 : 200}}
                />
                <Typography gutterBottom variant="h5" component="div" mt={4}>
                  {item.title}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {item.author}
                </Typography>
                <Stack
                  direction="row"
                  divider={<Divider orientation="vertical" flexItem />}
                  spacing={2}
                  mt={2}
                  justifyContent={'center'}
                >
                  <Button className='btnDarkWht' size={mobile ? 'small' : 'medium'} sx={{padding: mobile ? '.5rem 1rem' : '.5rem 1.25rem' }} startIcon={<VisibilityIcon />} onClick={() => {demo(item.link); }}>
                    Demo
                  </Button>
                  <Button className='btnLightPurple' size={mobile ? 'small' : 'medium'} sx={{padding: mobile ? '.5rem 1rem' : '.5rem 1.25rem' }} startIcon={<WhatsAppIcon />} onClick={() => {WAMsg(item.title); }}>
                    Pesan
                  </Button>
                </Stack>
              </CardContent>
            </Card>
          </center>
      ))}
    </ImageList>
  );
}

export default Theme1;