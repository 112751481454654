import * as React from 'react';
import axios from 'axios';
import GlobalVar from '../../GlobalVar';
import { useTheme, useMediaQuery } from '@material-ui/core';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Fade from '@mui/material/Fade';
import Grow from '@mui/material/Grow';
import Slide from '@mui/material/Slide';
import { bankData } from '../../Bank.js'
import { AnimationOnScroll } from 'react-animation-on-scroll';
import { useCountdown } from '../useCountdown';

const server = GlobalVar.server;

const Item = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(0.25),
  color: theme.palette.text.secondary,
}));

const SectionHeaderUlos = (props) => {
    const theme = useTheme();
    const mobile = useMediaQuery(theme.breakpoints.down('xs'));

    const [mempelaiPria, setMempelaiPria] = React.useState('');
    const [mempelaiWanita, setMempelaiWanita] = React.useState('');
    const [tglUnduhS, setTglUnduhS] = React.useState('');
    const [tglAkadS, setTglAkadS] = React.useState('');
    const [tglResepsiS, setTglResepsiS] = React.useState('');
    const [fotoCover1, setFotoCover1] = React.useState('');
    const [fotoCover2, setFotoCover2] = React.useState('');
    const [hari, setHari] = React.useState('');
    const [tgl, setTgl] = React.useState('');
    const [bulan, setBulan] = React.useState('');
    const [tahun, setTahun] = React.useState('');
    const [unduhOnly, setUnduhOnly] = React.useState(0);
    const [paketID, setPaketID] = React.useState(1);

    const [day, hour, minute, second] = useCountdown(unduhOnly == 1 ? tglUnduhS : tglResepsiS);

    const path = window.location.search;
    const urlParams = new URLSearchParams(path);
    const pathParts = window.location.pathname.split('/');  

    React.useEffect(() => {
        
        axios.get(server+'GetData.php?n='+pathParts[1]).then(function(response){
            let res = JSON.stringify(response.data);
            let resP = JSON.parse(res);

            setMempelaiPria(resP[0].cf_mempelai_pria);
            setMempelaiWanita(resP[0].cf_mempelai_wanita);
            setUnduhOnly(resP[0].cf_unduh_only);
            setPaketID(resP[0].cs_paketid);
            const days = ['Minggu', 'Senin', 'Selasa', 'Rabu', 'Kamis', 'Jumat', 'Sabtu'];
            const monthNames = ["Januari", "Februari", "Maret", "April", "May", "Juni",
              "Juli", "Agustus", "September", "Oktober", "November", "Desember"
            ];
            let newTgl = new Date(resP[0].cf_unduh_only == 1 ? resP[0].cf_waktu_unduh_start : 
              resP[0].cf_waktu_akad_start == null ? resP[0].cf_waktu_resepsi_start : resP[0].cf_waktu_akad_start );
            setHari(days[newTgl.getDay()]);
            setTgl(newTgl.getDate());
            setBulan(monthNames[newTgl.getMonth()]); //Month from 0 to 11
            setTahun(newTgl.getFullYear());
            
            props.pria(resP[0].cf_mempelai_pria);
            props.wanita(resP[0].cf_mempelai_wanita);
            props.priaFull(resP[0].cf_mempelai_pria_full);
            props.wanitaFull(resP[0].cf_mempelai_wanita_full);
            props.tglUnduhS(resP[0].cf_waktu_unduh_start);
            setTglUnduhS(resP[0].cf_waktu_unduh_start);
            props.tglUnduhE(resP[0].cf_waktu_unduh_end);
            props.lokUnduh(resP[0].cf_tempat_unduh);
            props.tglAkadS(resP[0].cf_waktu_akad_start);
            setTglAkadS(resP[0].cf_waktu_akad_start);
            setTglResepsiS(resP[0].cf_waktu_resepsi_start);
            props.tglAkadE(resP[0].cf_waktu_akad_end);
            props.lokAkad(resP[0].cf_tempat_akad);
            props.tglResepsiS(resP[0].cf_waktu_resepsi_start);
            props.tglResepsiE(resP[0].cf_waktu_resepsi_end);
            props.lokResepsi(resP[0].cf_tempat_resepsi);
            props.ayahPria(resP[0].cf_ayah_mempelai_pria);
            props.ibuPria(resP[0].cf_ibu_mempelai_pria);
            props.ayahWanita(resP[0].cf_ayah_mempelai_wanita);
            props.ibuWanita(resP[0].cf_ibu_mempelai_wanita);
            props.unduhOnly(resP[0].cf_unduh_only);
            props.unduhGmaps(resP[0].cf_gmaps_unduh);
            props.akadGmaps(resP[0].cf_gmaps_akad);
            props.resepsiGmaps(resP[0].cf_gmaps_resepsi);
            props.alamatHadiah(resP[0].cf_alamat_hadiah);
            props.linkStreaming(resP[0].cf_link_streaming);
            props.paketID(resP[0].cs_paketid);      
            props.islam(resP[0].cs_islam);                
            props.plusResepsi(resP[0].cf_plus_resepsi);   
            props.fotoShared(resP[0].cf_foto_shared);    
            props.audioMempelai(resP[0].cf_audio_mempelai);
            
            bankData
            .filter((bank) => bank.code == resP[0].cf_bank1_kode)
            .map((filteredBank) => {
              props.bank1Name(filteredBank.logo)
              props.bank1Rek(resP[0].cf_bank1_rekening)
              props.bank1An(resP[0].cf_bank1_an)
            });

            bankData
            .filter((bank) => bank.code == resP[0].cf_bank2_kode)
            .map((filteredBank) => {
                props.bank2Name(filteredBank.logo)
                props.bank2Rek(resP[0].cf_bank2_rekening)
                props.bank2An(resP[0].cf_bank2_an)
            });
           
            if(paketID == 1) {
              axios.get(server+'GetImages6.php?n='+pathParts[1]).then(function(response){
                  let res = JSON.stringify(response.data);
                  let resP = JSON.parse(res);
                  props.fotoPria(resP.pria);
                  props.fotoWanita(resP.wanita);
                  props.fotoCover1(resP.fotocover1);
                  props.fotoCover2(resP.fotocover2);
                  props.fotoOpening(resP.fotoopening);
                  props.foto1(resP.foto1);
                  props.foto2(resP.foto2);
                  props.foto3(resP.foto3);
                  props.foto4(resP.foto4);
                  props.foto5(resP.foto5);
                  props.foto6(resP.foto6);
                  props.foto7('');
                  props.foto8('');
                  props.foto9('');
                  props.foto10('');
                  props.foto11('');
                  props.foto12('');
              });
            } else {
                axios.get(server+'GetImages12.php?n='+pathParts[1]).then(function(response){
                  let res = JSON.stringify(response.data);
                  let resP = JSON.parse(res);
                  props.fotoPria(resP.pria);
                  props.fotoWanita(resP.wanita);
                  props.fotoCover1(resP.fotocover1);
                  props.fotoCover2(resP.fotocover2);
                  props.fotoOpening(resP.fotoopening);
                  props.foto1(resP.foto1);
                  props.foto2(resP.foto2);
                  props.foto3(resP.foto3);
                  props.foto4(resP.foto4);
                  props.foto5(resP.foto5);
                  props.foto6(resP.foto6);
                  props.foto7(resP.foto7);
                  props.foto8(resP.foto8);
                  props.foto9(resP.foto9);
                  props.foto10(resP.foto10);
                  props.foto11(resP.foto11);
                  props.foto12(resP.foto12);
    
              });
              
            }
            
        });        
        

    }, []);

  return (
    <div>
    <Fade in={props.visible}
        {...(props.visible ? { timeout: 3000 } : {})} > 

          <div className='App-header-section-ulos'>

            <div className='App-header-section-ulos-Inside'>
              <center>
                  <Typography variant="h2" className='font-minang' style={{letterSpacing: 2}}>{unduhOnly == 1 ? 'UNDUH MANTU' : 'UNDANGAN'}</Typography>
                  {/* <img src={require('../../images/bg_adat/batak/perintilan1.png')} className="App-section-ulos-gorga"/> */}
                  {
                    pathParts[1] == 'fahrinovi' ?                    
                      <img src={require('../../images/bg_adat/batak/mangupa.png')} className="App-section-ulos-banner"/>
                  :
                      <img src={require('../../images/bg_adat/batak/banner.png')} className="App-section-ulos-banner"/>
                  }
                  {/* <Typography variant={mobile ? "h3": "h2"} className='font-minang'>{tgl}</Typography>
                  <Typography variant={mobile ? "h4": "h3"} className='font-minang'>{bulan}</Typography>
                  <Typography variant={mobile ? "h4": "h3"} className='font-minang' mb={2}>{tahun}</Typography> */}

                </center>
                
                <Grid container 
                    direction="row"
                    justifyContent="center"
                    spacing={0}>                    
                    <Grid item xs={2}>
                      <center>
                        <Item elevation={0} className='bgTransparentUlos' style={{color: '#000'}}>
                            <Typography variant="h5" className='font-minang' >{tgl}</Typography>                                            
                        </Item>
                      </center>
                    </Grid>
                    
                    <Grid item xs={3} style={{borderLeft: '3px solid #000', borderRight: '3px solid #000'}}>
                    <center>
                        <Item elevation={0} className='bgTransparentUlos' style={{color: '#000'}}>
                            <Typography variant="h5" className='font-minang' >{bulan}</Typography>
                        </Item>
                        </center>
                    </Grid>
                    
                    <Grid item xs={2}>
                    <center>
                        <Item elevation={0} className='bgTransparentUlos' style={{color: '#000'}}>
                            <Typography variant="h5" className='font-minang' >{tahun}</Typography>                                            
                        </Item>
                        </center>
                    </Grid>
                    
                </Grid>   
                <center>
                <Stack
                        direction="row"
                        justifyContent="center"
                        alignItems="center" 
                        spacing={1}
                        my={4}
                    >
                        <Item className='dayUlos font-minang'>{day}</Item>
                        <Item className='hourUlos font-minang'>{hour}</Item>
                        <Item className='minuteUlos font-minang'>{minute}</Item>
                        <Item className='secondUlos font-minang'>{second}</Item>
                </Stack>
                </center>  

                  <div className='App-section-ulos-mempelai'>
                    <div className='App-section-ulos-mempelai-Inside'>
                      <center>
                        { pathParts[1] == 'marhaidinnovia' ?
                            <>
                              <Typography variant="h3" className='font-minang'>{mempelaiPria.substring(0,1)} | {mempelaiWanita.substring(0,1)}</Typography>
                                <Divider />
                              <Typography variant="h5" className='font-minang font-thin'>{mempelaiPria} & {mempelaiWanita}</Typography>
                            </>
                          :
                            <>
                              <Typography variant="h3" className='font-minang'>{mempelaiWanita.substring(0,1)} | {mempelaiPria.substring(0,1)}</Typography>
                                <Divider />
                              <Typography variant="h5" className='font-minang font-thin'>{mempelaiWanita} & {mempelaiPria}</Typography>
                            </>
                        }
                      </center>
                    </div>
                  </div>
            </div>
              
          </div>
        
    </Fade>
    </div>
  );
}

export default SectionHeaderUlos;


