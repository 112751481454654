import * as React from 'react';
import { useTheme, useMediaQuery } from '@material-ui/core';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import { CardActionArea } from '@mui/material';
import { AnimationOnScroll } from 'react-animation-on-scroll';

import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';

const Item = styled(Paper)(({ theme }) => ({
    padding: theme.spacing(0.25),
    color: theme.palette.text.secondary,
  }));
  
const SectionMempelai = (props) => {
    const pathParts = window.location.pathname.split('/');  
    const theme = useTheme();
    const mobile = useMediaQuery(theme.breakpoints.down('xs'));
    const tablet = useMediaQuery(theme.breakpoints.down('sm'));

  
  return (
    <div className='Section'>
            <Box>
            { props.islam &&
                <center>  
                    { props.islam == 1 &&
                    <AnimationOnScroll animateIn="animate__fadeInDown">
                        <Typography variant="h6" pt={4} gutterBottom >السَّلاَمُ عَلَيْكُمْ وَرَحْمَةُ اللهِ وَبَرَكَاتُهُ</Typography>
                    </AnimationOnScroll>
                    }
                    <AnimationOnScroll animateIn="animate__fadeInDown">
                        <Typography variant="body1" className="font-kulim font-thin" mx={4} mb={2}>
                            {props.islam == 1 ? 'Maha Suci Allah yang telah menciptakan makhluk-Nya berpasang-pasangan' : 'Tuhan membuat segala sesuatu indah pada waktu-Nya. Indah ketika mempertemukan kami, indah saat menumbuhkan kasih di antara kami, dan indah saat Ia mempersatukan kami dalam pernikahan yang kudus.'}
                        </Typography>
                    </AnimationOnScroll>
                    <Divider sx={{ width: 200 }}><FavoriteBorderIcon sx={{ fontSize: 20 }} style={{color: props.cs_color == 'Green' ? '#5F7161' : '#F87171'}}/></Divider>      
                </center>
            }
        
                <Grid container 
                    direction="row"
                    justifyContent="center"
                    alignItems="center" 
                    spacing={2} mt={4} px={2} mb={4}
                >
                    
                    <Grid item xs={12}>
                        <Item elevation={0} className='bgTransparent'>
                            <Card elevation={0} className='bgTransparent'>
                                <AnimationOnScroll animateIn="animate__fadeIn">
                                    <center>
                                        <CardMedia
                                            component="img"
                                            sx={{ width: pathParts[1] == 'ghinabagas' ? 160 : 250 }}
                                            image={`data:image/png;base64, ${props.fotoWanita}`}
                                            className="img-mempelai2"
                                            alt="mempelai wanita"
                                        />
                                    </center>
                                </AnimationOnScroll>
                                <CardActionArea className='cardNoAction'>
                                    <CardContent>
                                        <center>
                                        <AnimationOnScroll animateIn="animate__fadeInDown">
                                            <Typography variant="h2" className='font-curly text-shadow' component="div">
                                                {props.wanita}
                                            </Typography>
                                        </AnimationOnScroll>
                                        <AnimationOnScroll animateIn="animate__fadeInUp">
                                            <Typography gutterBottom variant="h6" className='font-kulim' component="div">
                                                {props.wanitaFull}
                                            </Typography>
                                            <Typography variant="body1" className="font-kulim" color="text.secondary" mt={2} mb={1}> Putri dari </Typography>
                                            <Typography variant="body1" className="font-kulim font-thin" >Bapak {props.ayahWanita} &</Typography>
                                            <Typography variant="body1" className="font-kulim font-thin" >Ibu {props.ibuWanita}</Typography>
                                        </AnimationOnScroll>
                                        </center>
                                    </CardContent>
                                </CardActionArea>
                            </Card>
                        </Item>
                    </Grid>
                    <Grid item xs={12}>
                        <Item elevation={0} className='bgTransparent'>
                            <Card elevation={0} className='bgTransparent'>
                                <AnimationOnScroll animateIn="animate__fadeIn">
                                    <center>
                                        <CardMedia
                                            component="img"
                                            sx={{ width: pathParts[1] == 'ghinabagas' ? 160 : 250 }}
                                            image={`data:image/png;base64, ${props.fotoPria}`}
                                            className="img-mempelai"
                                            alt="mempelai pria"
                                        />
                                    </center>
                                </AnimationOnScroll>
                                <CardActionArea className='cardNoAction'>
                                    <CardContent>
                                        <center>
                                        <AnimationOnScroll animateIn="animate__fadeInDown">
                                            <Typography variant="h2" className='font-curly text-shadow' component="div">
                                                {props.pria}
                                            </Typography>
                                        </AnimationOnScroll>

                                        <AnimationOnScroll animateIn="animate__fadeInUp">
                                            <Typography gutterBottom variant="h6" className='font-kulim' component="div">
                                                {props.priaFull}
                                            </Typography>
                                            <Typography variant="body1" className="font-kulim" color="text.secondary" mt={2} mb={1}> Putra dari </Typography>
                                            <Typography variant="body1" className="font-kulim font-thin" >Bapak {props.ayahPria} &</Typography>
                                            <Typography variant="body1" className="font-kulim font-thin" >Ibu {props.ibuPria}</Typography>
                                        </AnimationOnScroll>
                                        </center>
                                    </CardContent>
                                </CardActionArea>
                            </Card>
                        </Item>
                    </Grid>
                </Grid>
            </Box>
            { props.unduhOnly == 0 && 
                <div className={props.cs_color == 'Green' ? 'bgAyatGreen' : 'bgAyat'}>
                    <Box>
                    { props.islam &&
                        <center>
                        <AnimationOnScroll animateIn="animate__fadeInDown">
                            <Typography variant="body1" pt={2} mx={2} className='font-kulim font-thin' style={{width:'85%'}}>
                            {props.islam == 1 ? '" Dan di antara tanda-tanda (kebesaran)-Nya ialah Dia menciptakan pasangan-pasangan untukmu dari jenismu sendiri, agar kamu cenderung dan merasa tenteram kepadanya, dan Dia menjadikan di antaramu rasa kasih dan sayang. Sungguh, pada demikian itu benar-benar terdapat tanda-tanda (kebesaran Allah) bagi kaum yang berpikir. "'
                            : '"Sebab pada awal dunia, Allah menjadikan mereka laki-laki dan perempuan, sebab itu laki-laki akan meninggalkan ayahnya dan ibunya dan bersatu dengan isterinya, sehingga keduanya itu menjadi satu daging. Demikianlah mereka bukan lagi dua, melainkan satu. Karena itu, apa yang telah dipersatukan Allah, tidak boleh diceraikan manusia."'}
                            </Typography>            
                        </AnimationOnScroll>
                        <AnimationOnScroll animateIn="animate__fadeInUp">
                            <Typography variant="body1" pb={2} mt={2} mx={2} className="font-kulim ">
                            {props.islam == 1 ? '( QS. Ar-Rum ayat 21 )' : '( Markus 10:6-9 )'}</Typography>
                        </AnimationOnScroll>
                        </center> 
                    }

                    </Box>
                </div>
            }

            { props.ls_awal && 
                <>
                    <center style={{background: 'white'}}>
                        <AnimationOnScroll animateIn="animate__fadeInDown">
                            <Typography variant="h3" py={4} px={2} className="font-curly">Cerita Kami</Typography>
                        </AnimationOnScroll>
                    </center>
                    <AnimationOnScroll animateIn="animate__fadeIn">
                        <Card sx={{ display: 'flex' }}>
                            <Box sx={{ display: 'flex', flexDirection: 'column', width: '65%' }}>
                                <CardContent sx={{ flex: '1 0 auto' }}>
                                    <Typography component="div" variant="h6" className='font-kulim'>
                                    Awal Kami Bertemu
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary" component="div" className='font-kulim font-thin'>
                                    {props.ls_awal}
                                    </Typography>
                                </CardContent>
                            </Box>
                            <CardMedia
                            component="img"
                            sx={{ width: '35%' }}
                            image={`data:image/png;base64, ${props.ls_pict_awal}`}
                            alt="Awal"
                            />
                        </Card>   
                    </AnimationOnScroll> 
                    <AnimationOnScroll animateIn="animate__fadeIn">
                        <Card sx={{ display: 'flex' }}>
                            <CardMedia
                            component="img"
                            sx={{ width: '35%' }}
                            image={`data:image/png;base64, ${props.ls_pict_mengenal}`}
                            alt="Mengenal"
                            />
                            <Box sx={{ display: 'flex', flexDirection: 'column', width: '65%' }}>
                                <CardContent sx={{ flex: '1 0 auto' }}>
                                    <Typography component="div" variant="h6" className='font-kulim'> 
                                Kami Saling Mengenal
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary" component="div" className='font-kulim font-thin'>
                                    {props.ls_mengenal}
                                    </Typography>
                                </CardContent>
                            </Box>
                        </Card>         
                    </AnimationOnScroll>
                    <AnimationOnScroll animateIn="animate__fadeIn">
                        <Card sx={{ display: 'flex' }}>
                            <Box sx={{ display: 'flex', flexDirection: 'column', width: '65%' }}>
                                <CardContent sx={{ flex: '1 0 auto' }}>
                                    <Typography component="div" variant="h6" className='font-kulim'>
                                    Kami Memutuskan Untuk Bersama
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary" component="div" className='font-kulim font-thin'>
                                    {props.ls_akhir}
                                    </Typography>
                                </CardContent>
                            </Box>
                            <CardMedia
                            component="img"
                            sx={{ width: '35%' }}
                            image={`data:image/png;base64, ${props.ls_pict_akhir}`}
                            alt="Akhir"
                            />
                        </Card> 
                    </AnimationOnScroll>  
                </>         
            }
    </div>
  );
}

export default SectionMempelai;
