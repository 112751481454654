import * as React from 'react';
import { useTheme, useMediaQuery } from '@material-ui/core';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Container from '@mui/material/Container';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import { CardActionArea } from '@mui/material';
import Box from '@mui/material/Box';
import Fade from '@mui/material/Fade';
import { AnimationOnScroll } from 'react-animation-on-scroll';
import Snackbar from '@material-ui/core/Snackbar';

import LocationOnIcon from '@mui/icons-material/LocationOn';
import IconButton from '@mui/material/IconButton';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import CardGiftcardIcon from '@mui/icons-material/CardGiftcard';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import CloseIcon from '@mui/icons-material/Close';

const Item = styled(Paper)(({ theme }) => ({
    padding: theme.spacing(0.25),
    color: theme.palette.text.secondary,
  }));
  
const SectionAcaraJawa = (props) =>  {
    const [openSnackbar, setOpenSnackbar] = React.useState(false);

    const handleClickSnackbar = () => {
        setOpenSnackbar(true);
    };

    const handleCloseSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
        return;
        }

        setOpenSnackbar(false);
    };     

    const theme = useTheme();
    const mobile = useMediaQuery(theme.breakpoints.down('xs'));

  function addZero(i) {
    if (i < 10) {i = "0" + i}
    return i;
  }

  const days = ['Minggu', 'Senin', 'Selasa', 'Rabu', 'Kamis', 'Jumat', 'Sabtu'];
  const monthNames = ["Januari", "Februari", "Maret", "April", "May", "Juni",
    "Juli", "Agustus", "September", "Oktober", "November", "Desember"
  ];
  let newTglAkadS = new Date(props.unduhOnly == 1 ?  props.tglUnduhS : props.tglAkadS == null ? props.tglResepsiS : props.tglAkadS);
  let newNDAkadS = days[newTglAkadS.getDay()];
  let newDAkadS = newTglAkadS.getDate();
  let newMAkadS = monthNames[newTglAkadS.getMonth()]; //Month from 0 to 11
  let newYAkadS = newTglAkadS.getFullYear();
  let newHAkadS = addZero(newTglAkadS.getHours());
  let newMTAkadS = addZero(newTglAkadS.getMinutes());
      
    let newTglAkadE = new Date(props.tglAkadE);
    let newNDAkadE = days[newTglAkadE.getDay()];
    let newDAkadE = newTglAkadE.getDate();
    let newMAkadE = monthNames[newTglAkadE.getMonth()]; //Month from 0 to 11
    let newYAkadE = newTglAkadE.getFullYear();
    let newHAkadE = addZero(newTglAkadE.getHours());
    let newMTAkadE = addZero(newTglAkadE.getMinutes());
  
        let newTglResepsiS = new Date(props.tglResepsiS);
        let newNDResepsiS = days[newTglResepsiS.getDay()];
        let newDResepsiS = newTglResepsiS.getDate();
        let newMResepsiS = monthNames[newTglResepsiS.getMonth()]; //Month from 0 to 11
        let newYResepsiS = newTglResepsiS.getFullYear();
        let newHResepsiS = addZero(newTglResepsiS.getHours());
        let newMTResepsiS = addZero(newTglResepsiS.getMinutes());
  
            let newTglResepsiE = new Date(props.tglResepsiE);
            let newNDResepsiE = days[newTglResepsiE.getDay()];
            let newDResepsiE = newTglResepsiE.getDate();
            let newMResepsiE = monthNames[newTglResepsiE.getMonth()]; //Month from 0 to 11
            let newYResepsiE = newTglResepsiE.getFullYear();
            let newHResepsiE = addZero(newTglResepsiE.getHours());
            let newMTResepsiE = addZero(newTglResepsiE.getMinutes());

              let newTglS = new Date(props.tglUnduhS);
              let newNDS = days[newTglS.getDay()];
              let newDS = newTglS.getDate();
              let newMS = monthNames[newTglS.getMonth()]; //Month from 0 to 11
              let newYS = newTglS.getFullYear();
              let newHS = addZero(newTglS.getHours());
              let newMTS = addZero(newTglS.getMinutes());
              
                let newTglE = new Date(props.tglUnduhE);
                let newNDE = days[newTglE.getDay()];
                let newDE = newTglE.getDate();
                let newME = monthNames[newTglE.getMonth()]; //Month from 0 to 11
                let newYE = newTglE.getFullYear();
                let newHE = addZero(newTglE.getHours());
                let newMTE = addZero(newTglE.getMinutes());

    const openInNewTab = url => {
      window.open(url, '_blank', 'noopener,noreferrer');
    };

  return ( 
    <div className='bgAcaraJawa'>
        
        <div className='section-overlay jawa' style={{ marginTop: props.unduhOnly == 1 && -32 }}>
        <div className='section-overlay-content'>
            
            <Container>
                <center>
                    <AnimationOnScroll animateIn="animate__fadeInDown">
                        <Typography variant="h5" mt={4} mx={2} className="font-jawa">Hari Bahagia Kami</Typography>
                        <Typography variant="body1" mb={4} mx={2} className='font-XanhMono'>Suatu kehormatan bagi kami apabila Anda berkenan hadir.</Typography>
                    </AnimationOnScroll>

                </center>


                <Grid container 
                    direction="row"
                    justifyContent="center"
                    alignItems="center" 
                    spacing={0}>                    
                    <Grid item xs={3}>
                    <center>
                        <Item elevation={0} className='bgTransparentJawa' style={{color: '#e1e1e1'}}>
                            <Typography variant="h6" className='font-XanhMono' >{newNDResepsiS}</Typography>                                            
                        </Item>
                        </center>
                    </Grid>
                    
                    <Grid item xs={4} style={{borderLeft: '1px solid #e1e1e1', borderRight: '1px solid #e1e1e1'}}>
                    <center>
                        <Item elevation={0} className='bgTransparentJawa' style={{color: '#e1e1e1'}}>
                            <Typography variant="h6" className='font-XanhMono' >{newDResepsiS}</Typography>
                            <Typography variant="h6" className='font-XanhMono'>{newMResepsiS}</Typography>
                        </Item>
                        </center>
                    </Grid>
                    
                    <Grid item xs={3}>
                    <center>
                        <Item elevation={0} className='bgTransparentJawa' style={{color: '#e1e1e1'}}>
                            <Typography variant="h6" className='font-XanhMono' >{newYResepsiS}</Typography>                                            
                        </Item>
                        </center>
                    </Grid>
                    
                </Grid>     

                <Grid container 
                direction="row"
                justifyContent="center"
                alignItems="center" 
                spacing={2} mt={4} px={2} mb={4}>
                { props.unduhOnly == 0 &&   
                    <Grid item xs={12} className='bgTransparentJawa'>
                    <Item elevation={0} className='bgTransparentJawa'>
                        <Card elevation={0} className='bgTransparentWBorderJawa'>
                            <CardActionArea className='cardNoAction'>
                                <CardContent className='cardJawa'>
                                    <center>
                                    {
                                        props.tglAkadS != null ?
                                        <div>
                                        <AnimationOnScroll animateIn="animate__zoomIn">
                                            <Typography variant="h6" className="font-jawa" mt={2}>
                                                    {props.islam == 1 ? 'Akad Nikah' : 'Pemberkatan'} {props.plusResepsi == 1 ? '& Resepsi' : ''}
                                            </Typography>
                                        </AnimationOnScroll>
                                        <AnimationOnScroll animateIn="animate__zoomIn">
                                        <Typography variant="body1" className='font-XanhMono' >{newNDAkadS}, {newDAkadS} {newMAkadS} {newYAkadS}</Typography>
                                        </AnimationOnScroll>
                                        <AnimationOnScroll animateIn="animate__zoomIn">
                                            <Typography variant="body1" className='font-XanhMono' >{newHAkadS}:{newMTAkadS} - {props.tglAkadE == null ? "Selesai" : newHAkadE+":"+newMTAkadE} (WIB)</Typography>
                                        </AnimationOnScroll>
                                            {
                                                props.akadGmaps != null && props.akadGmaps != props.resepsiGmaps ?
                                                    <div>                 
                                                    <AnimationOnScroll animateIn="animate__zoomIn">
                                                        <Typography variant="body1" className="font-XanhMono" my={2} >{props.lokAkad}</Typography>

                                                        <Button disableElevation startIcon={<LocationOnIcon />} size="small" 
                                                        onClick={() => openInNewTab(props.akadGmaps)}
                                                        className="btnPrimaryJawa font-XanhMono" >Google Maps</Button>
                                                    </AnimationOnScroll>
                                                    </div>                               
                                                : ''
                                            }
                                        <Typography variant="h6"  className="font-jawa">
                                                &
                                        </Typography>
                                        </div>
                                        : ''
                                    }
                                        <AnimationOnScroll animateIn="animate__zoomIn">
                                            <Typography variant="h6"  className="font-jawa">
                                                    Resepsi
                                            </Typography>
                                            {
                                                props.akadGmaps != null && props.akadGmaps != props.resepsiGmaps ?
                                                    <div>    
                                                        {/* <Typography variant="body1" style={{letterSpacing: 4}} className='font-kulim font-thin'> Hari dan Tanggal </Typography> */}
                                                        <Typography variant="body1" className='font-XanhMono' my={1} >{newNDResepsiS}, {newDResepsiS} {newMResepsiS} {newYResepsiS}</Typography>
                                                    </div>                               
                                                : ''
                                            }
                                        </AnimationOnScroll>
                                        <AnimationOnScroll animateIn="animate__zoomIn">
                                        <Typography variant="body1" className='font-XanhMono' >{newNDResepsiS}, {newDResepsiS} {newMResepsiS} {newYResepsiS}</Typography>
                                        </AnimationOnScroll>
                                        <AnimationOnScroll animateIn="animate__zoomIn">
                                            <Typography variant="body1" className='font-XanhMono' >{newHResepsiS}:{newMTResepsiS} - {props.tglResepsiE == null ? "Selesai" : newHResepsiE+":"+newMTResepsiE} (WIB)</Typography>                                        
                                        </AnimationOnScroll>
                                        <AnimationOnScroll animateIn="animate__zoomIn">
                                            <Typography variant="body1" className="font-XanhMono" my={2} >{props.resepsiGmaps != null && props.akadGmaps != props.resepsiGmaps
                                            ? props.lokResepsi : props.lokAkad}</Typography>

                                            <Button disableElevation startIcon={<LocationOnIcon />} size="small" 
                                            onClick={() => openInNewTab(props.resepsiGmaps != null && props.akadGmaps != props.resepsiGmaps
                                                ? props.resepsiGmaps : props.akadGmaps)}
                                            className="btnPrimaryJawa font-XanhMono " >Google Maps</Button>
                                        </AnimationOnScroll>
                                    </center>
                                </CardContent>
                            </CardActionArea>
                            </Card>
                        </Item>
                    </Grid>
                }

                { props.tglUnduhS != null &&   
                    <Grid item xs={12} className='bgTransparentJawa'>
                    <Item elevation={0} className='bgTransparentJawa'>
                        <Card elevation={0} className='bgTransparentWBorderJawa'>
                            <CardActionArea className='cardNoAction'>
                                <CardContent className='cardJawa'>
                                    <center>
                                        <AnimationOnScroll animateIn="animate__zoomIn">
                                            <Typography gutterBottom variant="h4"className="font-italiana">
                                                    Unduh Mantu
                                            </Typography>
                                            <Typography variant="body1" style={{letterSpacing: 4}} className='font-XanhMono'> Hari dan Tanggal </Typography>
                                        </AnimationOnScroll>
                                        <AnimationOnScroll animateIn="animate__zoomIn">
                                            <Typography variant="body1" className='font-XanhMono' >{newNDS}, {newDS} {newMS} {newYS}</Typography>
                                            </AnimationOnScroll>
                                        <AnimationOnScroll animateIn="animate__zoomIn">
                                            <Typography variant="body1" style={{letterSpacing: 4}} mt={4} className='font-XanhMono'> Waktu </Typography>
                                        </AnimationOnScroll>
                                        <AnimationOnScroll animateIn="animate__zoomIn">
                                            <Typography variant="body1" className='font-XanhMono' >{newHS}:{newMTS} - {props.tglUnduhE == null ? "Selesai" : newHE+":"+newMTE} (WIB)</Typography>
                                        </AnimationOnScroll>
                                        <AnimationOnScroll animateIn="animate__zoomIn">
                                            <Typography variant="body1" style={{letterSpacing: 4}} mt={4} className='font-XanhMono'> Lokasi </Typography>
                                            <Typography variant="body1" className="font-XanhMono" mb={4} >{props.lokUnduh}</Typography>

                                            <Button disableElevation startIcon={<LocationOnIcon />} size={ mobile ? "small" : "large" } onClick={() => openInNewTab(props.unduhGmaps)}
                                            className="btnPrimaryJawa font-XanhMono " >Google Maps</Button>
                                        </AnimationOnScroll>
                                    </center>
                                </CardContent>
                            </CardActionArea>
                            </Card>
                        </Item>
                    </Grid>
                }
                </Grid>     
                
            </Container> 

                
            <div className='bgStreaming'>
                <Grid container 
                    direction="row"
                    justifyContent="center"
                    alignItems="center" 
                    spacing={2} mt={4} px={2}>
                    { props.linkStreaming != null && 
                    <Grid item xs={12} md={12}>
                            <Item elevation={0} className='bgTransparent'>
                                <center>  
                                    <AnimationOnScroll animateIn="animate__zoomIn">
                                        <Typography variant="h5" className="font-jawa">
                                            Live Moment
                                        </Typography>     
                                        <Typography variant="body2" mb={4} mx={2} className='font-XanhMono'>
                                            Temui kami secara virtual untuk menyaksikan moment bahagia kami. Silahkan untuk join live moment.
                                        </Typography>

                                        <Button disableElevation startIcon={<PlayArrowIcon />} size={ mobile ? "medium" : "large" } style={{marginBottom: 40}} 
                                        onClick={() => openInNewTab(props.linkStreaming)}
                                        className="btnPrimaryJawa font-XanhMono " > Live Moment</Button>
                                    </AnimationOnScroll>
                                    
                                </center>  
                            </Item>
                        </Grid>
                    }

                    <Grid item xs={12} >
                        <Item elevation={0} className='bgTransparent'>
                            <center>  
                                <AnimationOnScroll animateIn="animate__zoomIn">
                                    <Typography variant="h5"  className="font-jawa">
                                        Hadiah
                                    </Typography>     
                                    <Typography variant="body2"  mb={4} mx={2} className='font-XanhMono'>Bagi keluarga/sahabat yang ingin mengirimkan hadiah, Silahkan 
                                        mengirimkannya melalui tautan berikut :
                                    </Typography>
                                </AnimationOnScroll>

                                
                                <AnimationOnScroll animateIn="animate__zoomIn">
                                    <Typography variant="h6" mt={4} mb={2} className="font-XanhMono">
                                        <u>Alamat Pengiriman</u>
                                    </Typography>
                                    <Divider width={140}/>
                                    <Typography variant="body1" mb={2} className="font-XanhMono">{props.alamatHadiah}</Typography>
                                    <IconButton aria-label="copy" size={'small'} sx={{color: '#e1e1e1'}}  mb={4} onClick={() => {navigator.clipboard.writeText(props.alamatHadiah); handleClickSnackbar(); }}>
                                        <FileCopyIcon fontSize="inherit" />
                                    </IconButton>       
                                </AnimationOnScroll>                         
                                
                                <AnimationOnScroll animateIn="animate__zoomIn">
                                    <Typography variant="h6"  mt={4} mb={2} className="font-XanhMono">
                                        <u>No Rekening</u>
                                    </Typography>
                                    <Divider width={140}/>
                                    <Grid container 
                                        direction="row"
                                        justifyContent="center"
                                        alignItems="center" 
                                        spacing={1} px={2} mb={4}
                                    >
                                        <Grid item xs={11} md={12}>
                                            <Item elevation={0} className='bgTransparent'>
                                                <Card elevation={4} className='cardBankJawa'>
                                                    <CardActionArea className='cardBankJawa'>
                                                    <CardContent className='cardBankJawaOverlay'>
                                                        <img src={`${props.bank1Name}`} width={85} className="bank-img"/>  
                                                        <Typography gutterBottom variant="body2" className='font-XanhMono bank-rek' style={{letterSpacing: 2}} component="div" mt={2}>
                                                            <IconButton aria-label="copy" size="small" style={{marginRight: 5}} onClick={() =>  {navigator.clipboard.writeText(props.bank1Rek); handleClickSnackbar(); }}>
                                                            <FileCopyIcon fontSize="inherit" />
                                                            </IconButton>
                                                            {props.bank1Rek} 
                                                        </Typography> 
                                                        <Typography variant="caption" className="font-XanhMono bank-an" >{props.bank1An}</Typography>
                                                    </CardContent>
                                                    </CardActionArea>
                                                </Card>
                                            </Item>
                                        </Grid>
                                    { props.bank2Rek &&
                                        <Grid item xs={11} md={12}>
                                            <Item elevation={0} className='bgTransparent'>
                                                <Card elevation={1} className='cardBankJawa'>
                                                    <CardActionArea className='cardBankJawa'>   
                                                    <CardContent className='cardBankJawaOverlay'>
                                                        <img src={`${props.bank2Name}`} width={85} className="bank-img"/>  
                                                        <Typography gutterBottom variant="body2" className='font-XanhMono bank-rek' style={{letterSpacing: 2}} component="div" mt={2}>
                                                            <IconButton aria-label="copy" size="small" style={{marginRight: 5}} onClick={() => {navigator.clipboard.writeText(props.bank2Rek); handleClickSnackbar(); }}>
                                                                <FileCopyIcon fontSize="inherit" />
                                                            </IconButton>
                                                            {props.bank2Rek} 
                                                        </Typography> 
                                                        <Typography variant="caption" className="font-XanhMono bank-an" >{props.bank2An}</Typography>
                                                    </CardContent>
                                                    </CardActionArea>
                                                </Card>
                                            </Item>
                                        </Grid>
                                    }
                                        
                                    </Grid>
                                </AnimationOnScroll>
                                
                            </center>  
                        </Item>
                    </Grid>
                </Grid>       
                </div>
            </div>
        </div>


<Snackbar
    anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
    }}
    open={openSnackbar}
    autoHideDuration={2000}
    onClose={handleCloseSnackbar}
    message="Copied to clipboard"
    action={
        <React.Fragment>
            <IconButton size="small" aria-label="close" color="inherit" onClick={handleCloseSnackbar}>
                <CloseIcon fontSize="small" />
            </IconButton>
        </React.Fragment>
    }
/>

    </div>
  );
}

export default SectionAcaraJawa;