import * as React from 'react';
import axios from 'axios';
import GlobalVar from '../../GlobalVar';
import { useTheme, useMediaQuery } from '@material-ui/core';
import Typography from '@mui/material/Typography';
import Fade from '@mui/material/Fade';
import Grow from '@mui/material/Grow';
import Slide from '@mui/material/Slide';
import { bankData } from '../../Bank.js'
import { AnimationOnScroll } from 'react-animation-on-scroll';

const server = GlobalVar.server;

let cover1Bg = [];
let cover2Bg = [];
let openingBg = [];

const SectionHeaderPastel = (props) => {
    const theme = useTheme();
    const mobile = useMediaQuery(theme.breakpoints.down('xs'));

    const [mempelaiPria, setMempelaiPria] = React.useState('');
    const [mempelaiWanita, setMempelaiWanita] = React.useState('');
    const [fotoCover1, setFotoCover1] = React.useState('');
    const [fotoCover2, setFotoCover2] = React.useState('');
    const [fotoOpening, setFotoOpening] = React.useState('');
    
    const [hari, setHari] = React.useState('');
    const [tgl, setTgl] = React.useState('');
    const [bulan, setBulan] = React.useState('');
    const [tahun, setTahun] = React.useState('');
    const [unduhOnly, setUnduhOnly] = React.useState(0);
    const [paketID, setPaketID] = React.useState(1);

    const pathParts = window.location.pathname.split('/'); 

    React.useEffect(() => {
        const path = window.location.search;
        const urlParams = new URLSearchParams(path);
        
        axios.get(server+'GetData.php?n='+pathParts[1]).then(function(response){
            let res = JSON.stringify(response.data);
            let resP = JSON.parse(res);

            setMempelaiPria(resP[0].cf_mempelai_pria);
            setMempelaiWanita(resP[0].cf_mempelai_wanita);
            setUnduhOnly(resP[0].cf_unduh_only);
            setPaketID(resP[0].cs_paketid);
            const days = ['Minggu', 'Senin', 'Selasa', 'Rabu', 'Kamis', 'Jumat', 'Sabtu'];
            const monthNames = ["Januari", "Februari", "Maret", "April", "May", "Juni",
              "Juli", "Agustus", "September", "Oktober", "November", "Desember"
            ];
            let newTgl = new Date(resP[0].cf_unduh_only == 1 ? resP[0].cf_waktu_unduh_start : resP[0].cf_waktu_resepsi_start);
            setHari(days[newTgl.getDay()]);
            let bln = newTgl.getMonth()+1;
            if(newTgl.getDate() < 10) {
              setTgl('0'+newTgl.getDate());
            } else {
              setTgl(newTgl.getDate());
            }
            if(bln < 10) {
              setBulan('0'+bln);
            } else {
              setBulan(bln);
            } 
            // setBulan(monthNames[newTgl.getMonth()]); //Month from 0 to 11
            setTahun(newTgl.getFullYear());
            
            props.pria(resP[0].cf_mempelai_pria);
            props.wanita(resP[0].cf_mempelai_wanita);
            props.priaFull(resP[0].cf_mempelai_pria_full);
            props.wanitaFull(resP[0].cf_mempelai_wanita_full);
            props.tglUnduhS(resP[0].cf_waktu_unduh_start);
            props.tglUnduhE(resP[0].cf_waktu_unduh_end);
            props.lokUnduh(resP[0].cf_tempat_unduh);
            props.tglAkadS(resP[0].cf_waktu_akad_start);
            props.tglAkadE(resP[0].cf_waktu_akad_end);
            props.lokAkad(resP[0].cf_tempat_akad);
            props.tglResepsiS(resP[0].cf_waktu_resepsi_start);
            props.tglResepsiE(resP[0].cf_waktu_resepsi_end);
            props.lokResepsi(resP[0].cf_tempat_resepsi);
            props.ayahPria(resP[0].cf_ayah_mempelai_pria);
            props.ibuPria(resP[0].cf_ibu_mempelai_pria);
            props.ayahWanita(resP[0].cf_ayah_mempelai_wanita);
            props.ibuWanita(resP[0].cf_ibu_mempelai_wanita);
            props.unduhOnly(resP[0].cf_unduh_only);
            props.unduhGmaps(resP[0].cf_gmaps_unduh);
            props.akadGmaps(resP[0].cf_gmaps_akad);
            props.resepsiGmaps(resP[0].cf_gmaps_resepsi);
            props.alamatHadiah(resP[0].cf_alamat_hadiah);
            props.linkStreaming(resP[0].cf_link_streaming);
            props.paketID(resP[0].cs_paketid);            
            props.islam(resP[0].cs_islam);       
            props.plusResepsi(resP[0].cf_plus_resepsi);
            props.fotoShared(resP[0].cf_foto_shared);
            props.audioMempelai(resP[0].cf_audio_mempelai);
            props.gmapsIframeAkad(resP[0].cf_gmaps_iframe_akad);
            props.gmapsIframeResepsi(resP[0].cf_gmaps_iframe_resepsi);
            
            bankData
            .filter((bank) => bank.code == resP[0].cf_bank1_kode)
            .map((filteredBank) => {
              props.bank1Name(filteredBank.logo)
              props.bank1Rek(resP[0].cf_bank1_rekening)
              props.bank1An(resP[0].cf_bank1_an)
            });

            bankData
            .filter((bank) => bank.code == resP[0].cf_bank2_kode)
            .map((filteredBank) => {
                props.bank2Name(filteredBank.logo)
                props.bank2Rek(resP[0].cf_bank2_rekening)
                props.bank2An(resP[0].cf_bank2_an)
            });

            if(resP[0].cs_paketid == 1) {
              axios.get(server+'GetImages6.php?n='+pathParts[1]).then(function(response){
                  let res = JSON.stringify(response.data);
                  let resP = JSON.parse(res);
                  props.fotoPria(resP.pria);
                  props.fotoWanita(resP.wanita);
                  props.fotoCover1(resP.fotocover1);
                  props.fotoCover2(resP.fotocover2);
                  props.fotoOpening(resP.fotoopening);
                  props.foto1(resP.foto1);
                  props.foto2(resP.foto2);
                  props.foto3(resP.foto3);
                  props.foto4(resP.foto4);
                  props.foto5(resP.foto5);
                  props.foto6(resP.foto6);
                  props.foto7('');
                  props.foto8('');
                  props.foto9('');
                  props.foto10('');
                  props.foto11('');
                  props.foto12('');
    
                  setTimeout(() => {
                    setFotoOpening(resP.fotoopening);
                    setFotoCover1(resP.fotocover1);
                    setFotoCover2(resP.fotocover2);
                    
                    openingBg = {
                      background: `url("data:image/*;base64,${resP.fotoopening}")`,
                    };
                    cover1Bg = {
                      backgroundImage: `url("data:image/*;base64,${resP.fotocover1}")`,
                    };
                    cover2Bg = {
                      backgroundImage: `url("data:image/*;base64,${resP.fotocover2}")`,
                    };
                    
                  }, 300);
    
              });
            } else {
                axios.get(server+'GetImages12.php?n='+pathParts[1]).then(function(response){
                  let res = JSON.stringify(response.data);
                  let resP = JSON.parse(res);
                  props.fotoPria(resP.pria);
                  props.fotoWanita(resP.wanita);
                  props.fotoCover1(resP.fotocover1);
                  props.fotoCover2(resP.fotocover2);
                  props.fotoOpening(resP.fotoopening);
                  props.foto1(resP.foto1);
                  props.foto2(resP.foto2);
                  props.foto3(resP.foto3);
                  props.foto4(resP.foto4);
                  props.foto5(resP.foto5);
                  props.foto6(resP.foto6);
                  props.foto7(resP.foto7);
                  props.foto8(resP.foto8);
                  props.foto9(resP.foto9);
                  props.foto10(resP.foto10);
                  props.foto11(resP.foto11);
                  props.foto12(resP.foto12);
    
                  setTimeout(() => {
                    setFotoOpening(resP.fotoopening);
                    setFotoCover1(resP.fotocover1);
                    setFotoCover2(resP.fotocover2);
                    openingBg = {
                      background: `url("data:image/*;base64,${resP.fotoopening}")`,
                    };
                    cover1Bg = {
                      backgroundImage: `url("data:image/*;base64,${resP.fotocover1}")`,
                    };
                    cover2Bg = {
                      backgroundImage: `url("data:image/*;base64,${resP.fotocover2}")`,
                    };
                    
                  }, 300);
    
              });
              
            }
               
            
        });

        
        

    }, []);

  return (
    <div>
    <Fade in={props.visible}
        {...(props.visible ? { timeout: 3000 } : {})} > 

        <div className={props.themePage == 'Blue_Pastel' ? 'App-headerPastel BluePastel' : props.themePage == 'Yellow_Pastel' ? 'App-headerPastel YellowPastel' 
        : props.themePage == 'Green_Pastel' ? 'App-headerPastel GreenPastel' : props.themePage == 'Pink_Pastel' ? 'App-headerPastel PinkPastel' : props.themePage == 'GreenEl_Pastel' ? 'App-headerPastel GreenElPastel' : 'App-headerPastel'}
        >
            {/* <div className='divBG' style={openingBg}></div>
            <div className='divBG' style={openingBg}></div>
            <div className='divBG' style={openingBg}></div>
            <div className='divBG' style={openingBg}></div>
            <div className='divBG' style={openingBg}></div> */}
            {
              pathParts[1] == 'amirahdidi' && 
              <img
                src={`data:image/png;base64, ${fotoOpening}`}
                // alt={item.title}
                loading="lazy"
                style={{ position: 'absolute', top: 0, width: '100%', height: '100%', objectFit: 'cover', zIndex: 0 }}
              />
            }

            <div className='section-overlay'>
                <div className='section-overlay-content'>
                    <Slide direction="down" in={props.visible}
                        {...(props.visible ? { timeout: 3000 } : {})} > 
                        {/* <Typography variant="caption" className='font-kulim' mb={-4}>2 Insan 1 Tujuan</Typography> */}
                        <img src={require('../../images/bg_pastel/ornament-heart.png')} className="img-ornament-heart" width={48} />
                    </Slide>
                        <img src={require('../../images/bg_pastel/ornament1.png')} className="img-ornament1-top" width={240} />
                    {
                      pathParts[1] != 'nagasuryana' && 
                        <Grow in={props.visible}
                            {...(props.visible ? { timeout: 6000 } : {})} > 
                            <Typography variant="h5" className='font-bonheur txtwedding' my={.5}>{pathParts[1] == 'amirahdidi' ? '' : '- The Wedding Of -'}</Typography>
                        </Grow>
                    
                    }
                    {
                      pathParts[1] == 'silfiary' ? 
                        <>
                          <Grow in={props.visible}
                              {...(props.visible ? { timeout: 6000 } : {})} > 
                              <Typography variant="h2" className='font-bonheur' mb={-1}>{pathParts[1] == 'nagasuryana' || pathParts[1] == 'amirahdidi' ? mempelaiPria : mempelaiWanita}</Typography>
                          </Grow>
                            <Grow in={props.visible}
                                {...(props.visible ? { timeout: 6000 } : {})} > 
                                <Typography variant="h2" className='font-bonheur' mb={-2} mt={1}>&</Typography>
                            </Grow>
                        </>
                      :
                        <Grow in={props.visible}
                            {...(props.visible ? { timeout: 6000 } : {})} > 
                            <Typography variant="h2" className='font-bonheur' mb={-1}>{pathParts[1] == 'nagasuryana' || pathParts[1] == 'amirahdidi' ? mempelaiPria : mempelaiWanita} &</Typography>
                        </Grow>
                    }
                    <Grow in={props.visible}
                        {...(props.visible ? { timeout: 6000 } : {})} > 
                        <Typography variant="h2" className='font-bonheur'>{pathParts[1] == 'nagasuryana' || pathParts[1] == 'amirahdidi' ? mempelaiWanita : mempelaiPria}</Typography>
                    </Grow>
                        <img src={require('../../images/bg_pastel/ornament1.png')} className="img-ornament1-bottom" width={240} />
                        {
                          pathParts[1] == 'tyasarif' ?
                            <>
                            <Slide direction="up" in={props.visible}
                                {...(props.visible ? { timeout: 3000 } : {})} > 
                                <Typography variant="subtitle1" className='font-jost' mt={1} >R1: {31}. {12}. {2023}</Typography>
                            </Slide>
                            <Slide direction="up" in={props.visible}
                                {...(props.visible ? { timeout: 3000 } : {})} > 
                                <Typography variant="subtitle1" className='font-jost' mt={0} >R2: {tgl}. {bulan}. {tahun}</Typography>
                            </Slide>
                            </>
                          :
                          <Slide direction="up" in={props.visible}
                              {...(props.visible ? { timeout: 3000 } : {})} > 
                              <Typography variant="subtitle1" className='font-jost' mt={1} >{pathParts[1] == 'nagasuryana' && '21-'}{tgl}. {bulan}. {tahun}</Typography>
                          </Slide>
                        }
                </div>
            </div>
            <svg style={{position: 'absolute', bottom: 0, width: '100%', left: 0}} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
                <path fill={props.themePage == 'GreenEl_Pastel' ? "rgba(15, 118, 110, 1)" : "#fff"} fill-opacity="1" d="M0,192L26.7,208C53.3,224,107,256,160,272C213.3,288,267,288,320,240C373.3,192,427,96,480,80C533.3,64,587,128,640,144C693.3,160,747,128,800,112C853.3,96,907,96,960,128C1013.3,160,1067,224,1120,218.7C1173.3,213,1227,139,1280,101.3C1333.3,64,1387,64,1413,64L1440,64L1440,320L1413.3,320C1386.7,320,1333,320,1280,320C1226.7,320,1173,320,1120,320C1066.7,320,1013,320,960,320C906.7,320,853,320,800,320C746.7,320,693,320,640,320C586.7,320,533,320,480,320C426.7,320,373,320,320,320C266.7,320,213,320,160,320C106.7,320,53,320,27,320L0,320Z"></path>
            </svg>
        </div>
        
    </Fade>
    </div>
  );
}

export default SectionHeaderPastel;


