import * as React from 'react';
import { useTheme, useMediaQuery } from '@material-ui/core';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Container from '@mui/material/Container';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import { CardActionArea } from '@mui/material';
import Modal from '@mui/material/Modal';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Fade from '@mui/material/Fade';
import { AnimationOnScroll } from 'react-animation-on-scroll';
import Snackbar from '@material-ui/core/Snackbar';

import LocationOnIcon from '@mui/icons-material/LocationOn';
import IconButton from '@mui/material/IconButton';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import CardGiftcardIcon from '@mui/icons-material/CardGiftcard';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import CloseIcon from '@mui/icons-material/Close';

import { useCountdown } from '../useCountdown';

const Item = styled(Paper)(({ theme }) => ({
    padding: theme.spacing(0.25),
    color: theme.palette.text.secondary,
  }));
  
const SectionAcara2 = (props) =>  {
    const path = window.location.search;
    const urlParams = new URLSearchParams(path);
    const pathParts = window.location.pathname.split('/'); 
    
    const [openSnackbar, setOpenSnackbar] = React.useState(false);

    const handleClickSnackbar = () => {
        setOpenSnackbar(true);
    };

    const handleCloseSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
        return;
        }

        setOpenSnackbar(false);
    };
    
    const [day, hour, minute, second] = useCountdown(pathParts[1] != 'dinawendy' ? props.unduhOnly == 1 ? props.tglUnduhS : props.tglResepsiS : props.tglAkadS);
    
    const [openModalGift, setopenModalGift] = React.useState(false);
    const handleopenModalGift = () => setopenModalGift(true);
    const handleCloseModalGift = () => setopenModalGift(false);

    const [openModalBank, setopenModalBank] = React.useState(false);
    const handleopenModalBank = () => setopenModalBank(true);
    const handleCloseModalBank = () => setopenModalBank(false);      

    const theme = useTheme();
    const mobile = useMediaQuery(theme.breakpoints.down('xs'));

  function addZero(i) {
    if (i < 10) {i = "0" + i}
    return i;
  }

  const days = ['Minggu', 'Senin', 'Selasa', 'Rabu', 'Kamis', 'Jumat', 'Sabtu'];
  const monthNames = ["Januari", "Februari", "Maret", "April", "Mei", "Juni",
    "Juli", "Agustus", "September", "Oktober", "November", "Desember"
  ];
  let newTglAkadS = new Date(props.unduhOnly == 1 ?  props.tglUnduhS : props.tglAkadS == null ? props.tglResepsiS : props.tglAkadS);
  let newNDAkadS = days[newTglAkadS.getDay()];
  let newDAkadS = newTglAkadS.getDate();
  let newMAkadS = monthNames[newTglAkadS.getMonth()]; //Month from 0 to 11
  let newYAkadS = newTglAkadS.getFullYear();
  let newHAkadS = addZero(newTglAkadS.getHours());
  let newMTAkadS = addZero(newTglAkadS.getMinutes());
      
    let newTglAkadE = new Date(props.tglAkadE);
    let newNDAkadE = days[newTglAkadE.getDay()];
    let newDAkadE = newTglAkadE.getDate();
    let newMAkadE = monthNames[newTglAkadE.getMonth()]; //Month from 0 to 11
    let newYAkadE = newTglAkadE.getFullYear();
    let newHAkadE = addZero(newTglAkadE.getHours());
    let newMTAkadE = addZero(newTglAkadE.getMinutes());
  
        let newTglResepsiS = new Date(props.tglResepsiS);
        let newNDResepsiS = days[newTglResepsiS.getDay()];
        let newDResepsiS = newTglResepsiS.getDate();
        let newMResepsiS = monthNames[newTglResepsiS.getMonth()]; //Month from 0 to 11
        let newYResepsiS = newTglResepsiS.getFullYear();
        let newHResepsiS = addZero(newTglResepsiS.getHours());
        let newMTResepsiS = addZero(newTglResepsiS.getMinutes());
  
            let newTglResepsiE = new Date(props.tglResepsiE);
            let newNDResepsiE = days[newTglResepsiE.getDay()];
            let newDResepsiE = newTglResepsiE.getDate();
            let newMResepsiE = monthNames[newTglResepsiE.getMonth()]; //Month from 0 to 11
            let newYResepsiE = newTglResepsiE.getFullYear();
            let newHResepsiE = addZero(newTglResepsiE.getHours());
            let newMTResepsiE = addZero(newTglResepsiE.getMinutes());

              let newTglS = new Date(props.tglUnduhS);
              let newNDS = days[newTglS.getDay()];
              let newDS = newTglS.getDate();
              let newMS = monthNames[newTglS.getMonth()]; //Month from 0 to 11
              let newYS = newTglS.getFullYear();
              let newHS = addZero(newTglS.getHours());
              let newMTS = addZero(newTglS.getMinutes());
              
                let newTglE = new Date(props.tglUnduhE);
                let newNDE = days[newTglE.getDay()];
                let newDE = newTglE.getDate();
                let newME = monthNames[newTglE.getMonth()]; //Month from 0 to 11
                let newYE = newTglE.getFullYear();
                let newHE = addZero(newTglE.getHours());
                let newMTE = addZero(newTglE.getMinutes());


    let bgAcara = {
      backgroundImage: `url("data:image/*;base64,${props.fotoCover2}")`,
    };

    const openInNewTab = url => {
      window.open(url, '_blank', 'noopener,noreferrer');
    };

  return ( 
    <div className='Section bgAcara' style={bgAcara}>
        
        <div className='section-overlay' style={{ marginTop: props.unduhOnly == 1 && -32 }}>
        <div className='section-overlay-content'>
            
            <Container>
                <center>
                    <AnimationOnScroll animateIn="animate__fadeInDown">
                        <Typography variant="h3" mt={4} mx={2} className="font-curly">Hari Bahagia Kami</Typography>
                        {
                            pathParts[1] == 'dinawendy' ?
                            <Typography variant="body1" mb={4} mx={2} className='font-kulim font-thin'>
                                Melalui perantara media ini perkenankan kami menyampaikan kabar bahagia dan memohon doa restu pada acara akad pernikahan kami, yang InsyaAllah akan dilaksanakan pada:                                
                            </Typography>
                            :
                            <Typography variant="body1" mb={4} mx={2} className='font-kulim font-thin'>Suatu kehormatan bagi kami apabila Anda berkenan hadir.</Typography>
                            
                        }
                    </AnimationOnScroll>

                    <AnimationOnScroll animateIn="animate__flipInX">
                    <Stack
                            direction="row"
                            justifyContent="center"
                            alignItems="center" 
                            spacing={1}
                            mt={2}
                        >
                            <Item className='day'>{day}</Item>
                            <Item className='hour'>{hour}</Item>
                            <Item className='minute'>{minute}</Item>
                            <Item className='second'>{second}</Item>
                    </Stack>
                        </AnimationOnScroll>
                </center>

                <Grid container 
                direction="row"
                justifyContent="center"
                alignItems="center" 
                spacing={2} mt={4} px={2} mb={4}>
                { props.unduhOnly == 0 &&   
                    <Grid item xs={12} className='bgTransparent'>
                    <Item elevation={0} className='bgTransparent'>
                        <Card elevation={0} className='bgTransparentWBorder'>
                            <CardActionArea className='cardNoAction'>
                                <CardContent className='cardFlower'>
                                    <center>
                                    {
                                        props.tglAkadS != null ?
                                        <div>
                                        <AnimationOnScroll animateIn="animate__zoomIn">
                                            <Typography variant="body1" style={{letterSpacing: 4}} mt={4} className='font-kulim font-thin'> Hari dan Tanggal </Typography>
                                            <Typography variant="body1" className='font-kulim' >{newNDAkadS}, {newDAkadS} {newMAkadS} {newYAkadS}</Typography>
                                            <Typography variant="h4" className="font-italiana" mt={2}>
                                                {props.islam == 1 ? 'Akad Nikah' : 'Pemberkatan'} {props.plusResepsi == 1 ? '& Resepsi' : ''}
                                            </Typography>
                                            {
                                                pathParts[1] == 'mahmulputri' ?
                                                    <Typography variant="body2" className="font-kulim">(Kediaman Mempelai Wanita)</Typography>
                                                : ''
                                            }
                                        </AnimationOnScroll>
                                        <AnimationOnScroll animateIn="animate__zoomIn">
                                            <Typography variant="body1" className='font-kulim' >{newHAkadS}:{newMTAkadS} - {props.tglAkadE == null ? "Selesai" : newHAkadE+":"+newMTAkadE} (WIB)</Typography>
                                        </AnimationOnScroll>
                                        {
                                            pathParts[1] == 'syntayose' &&
                                                <AnimationOnScroll animateIn="animate__zoomIn">
                                                    <Typography variant="h4" className="font-italiana" mt={2}>
                                                        Walimatul Ursy
                                                    </Typography>
                                                    <Typography variant="body1" className='font-kulim' mb={2} >09:00 - 11.30 (WIB)</Typography>
                                                </AnimationOnScroll>
                                        }
                                        {
                                            props.akadGmaps != null && props.akadGmaps != props.resepsiGmaps ?
                                                <div>                 
                                                <AnimationOnScroll animateIn="animate__zoomIn">
                                                    {/* <Typography variant="body1" style={{letterSpacing: 4}} mt={4} className='font-kulim font-thin'> Lokasi </Typography> */}
                                                    <Typography variant="body1" className="font-kulim" my={2} >{props.lokAkad}</Typography>

                                                    <Button disableElevation startIcon={<LocationOnIcon />} size="small" 
                                                    onClick={() => openInNewTab(props.akadGmaps)}
                                                    className={props.cs_color == 'Green' ? "btnPrimaryGreen" : "btnPrimary"} >Google Maps</Button>
                                                </AnimationOnScroll>
                                                </div>                               
                                            : ''
                                        }
                                        
                                        {
                                            (pathParts[1] != 'syntayose' && pathParts[1] != 'dinawendy') &&
                                                <Typography variant="h2" className="font-curly">
                                                        &
                                                </Typography>
                                        }
                                        </div>
                                        : ''
                                    }
                                    {
                                        props.tglResepsiS != null &&
                                        <>
                                            <AnimationOnScroll animateIn="animate__zoomIn">
                                            {
                                                props.akadGmaps != null && props.akadGmaps != props.resepsiGmaps ?
                                                    <div>    
                                                        <Typography variant="body1" style={{letterSpacing: 4}} className='font-kulim font-thin'> Hari dan Tanggal </Typography>
                                                        <Typography variant="body1" className='font-kulim' mb={2} >{newNDResepsiS}, {newDResepsiS} {newMResepsiS} {newYResepsiS}</Typography>
                                                    </div>                               
                                                : ''
                                            }
                                                <Typography variant="h4" className="font-italiana">
                                                        Resepsi { pathParts[1] == 'mahmulputri' ? '2' : '' }
                                                </Typography>
                                                {
                                                    pathParts[1] == 'mahmulputri' ?
                                                        <Typography variant="body2" className="font-kulim">(Kediaman Mempelai Pria)</Typography>
                                                    : ''
                                                }
                                            </AnimationOnScroll>
                                            <AnimationOnScroll animateIn="animate__zoomIn">
                                                <Typography variant="body1"className='font-kulim' >{newHResepsiS}:{newMTResepsiS} - {props.tglResepsiE == null ? "Selesai" : newHResepsiE+":"+newMTResepsiE} (WIB)</Typography>                                        
                                            </AnimationOnScroll>
                                            <AnimationOnScroll animateIn="animate__zoomIn">
                                                {/* <Typography variant="body1" style={{letterSpacing: 4}} mt={4} className='font-kulim font-thin'> Lokasi </Typography> */}
                                                <Typography variant="body1" className="font-kulim" my={2} >{props.resepsiGmaps != null && props.akadGmaps != props.resepsiGmaps
                                                ? props.lokResepsi : props.lokAkad}</Typography>

                                                <Button disableElevation startIcon={<LocationOnIcon />} size="small" 
                                                onClick={() => openInNewTab(props.resepsiGmaps != null && props.akadGmaps != props.resepsiGmaps
                                                    ? props.resepsiGmaps : props.akadGmaps)}
                                                className={props.cs_color == 'Green' ? "btnPrimaryGreen" : "btnPrimary"} >Google Maps</Button>
                                            </AnimationOnScroll>
                                        </>
                                    }
                                    </center>
                                </CardContent>
                            </CardActionArea>
                            </Card>
                        </Item>

                        {
                            pathParts[1] == 'dinawendy' &&
                            <>
                                <AnimationOnScroll animateIn="animate__zoomIn">
                                    <center>
                                        <Typography variant="body1" mt={4} mb={1} mx={2} className='font-kulim font-thin'>
                                            Tanpa mengurangi rasa hormat, acara ini akan berlangsung sederhana diantara keluarga dan kerabat terdekat saja.
                                        </Typography>
                                        <Typography variant="body1" mb={4} mx={2} className='font-kulim font-thin'>
                                            Atas doa dan restunya kami ucapkan terimakasih.
                                        </Typography>
                                    </center>   
                                </AnimationOnScroll>
                            </>
                            
                        }
                    </Grid>
                }

                { props.tglUnduhS != null &&   
                    <Grid item xs={12} className='bgTransparent'>
                    <Item elevation={0} className='bgTransparent'>
                        <Card elevation={0} className='bgTransparentWBorder'>
                            <CardActionArea className='cardNoAction'>
                                <CardContent className='cardFlower'>
                                    <center>
                                        <AnimationOnScroll animateIn="animate__zoomIn">
                                            <Typography gutterBottom variant="h4" className="font-italiana">
                                                    Unduh Mantu
                                            </Typography>
                                            <Typography variant="body1" style={{letterSpacing: 4}} className='font-kulim font-thin'> Hari dan Tanggal </Typography>
                                        </AnimationOnScroll>
                                        <AnimationOnScroll animateIn="animate__zoomIn">
                                            <Typography variant="body1" className='font-kulim' >{newNDS}, {newDS} {newMS} {newYS}</Typography>
                                            </AnimationOnScroll>
                                        <AnimationOnScroll animateIn="animate__zoomIn">
                                            <Typography variant="body1" style={{letterSpacing: 4}} mt={4} className='font-kulim font-thin'> Waktu </Typography>
                                        </AnimationOnScroll>
                                        <AnimationOnScroll animateIn="animate__zoomIn">
                                            <Typography variant="body1" className='font-kulim' >{newHS}:{newMTS} - {props.tglUnduhE == null ? "Selesai" : newHE+":"+newMTE} (WIB)</Typography>
                                        </AnimationOnScroll>
                                        <AnimationOnScroll animateIn="animate__zoomIn">
                                            {/* <Typography variant="body1" style={{letterSpacing: 4}} mt={4} className='font-kulim font-thin'> Lokasi </Typography> */}
                                            <Typography variant="body1" className="font-kulim" my={2} >{props.lokUnduh}</Typography>

                                            <Button disableElevation startIcon={<LocationOnIcon />} size="small" onClick={() => openInNewTab(props.unduhGmaps)}
                                            className={props.cs_color == 'Green' ? "btnPrimaryGreen" : "btnPrimary"} >Google Maps</Button>
                                        </AnimationOnScroll>
                                    </center>
                                </CardContent>
                            </CardActionArea>
                            </Card>
                        </Item>
                    </Grid>
                }

                    {/* <Grid item xs={12} md={8} className='bgTransparent'>
                        <iframe src={`${props.unduhGmaps}`} allowfullscreen="true" className='gmaps'></iframe>
                    </Grid> */}

                </Grid>     
                
            </Container> 

                
            <div className='bgStreaming'>
                <Grid container 
                    direction="row"
                    justifyContent="center"
                    alignItems="center" 
                    spacing={2} mt={4} px={2}>
                    {props.linkStreaming != '' && 
                        <Grid item xs={12}>
                            <Item elevation={0} className='bgTransparent'>
                                <center>  
                                    <AnimationOnScroll animateIn="animate__zoomIn">
                                        <Typography variant="h3" className="font-curly">
                                            Live Moment
                                        </Typography>     
                                        <Typography variant="body2" mb={4} mx={2} className='font-kulim font-thin'> 
                                            Temui kami secara virtual untuk menyaksikan moment bahagia kami. Silahkan untuk join live moment.
                                        </Typography>

                                        <Button disableElevation startIcon={<PlayArrowIcon />} size="medium" style={{ marginBottom: 40}} 
                                        onClick={() => openInNewTab(props.linkStreaming)}
                                        className={props.cs_color == 'Green' ? "btnPrimaryGreen" : "btnPrimary"} > Live Moment</Button>
                                    </AnimationOnScroll>
                                    
                                </center>  
                            </Item>
                        </Grid>
                    }

                    {
                        (props.bank1Rek || props.alamatHadiah) &&
                        <Grid item xs={12}>
                            <Item elevation={0} className='bgTransparent'>
                                <center>  
                                    <AnimationOnScroll animateIn="animate__zoomIn">
                                        <Typography variant="h3" className="font-curly">
                                            Hadiah
                                        </Typography>     
                                        <Typography variant="body2" mb={4} mx={2} className='font-kulim font-thin'>Bagi keluarga dan sahabat yang ingin mengirimkan hadiah, Silahkan 
                                            mengirimkannya melalui tautan berikut :
                                        </Typography>
                                    </AnimationOnScroll>
                                    
                                    {
                                        pathParts[1] == 'baitiyudan' ?
                                            <>
                                                <AnimationOnScroll animateIn="animate__zoomIn">
                                                    <div>
                                                        <center>
                                                            <container>
                                                                <Typography variant="h6" mt={4} mb={1} className="font-kulim">
                                                                    No Rekening
                                                                </Typography>
                                                                <Divider width={140}/>
                                                                <Grid container 
                                                                    direction="row"
                                                                    justifyContent="center"
                                                                    alignItems="center" 
                                                                    spacing={1} mt={1} px={2} mb={4}
                                                                >
                                                                    <Grid item xs={12} md={8}>
                                                                        <Item elevation={0} className='bgTransparent'>
                                                                            <Card elevation={4} className='cardBank'>
                                                                                <CardActionArea className='cardBank'>
                                                                                <CardContent>
                                                                                    <img src={`${props.bank1Name}`} width={85} className="bank-img"/>  
                                                                                    <Typography gutterBottom variant="body2" className='font-kulim font-thin bank-rek' style={{letterSpacing: 2}} component="div" mt={2}>
                                                                                        <IconButton aria-label="copy" size="small" style={{marginRight: 5}} onClick={() =>  {navigator.clipboard.writeText(props.bank1Rek); handleClickSnackbar(); }}>
                                                                                            <FileCopyIcon fontSize="inherit" sx={{color: '#000'}} />
                                                                                        </IconButton>
                                                                                        {props.bank1Rek} 
                                                                                    </Typography> 
                                                                                    <Typography variant="caption" className="font-kulim font-thin bank-an" >{props.bank1An}</Typography>
                                                                                </CardContent>
                                                                                </CardActionArea>
                                                                            </Card>
                                                                        </Item>
                                                                    </Grid>
                                                                { props.bank2Rek &&
                                                                    <Grid item xs={12}>
                                                                        <Item elevation={0} className='bgTransparent'>
                                                                            <Card elevation={1} className='cardBank'>
                                                                                <CardActionArea className='cardBank'>
                                                                                <CardContent>
                                                                                    <img src={`${props.bank2Name}`} width={85} className="bank-img"/>  
                                                                                    <Typography gutterBottom variant="body2" className='font-kulim font-thin bank-rek' style={{letterSpacing: 2}} component="div" mt={2}>
                                                                                        <IconButton aria-label="copy" size="small" style={{marginRight: 5}} onClick={() => {navigator.clipboard.writeText(props.bank2Rek); handleClickSnackbar(); }}>
                                                                                            <FileCopyIcon fontSize="inherit" sx={{color: '#000'}} />
                                                                                        </IconButton>
                                                                                        {props.bank2Rek} 
                                                                                    </Typography> 
                                                                                    <Typography variant="caption" className="font-kulim font-thin bank-an" >{props.bank2An}</Typography>
                                                                                </CardContent>
                                                                                </CardActionArea>
                                                                            </Card>
                                                                        </Item>
                                                                    </Grid>
                                                                }
                                                                    
                                                                </Grid>
                                                            </container>

                                                            <Typography variant="h6" mt={4} mb={1} className="font-kulim">
                                                            Alamat Pengiriman
                                                            </Typography>
                                                            <Divider width={140}/>
                                                            <Typography variant="body2" mx={4} mt={1} mb={4} className="font-kulim font-thin">{props.alamatHadiah}
                                                                <IconButton aria-label="copy" size='small' sx={{marginLeft: 2}} onClick={() =>  {navigator.clipboard.writeText(props.alamatHadiah); handleClickSnackbar(); }}>
                                                                    <FileCopyIcon fontSize="inherit" sx={{color: '#000'}} />
                                                                </IconButton>
                                                            </Typography>
                                                        </center>
                                                    </div>
                                                </AnimationOnScroll>
                                            </>
                                        :
                                            <Grid container 
                                                direction="row"
                                                justifyContent="center"
                                                alignItems="center" 
                                                spacing={1} mt={4} px={2} mb={6}
                                            >
                                                {
                                                    props.alamatHadiah &&
                                                        <Grid item xs={(props.alamatHadiah && props.bank1Rek) ? 6 : 12}>
                                                            <AnimationOnScroll animateIn="animate__zoomIn">
                                                                <IconButton aria-label="hadiah" className={props.cs_color == 'Green' ? "iconHadiahGreen" : "iconHadiah"} onClick={handleopenModalGift}>
                                                                    <CardGiftcardIcon sx={{ fontSize: 40 }}/>
                                                                </IconButton>
                                                            </AnimationOnScroll>
                                                        </Grid>
                                                }
                                                {
                                                    props.bank1Rek &&
                                                        <Grid item xs={(props.alamatHadiah && props.bank1Rek) ? 6 : 12}>
                                                            <AnimationOnScroll animateIn="animate__zoomIn">
                                                                <IconButton aria-label="hadiah" className={props.cs_color == 'Green' ? "iconHadiahGreen" : "iconHadiah"} onClick={handleopenModalBank}>
                                                                    <AccountBalanceWalletIcon sx={{ fontSize: 40 }}/>
                                                                </IconButton>
                                                            </AnimationOnScroll>
                                                        </Grid>
                                                }
                                                
                                            </Grid>
                                    }
                                    
                                </center>  
                            </Item>
                        </Grid>
                    }
                    
                </Grid>       
                </div>
            </div>
        </div>


<Modal
  aria-labelledby="transition-modal-title"
  aria-describedby="transition-modal-description"
  open={openModalGift}
  onClose={handleCloseModalGift}
  closeAfterTransition
  BackdropComponent={Backdrop}
  BackdropProps={{
    timeout: 100,
  }} px={4}
>
  <Fade in={openModalGift}>
    <Box className='boxModal' 
        style={{paddingBottom: 20}}>
        <IconButton aria-label="copy" size='medium' mb={4} style={{position: 'absolute', right: 2, top: 2}} onClick={handleCloseModalGift}>
            <CloseIcon fontSize="inherit" style={{color: '#000'}} />
        </IconButton>
        <center>
            <Typography variant="body1" mt={4} mb={2} className="font-kulim">
              Alamat Pengiriman
            </Typography>
            <Divider width={140}/>
            <Typography variant="body2" mx={4} mt={2} mb={2} className="font-kulim font-thin">{props.alamatHadiah}</Typography>
            <IconButton aria-label="copy" size='small'  mb={4} onClick={() =>  {navigator.clipboard.writeText(props.alamatHadiah); handleClickSnackbar(); }}>
                <FileCopyIcon fontSize="inherit" />
            </IconButton>
        </center>

    </Box>
  </Fade>
</Modal>

<Modal
  aria-labelledby="transition-modal-title"
  aria-describedby="transition-modal-description"
  open={openModalBank}
  onClose={handleCloseModalBank}
  closeAfterTransition
  BackdropComponent={Backdrop}
  BackdropProps={{
    timeout: 100,
  }}
>
  <Fade in={openModalBank}>
    <Box className='boxModal' 
            style={{paddingBottom: 20}}>
            <IconButton aria-label="copy" size='medium' mb={4} style={{position: 'absolute', right: 2, top: 2}} onClick={handleCloseModalBank}>
                <CloseIcon fontSize="inherit" style={{color: '#000'}} />
            </IconButton>
            <container>
                <center>
                    <Typography variant="body1" mt={4} mb={2} className="font-kulim">
                    No Rekening
                    </Typography>
                    <Divider width={140}/>
                    <Grid container 
                        direction="row"
                        justifyContent="center"
                        alignItems="center" 
                        spacing={1} mt={4} px={2} mb={4}
                    >
                        <Grid item xs={12}>
                            <Item elevation={0} className='bgTransparent'>
                                <Card elevation={4} className='cardBank'>
                                    <CardActionArea className='cardBank'>
                                    <CardContent>
                                        <img src={`${props.bank1Name}`} width={85} className="bank-img"/>  
                                        <Typography gutterBottom variant="body2" className='font-kulim font-thin bank-rek' style={{letterSpacing: 2}} component="div" mt={2}>
                                            <IconButton aria-label="copy" size="small" style={{marginRight: 5}} onClick={() =>  {navigator.clipboard.writeText(props.bank1Rek); handleClickSnackbar(); }}>
                                                <FileCopyIcon fontSize="inherit" />
                                            </IconButton>
                                            {props.bank1Rek} 
                                        </Typography> 
                                        <Typography variant="caption" className="font-kulim font-thin bank-an" >{props.bank1An}</Typography>
                                    </CardContent>
                                    </CardActionArea>
                                </Card>
                            </Item>
                        </Grid>
                    { props.bank2Rek &&
                        <Grid item xs={12}>
                            <Item elevation={0} className='bgTransparent'>
                                <Card elevation={1} className='cardBank'>
                                    <CardActionArea className='cardBank'>
                                    <CardContent>
                                        <img src={`${props.bank2Name}`} width={85} className="bank-img"/>  
                                        <Typography gutterBottom variant="body2" className='font-kulim font-thin bank-rek' style={{letterSpacing: 2}} component="div" mt={2}>
                                            <IconButton aria-label="copy" size="small" style={{marginRight: 5}} onClick={() => {navigator.clipboard.writeText(props.bank2Rek); handleClickSnackbar(); }}>
                                                <FileCopyIcon fontSize="inherit" />
                                            </IconButton>
                                            {props.bank2Rek} 
                                        </Typography> 
                                        <Typography variant="caption" className="font-kulim font-thin bank-an" >{props.bank2An}</Typography>
                                    </CardContent>
                                    </CardActionArea>
                                </Card>
                            </Item>
                        </Grid>
                    }
                        
                    </Grid>
                </center>
            </container>            

    </Box>
  </Fade>
</Modal>     

<Snackbar
    anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
    }}
    open={openSnackbar}
    autoHideDuration={2000}
    onClose={handleCloseSnackbar}
    message="Copied to clipboard"
    action={
        <React.Fragment>
            <IconButton size="small" aria-label="close" color="inherit" onClick={handleCloseSnackbar}>
                <CloseIcon fontSize="small" />
            </IconButton>
        </React.Fragment>
    }
/>

    </div>
  );
}

export default SectionAcara2;